import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { PlayArrow } from "@mui/icons-material";
import { useListUserTakerDocumentAccessGrantsQuery } from "../../redux/services/taker";
import { TakerDocumentAccessGrant } from "../../redux/models/dataModelTypes";
import PagedTable from "../../components/tables/pagedTable";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";
import TakerDocumentStatus from "./TakerDocumentStatus";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";


const SharedTakers = () => {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);
  const { selectedOrgId } = useUserScopedAppData();

  const navigate = useNavigate();

  const {
    data: takerDocumentAGs,
    isFetching,
    isLoading,
  } = useListUserTakerDocumentAccessGrantsQuery({page, limit, selectedOrgId});

  return (
    <PagedTable
      isFetching={isFetching}
      isLoading={isLoading}
      paginatedResponse={takerDocumentAGs}
      tableSpan={5}
      rowMapper={(tdag: TakerDocumentAccessGrant) => (
        <TableRow
          key={tdag.id}
        >
          <TableCell>
            {tdag.takerDocument?.taker?.name ? (
              tdag.takerDocument?.taker.name
            ) : (
              "no name"
            )}
          </TableCell>
          <TableCell>{tdag.type}</TableCell>
          <TableCell>
            {tdag.takerDocument && (
              <TakerDocumentStatus takerDocument={tdag.takerDocument}/>
            )}
          </TableCell>
          <TableCell>{new Date(tdag.createdAt).toLocaleString()}</TableCell>
          <TableCell>
            <CrudButtonGroup 
              buttons={[
                {
                  icon: (<PlayArrow fontSize="small"/>),
                  handleClick: () => {
                    navigate(`/mainTaker/${tdag.takerDocument?.takerId}/keyTerms`);
                  }
                }
              ]} 
            />
          </TableCell>
        </TableRow>
      )}
      headers={[
          "Name",
          "Access",
          "Status",
          "Shared",
          ""
      ]}
      page={page}
      limit={limit}
      setPage={setPage}
      setLimit={setLimit}
    />
  );
}

export default SharedTakers;
