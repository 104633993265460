import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Checkbox,
    ListItemText,
    SelectChangeEvent
} from "@mui/material/";
import { useEffect, useMemo } from "react";

export interface ChoiceType {
    label: string;
    value: any;
};

interface MutiSelectQuestionProps {
    uniqueId: string;
    choices: ChoiceType[];
    defaultValue: any;
    onChangeValue: (a: any) => void;
    onResetField: () => void;
    readOnly: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const MutiSelectQuestion = ({
    uniqueId,
    choices,
    defaultValue,
    onChangeValue,
    onResetField,
    readOnly
}: MutiSelectQuestionProps) => {
    const error = useMemo(
        () => !defaultValue || defaultValue === "",
        [defaultValue]
    );

    const helperText = useMemo(
        () => error ? "Input is required" : null,
        [error]
    );

    const defaultValueAsList = useMemo(() => {
        if (!!defaultValue) {
            return defaultValue.split(',');
        }
        return []
    }, [defaultValue]);

    const handleChange = (event: SelectChangeEvent<typeof defaultValueAsList>) => {
        const {
            target: { value },
        } = event;
        onChangeValue(value.join(","))
    };

    useEffect(() => {
        // If value is populated and choices don't include the value, then reset 
        let fieldIsUnset = (defaultValue === undefined || defaultValue === null)
        if (!fieldIsUnset && !choices.some(c => defaultValue.split(',').includes(c.value))) {
            onResetField();
            //console.log(`do reset for value=${defaultValue} and available choices`);
        }
    }, [choices.length]);

    return (
        <FormControl
            error={error}
            sx={{ maxWidth: 750 }}
            fullWidth
        >
            <Select
                id={uniqueId}
                multiple
                value={defaultValueAsList}
                onChange={handleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.map(
                        (s: string) => choices.find(c => c.value === s)?.label
                    ).join(', ')
                }
                MenuProps={MenuProps}
            >
                {choices.map((c) => (
                    <MenuItem key={c.value} value={c.value}>
                        <Checkbox checked={defaultValueAsList.indexOf(c.value) > -1} />
                        <ListItemText primary={c.label} />
                    </MenuItem>
                ))}
            </Select>
            {helperText && (
                <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
}

export default MutiSelectQuestion;

