import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Tab,
  Tabs
} from "@mui/material/";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddIndexedGuidanceMutation, useInitializeIndexedGuidanceFromPackageMutation } from "../../redux/services/indexedGuidance";
import { useAddFileUploadItemContentMutation } from "../../redux/services/fileUpload";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { FileUploadWithNameRhf2 } from "../../components/form/reactHookForm/fileUploadWithNameRhf";
import TabPanel, { a11yProps } from "../../components/navigation/TabPanel";

interface Props {
  open: boolean;
  setOpen: (o: boolean) => void;
}

interface formInputs {
  guidanceId: string;
  name: string;
  description: string;
  canonicalLabel: string;
}

const FILE_TYPE_MAP: Record<string, string> = {
  "application/pdf": "PDF",
  "application/zip": "ZIP"
};

export function CreateIndexedGuidanceModal({ open, setOpen }: Props) {
  const [file, setFile] = useState<File | null>(null);
  const [packageFile, setPackageFile] = useState<File | null>(null);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  const [addIndexedGuidance, addIndexedGuidanceRes] = useAddIndexedGuidanceMutation();
  const [initializeIndexedGuidanceFromPackage, initializeIndexedGuidanceFromPackageRes] = useInitializeIndexedGuidanceFromPackageMutation();
  const [addFileUploadItemContent, addFileUploadItemContentRes] = useAddFileUploadItemContentMutation();

  useEffect(() => {
    if (addIndexedGuidanceRes.isSuccess) { 
      let indexedGuidance = addIndexedGuidanceRes.data;
      let fileUpload = indexedGuidance.indexedGuidanceUploads[0].fileUpload;
      let fileUploadItem = fileUpload.fileUploadItems[0];
      if (addFileUploadItemContentRes.isSuccess &&
        addFileUploadItemContentRes.data.id == fileUploadItem.id) {       
        initializeIndexedGuidanceFromPackage(indexedGuidance.id);
        setOpen(false);
      }
    }
  }, [addFileUploadItemContentRes]);
  
  useEffect(() => {
    if (addIndexedGuidanceRes.isSuccess) {
      let indexedGuidance = addIndexedGuidanceRes.data;
      if (file) {
        let fileUpload = indexedGuidance.fileUpload;
        if (fileUpload) {
          let fileUploadItem = fileUpload.fileUploadItems[0];
          const formData = new FormData();
          formData.append(
            "file",
            file,
            fileUploadItem.label
          );
          addFileUploadItemContent({
            id: fileUploadItem.id,
            fileUploadId: fileUpload.id,
            formData: formData
          });
        }
      }
      if (packageFile) {
        let fileUpload = indexedGuidance.indexedGuidanceUploads[0].fileUpload;
        let fileUploadItem = fileUpload.fileUploadItems[0];
        const packageFormData = new FormData();
        packageFormData.append(
          "file",
          packageFile,
          fileUploadItem.label
        );

        addFileUploadItemContent({
          id: fileUploadItem.id,
          fileUploadId: fileUpload.id,
          formData: packageFormData
        });
      }
    }
  }, [addIndexedGuidanceRes]);

  const schema = yup.object().shape({
    guidanceId: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    canonicalLabel: yup.string().nullable()
  });

  const defaultValues = {
    guidanceId: "",
    name: "",
    description: "",
    canonicalLabel: ""
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<formInputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitCust = (d: formInputs) => {
    addIndexedGuidance({
      guidanceId: d.guidanceId,
      name: d.name,
      description: d.description,
      canonicalLabel: d.canonicalLabel,
      fileLabel: file?.name,
      fileType: (file ? FILE_TYPE_MAP[file.type] : undefined),
      packageFileLabel: packageFile?.name,
      packageFileType: (packageFile ? FILE_TYPE_MAP[packageFile.type] : undefined)
    });
    setOpen(false);
  };

  return (
    <SimpleModalWrapper
      headerText="Add Indexed Guidance"
      open={open}
      handleClose={handleClose}
      sx={{ width: "50vw", height: "auto", padding: "16px 24px 16px 24px" }}
    >
      <Box>
        <form
          onSubmit={handleSubmit(handleSubmitCust)}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <TextFieldRhf
            label="Guidance ID"
            fieldName="guidanceId"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
            formHelperText="A human-readable ID to assign to some guidance. Can be used by muliple guidances to refer to the same type of guide."
          />
          <TextFieldRhf
            label="Name"
            fieldName="name"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <TextFieldRhf
            label="Description"
            fieldName="description"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <TextFieldRhf
            label="Canonical Label"
            fieldName="canonicalLabel"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
            formHelperText="(optional) A label to join mutiple guides together. Used when formatting a memo."
          />
          <Box paddingTop="15px">
            <Tabs
              value={tabValue}
              onChange={handleChange}
            >
              <Tab
                label="Source Document"
                {...a11yProps(0)}
              />
              <Tab
                label="Package File"
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <FileUploadWithNameRhf2
                label="Source Document"
                onChangeFile={(f) => setFile(f)}
                helperText="(optional) upload the source document to reference when entering data into the guidance."
                defaultFile={file}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <FileUploadWithNameRhf2
                label="Package File"
                onChangeFile={(f) => setPackageFile(f)}
                helperText="(optional) upload a indexed guidance package to initialize the guidance with data."
                defaultFile={packageFile}
              />
            </TabPanel>
          </Box>
          <Box
            sx={{
              paddingTop: "20px",
              textAlign: "right"
            }}
          >
            <Button
              variant="contained"
              type="submit">
              Upload
            </Button>
            <Button
              variant="contained"
              sx={{ marginLeft: "10px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </SimpleModalWrapper>
  );
}
