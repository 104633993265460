import React, { useEffect, useMemo, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useNavigate } from "react-router-dom";
import Quesionnaire from "./Questionnaire";
import Diagram from "./Diagram/Diagram";
import { AnalysisState } from "../../containers/WidgetWrapper/states";
import WidgetWrapper, { useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import AssistantIcon from '@mui/icons-material/Assistant';

import "./index.css"

const buildInitialAnalysisState = (savedState: string | null) => {
    const gpsState: AnalysisState = (!!savedState && JSON.parse(savedState)) || {
        selectedMid: null,
        selectedIteration: null,
        controlledByQuestionnaire: true,
        collapsedState: {},
        showAnswerQuestionsDialog: false,
        panelViewMode: 0
    };
    return gpsState;
};

const Toolbar = () => {
    const { getState, mutateState } = useWidgetState()
    const [openComingSoon, setOpenComingSoon] = useState(false);

    const panelViewMode = getState<AnalysisState>().panelViewMode;

    const selectedPanels = useMemo(() => {
        if (panelViewMode === 0) {
            return [0, 1];
        } else if (panelViewMode === 1) {
            return [0];
        } else if (panelViewMode === 2) {
            return [1];
        }
    }, [panelViewMode]);

    return (
        <Box
            sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                paddingLeft: 1,
                border: "1px solid #ddd",
                borderRadius: 1,
                backgroundColor: "rgba(221,221,221,0.25)"
            }}
        >
            <Grid
                container
                justifyContent="end"
                xs={12}
                paddingRight={1}
                paddingTop={0.5}
                paddingBottom={0.5}
                data-testid="toolbar-grid"
            >
                <Grid item xs={1}>
                    <IconButton
                        sx={{
                            'float': 'left'
                        }}
                        onClick={() => {
                            setOpenComingSoon(true);
                        }}
                    >
                        <AssistantIcon />
                    </IconButton>
                    <Dialog
                        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                        maxWidth="xs"
                        open={openComingSoon}
                    >
                        <DialogTitle>Question Helper - Coming Soon</DialogTitle>
                        <DialogContent>This feature is currently in development. The Question Helper is a AI aid gives the user helpful tips regarding individual questions or the overall questionnaire.</DialogContent>
                        <DialogActions>
                            <Button
                                autoFocus
                                onClick={() => {
                                    setOpenComingSoon(false);
                                }}
                            >
                                Okay
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item xs={9}>
                </Grid>
                <Grid item xs={2}
                    justifyContent="end"
                >
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={selectedPanels}
                        data-testid="toolbar-toggle-group"
                        sx={{
                            'float': 'right'
                        }}
                        onChange={(_, newMode) => {
                            let hasQuestionnaire = newMode.includes(0);
                            let hasGps = newMode.includes(1);
                            if (hasQuestionnaire && hasGps) {
                                mutateState<AnalysisState>({
                                    panelViewMode: 0
                                });
                            } else if (hasQuestionnaire) {
                                mutateState<AnalysisState>({
                                    panelViewMode: 1
                                });
                            } else if (hasGps) {
                                mutateState<AnalysisState>({
                                    panelViewMode: 2
                                });
                            }
                        }}
                    >
                        <ToggleButton
                            value={0}
                        >
                            Questionnaire
                        </ToggleButton>
                        <ToggleButton
                            value={1}
                        >
                            GPS
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </Box>
    );
};

const TakerWidgets = () => {
    const { getState } = useWidgetState();

    const panelViewMode = getState<AnalysisState>().panelViewMode;

    let showQuestionnaire = panelViewMode === 0 || panelViewMode === 1;
    let showGps = panelViewMode === 0 || panelViewMode === 2;

    return (
        <Grid
            container
            padding={1}
            columnSpacing={1}
            height="100%"
            width="100%"
        >
            {showQuestionnaire && (
                <Grid
                    item
                    xs={showGps ? 6 : 12}
                    sx={{
                        height: '100%',
                        width: "100%"
                    }}
                >
                    <Quesionnaire />
                </Grid>
            )}
            {showGps && (
                <Grid
                    item
                    xs={showQuestionnaire ? 6 : 12}
                    sx={{
                        height: '100%',
                        width: "100%"
                    }}
                    alignItems="center"
                    alignContent="center"
                >
                    <ReactFlowProvider>
                        <Diagram />
                    </ReactFlowProvider>
                </Grid>
            )}
        </Grid>
    );
}

const TakerAnalysis = () => {
    const navigate = useNavigate();
    const { taker, takerDocumentId } = useTakerState();

    const [widgetState, setWidgetState] = useState<AnalysisState>(
        buildInitialAnalysisState(localStorage.getItem(`AnalysisState-${takerDocumentId}`)),
    );

    /* Sync with localStorage */
    useEffect(() => {
        if (!!widgetState) {
            localStorage.setItem(`AnalysisState-${takerDocumentId}`, JSON.stringify(widgetState));
        }
    }, [widgetState]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                height: '100%',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    width: '100%'
                }}
            >
                <TakerToolbar
                    onClickBack={() => {
                        if (taker) {
                            navigate(`/mainTaker/${taker.id}/keyTerms`);
                        }
                    }}
                    onClickForward={() => {
                        if (taker) {
                            navigate(`/mainTaker/${taker.id}/report`);
                        }
                    }}
                    workflowDisplay={"Analysis"}
                    workflowDescription={`Please review and approve each answer and analysis.`}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    overflow: 'hidden'
                }}
                flexGrow={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <WidgetWrapper
                        widgetState={widgetState}
                        setPartialWidgetState={(s) => {
                            setWidgetState(Object.assign({}, widgetState, s));
                        }}
                    >
                        <Box
                            paddingTop={1}
                            paddingLeft={1}
                            paddingRight={1}
                        >
                            <Toolbar />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                overflowY: 'hidden'
                            }}
                            flexGrow={1}
                        >
                            <TakerWidgets />
                        </Box>
                    </WidgetWrapper>
                </Box>
            </Box>
        </Box>
    );
}

export default TakerAnalysis;
