import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    SerializedLexicalNode,
    Spread,
} from 'lexical';
import { useWidgetState } from '../../../../containers/WidgetWrapper/wrapper';
import { KeyTermsState } from '../../../../containers/WidgetWrapper/states';
import { DocumentHighlight } from '../../../../types/taker/documentkeyterms.generated';

export interface RawHtmlPayload {
    rawHtml: string;
    page: number;
    pageWidth: number;
}

export type SerializedRawHtmlNode = Spread<
    {
        rawHtml: string;
        page: number;
        pageWidth: number;
    },
    SerializedLexicalNode
>;

// might want to make these more configurable per document
const FONT_SCALE_MULTIPLIER = .99;
const ASPECT_RATIO_SCALE_MULTIPLIER = .80;

const SELECTOR_QUERY = '[data-element-type="textbox"], [data-element-type="textline"], [data-element-type="word"]'

interface RawHtmlComponentProps {
    innerHtml: string;
    originalPageWidth: number;
    documentHighlights: DocumentHighlight[];
}

const RawHtmlComponentV1 = ({
    innerHtml,
    originalPageWidth,
    documentHighlights
}: RawHtmlComponentProps) => {
    const [resizeJob, setResizeJob] = useState<any>();
    const pageRef = useRef<HTMLDivElement>();
    const { getState } = useWidgetState();
    const panelViewMode = getState<KeyTermsState>().panelViewMode;

    const onResize = () => {
        if (pageRef.current) {
            if (resizeJob) {
                clearTimeout(resizeJob);
            }
            setResizeJob(setTimeout(() => {
                if (pageRef.current) {
                    reprocessPage(pageRef.current.offsetWidth);
                }
            }, 50));
        }
    };

    const reprocessPage = (thisPageWidth: number) => {
        if (pageRef.current) {
            const pageElems = pageRef.current.getElementsByClassName('page') as HTMLCollectionOf<HTMLDivElement>;
            if (pageElems && pageElems.length === 1) {
                let ogWidthAttr = pageElems[0].attributes.getNamedItem("data-pagewidth")
                let ogHeightAttr = pageElems[0].attributes.getNamedItem("data-pageheight")
                if (ogWidthAttr && ogHeightAttr) {
                    let ogWidth = parseInt(ogWidthAttr.value) * ASPECT_RATIO_SCALE_MULTIPLIER;
                    let ogHeight = parseInt(ogHeightAttr.value);
                    pageElems[0].style.aspectRatio = `${ogWidth} / ${ogHeight}`;
                }
            }

            let currentWidthRadio = (thisPageWidth / originalPageWidth) * FONT_SCALE_MULTIPLIER;
            const allWords = pageRef.current.getElementsByClassName('word') as HTMLCollectionOf<HTMLSpanElement>;
            for (const word of allWords) {
                let ogSizeAttr = word.attributes.getNamedItem("data-og-size")
                if (ogSizeAttr) {
                    let ogSize = parseInt(ogSizeAttr.value)
                    let newSize = ogSize * currentWidthRadio;
                    word.style.letterSpacing = `0px`;
                    word.style.fontSize = `${newSize}px`;
                }
            }
        }
    };

    useEffect(() => {
        // TODO: this isn't using the latest width to draw
        if (panelViewMode === 0 || panelViewMode == 2) {
            setTimeout(() => {
                if (pageRef.current) {
                    reprocessPage(pageRef.current.offsetWidth);
                }
            }, 100);
        }
    }, [panelViewMode]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [pageRef]);

    const docHighlightsByElementId = useMemo(() => {
        const map: Record<string, DocumentHighlight[]> = {};
        for (const highlight of documentHighlights) {
            if (!map[highlight.elementId]) {
                map[highlight.elementId] = []
            }
            map[highlight.elementId].push(highlight);
        }
        return map;
    }, [documentHighlights]);

    useEffect(() => {
        if (pageRef.current) {
            const elems = pageRef.current.querySelectorAll(SELECTOR_QUERY) as NodeListOf<HTMLElement>;
            for (const elem of elems) {
                if (docHighlightsByElementId[elem.id]) {
                    const highlights = docHighlightsByElementId[elem.id];
                    for (const { elementType } of highlights) {
                        if (elementType === "WORD") {
                            elem.style.background = "rgba(227,255,0,0.65)";
                        } else if (elementType === "LINE") {
                            elem.style.background = "rgba(86,255,0,0.65)";
                        } else if (elementType === "TEXTBOX") {
                            elem.style.background = "rgba(0,249,255,0.65)";
                        }
                    }
                } else {
                    elem.style.background = "";
                }
            }
        }
    }, [pageRef, docHighlightsByElementId]);

    return (
        <div
            style={{
                fontFamily: "serif"
            }}
            ref={(r) => {
                if (r) {
                    pageRef.current = r;
                }
            }}
            dangerouslySetInnerHTML={{
                __html: innerHtml
            }}
        />
    );
};

export default RawHtmlComponentV1;