import React, { useCallback, useMemo, useState } from "react";
import { SimpleModalWrapper } from "../../../dialog/wrappers/simpleModalWrapper";
import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material/";
import { useTakerState } from "../../../../containers/TakerDocumentState/TakerDocumentState";
import { useAddTakerDocumentReviewCommentMutation, useUpdateTakerDocumentStateMutation } from "../../../../redux/services/taker";
import { TakerDocumentReviewState, TakerDocumentState } from "../../../../redux/models/dataModelTypes";
import { RootReducerType } from "../../../../redux/models/reduxTypes";
import { useSelector } from "../../../../redux/reduxUtils/functions";
import CommentRow from "./CommentRow";
import Comment from "./Comment";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const steps: { state: TakerDocumentReviewState; text: string; }[] = [
    { state: "DRAFT", text: "Finish the Draft" },
    { state: "PENDING", text: "Review" },
    { state: "COMPLETED", text: "Mark as Complete" },
];

function ReviewModal({
    open,
    setOpen
}: Props) {
    const {
        takerDocumentId,
        activeTakerDocument,
        takerPermissionState
    } = useTakerState();

    const [newLexical, setNewLexical] = useState<any>();
    const [newText, setNewText] = useState<string>();

    const { user: authedUser } = useSelector((state: RootReducerType) => state.auth);

    const [
        addTakerDocumentReviewComment,
        addTakerDocumentReviewCommentRes
    ] = useAddTakerDocumentReviewCommentMutation();

    const [
        updateTakerDocumentState,
        updateTakerDocumentStateRes
    ] = useUpdateTakerDocumentStateMutation();

    const handleClose = () => {
        setOpen(false);
    };

    const reviewComments = useMemo(() => {
        if (!activeTakerDocument || !activeTakerDocument.state) {
            return [];
        }

        let sortedReviewComments = [...activeTakerDocument.state.reviewComments];
        sortedReviewComments.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
        return sortedReviewComments;
    }, [activeTakerDocument]);

    const activeReviewStep: number = useMemo(() => {
        if (!activeTakerDocument || !activeTakerDocument.state) {
            return -1;
        }

        let activeState = activeTakerDocument.state.reviewState;
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].state === activeState) {
                return i;
            }
        }
        return -1;
    }, [activeTakerDocument]);

    const addComment = (lexical: any, text: string | undefined, userId: string | undefined) => {
        addTakerDocumentReviewComment({
            takerDocumentId,
            commentLexical: lexical,
            commentText: text,
            userId: userId
        });
        setNewLexical(undefined);
        setNewText(undefined);
    }

    const incrementReviewState = (takerDocumentState: TakerDocumentState, i: number) => {
        if (i === 2) {
            return;
        }

        let newState = steps[i + 1].state;
        updateTakerDocumentState({
            id: takerDocumentState.id,
            takerDocumentId: takerDocumentState.takerDocumentId,
            reviewState: newState
        })
    }

    const decrementReviewState = (takerDocumentState: TakerDocumentState, i: number) => {
        if (i === 0) {
            return;
        }

        let newState = steps[i - 1].state;
        updateTakerDocumentState({
            id: takerDocumentState.id,
            takerDocumentId: takerDocumentState.takerDocumentId,
            reviewState: newState
        })
    }

    const addCommentCallback = useCallback(() => {
        addComment(newLexical, newText, authedUser?.id);
    }, [newLexical, newText, authedUser]);

    const addCommentAndIncrement = useCallback(() => {
        if (activeTakerDocument && activeTakerDocument.state && activeReviewStep > -1) {
            addComment(newLexical, newText, authedUser?.id);
            incrementReviewState(activeTakerDocument.state, activeReviewStep);
        }
    }, [newLexical, newText, authedUser, activeTakerDocument]);

    const addCommentAndDecrement = useCallback(() => {
        if (activeTakerDocument && activeTakerDocument.state && activeReviewStep > -1) {
            addComment(newLexical, newText, authedUser?.id);
            decrementReviewState(activeTakerDocument.state, activeReviewStep);
        }
    }, [newLexical, newText, authedUser, activeTakerDocument]);

    const disableComment = useMemo(() =>
        !newText || newText.trim() === "",
        [newText]
    );

    let isReviewer = takerPermissionState.allGrants.includes("REVIEW");
    let isOwner = takerPermissionState.allGrants.includes("OWNER");

    return (
        <SimpleModalWrapper
            headerText="Review"
            open={open}
            handleClose={handleClose}
            sx={{ width: "60vw", height: "80vh", padding: "16px 24px 16px 24px" }}
        >
            <Box
                sx={{
                    paddingTop: "15px",
                    height: "13%",
                    width: "100%"
                }}>
                <Stepper activeStep={activeReviewStep} alternativeLabel>
                    {steps.map(({ state, text }) => (
                        <Step key={state}>
                            <StepLabel>{text}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box
                sx={{
                    paddingTop: "10px",
                    height: "7%"
                }}>
                <Typography variant="subtitle2">Comments</Typography>
            </Box>
            <Box
                sx={{
                    paddingTop: "5px",
                    height: "45%",
                    overflow: "auto"
                }}>
                <Grid container rowGap={1}>
                    {!!authedUser && (
                        (reviewComments.length > 0) ? reviewComments.map(comment => (
                            <CommentRow
                                viewerUser={authedUser}
                                comment={comment}
                                takerDocumentId={takerDocumentId}
                            />
                        )) : (
                            <i>no comments</i>
                        )
                    )}
                </Grid>
            </Box>
            <Box
                sx={{
                    height: "30%",
                    width: "100%",
                }}
            >
                {(isReviewer || isOwner) ? (
                    <Box
                        sx={{
                            height: "100%",
                            paddingTop: "5px",
                            padding: 0.5
                        }}
                    >
                        <Grid
                            container
                            sx={{
                                padding: 1,
                                outline: "1px solid #ddd",
                                backgroundColor: "rgb(25, 118, 210, 0.05)",
                                borderRadius: 1,
                                overflow: "auto",
                                height: "70%"
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    height: "100%"
                                }}
                            >
                                <Comment
                                    lexical={newLexical}
                                    onUpdate={(lexicalContent, textContent) => {
                                        setNewLexical(lexicalContent);
                                        setNewText(textContent);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                paddingTop: "10px",
                                height: "30%"
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    height: "100%"
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    disabled={disableComment}
                                    onClick={addCommentCallback}
                                >
                                    Post Comment
                                </Button>
                                {(isReviewer && activeReviewStep === 1) && (
                                    <Button
                                        sx={{ marginLeft: "5px" }}
                                        variant="contained"
                                        disabled={disableComment}
                                        onClick={addCommentAndIncrement}
                                    >
                                        Comment and Mark Review as Completed
                                    </Button>
                                )}
                                {(isReviewer && activeReviewStep === 1) && (
                                    <Button
                                        sx={{ marginLeft: "5px" }}
                                        variant="contained"
                                        disabled={disableComment}
                                        onClick={addCommentAndDecrement}
                                    >
                                        Comment and Move to Draft
                                    </Button>
                                )}
                                {(isOwner && activeReviewStep === 0) && (
                                    <Button
                                        sx={{ marginLeft: "5px" }}
                                        variant="contained"
                                        disabled={disableComment}
                                        onClick={addCommentAndIncrement}
                                    >
                                        Comment and Send for Review
                                    </Button>
                                )}
                                {((isOwner || isReviewer) && activeReviewStep === 2) && (
                                    <Button
                                        sx={{ marginLeft: "5px" }}
                                        variant="contained"
                                        disabled={disableComment}
                                        onClick={addCommentAndDecrement}
                                    >
                                        Comment and Move back to Review
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Typography>
                        Posting comments is disabled
                    </Typography>
                )}
            </Box>
        </SimpleModalWrapper>
    );
}

export default ReviewModal;
