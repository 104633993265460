import React, { useMemo, useState } from 'react';
import { Typography, Box, Stack, IconButton } from "@mui/material";
import RichTextEditor from "../../../../components/lexical/wrappers/RichTextEditor";
import { Commentary } from "../../../../types/builder.generated";
import { Close } from '@mui/icons-material';

interface ContentForCommentaryProps {
    commentaryValue: string;
    displayLexical: boolean;
};

const ContentForCommentary = ({
    commentaryValue,
    displayLexical
} : ContentForCommentaryProps) => {
    const [shouldDisplayLexical, setShouldDisplayLexical] = useState(displayLexical);
    if (shouldDisplayLexical) {
        return (
            <RichTextEditor
                displayOnly
                onEmptyEditor={() => {
                    setShouldDisplayLexical(false)
                }}
                contentLexical={commentaryValue}
            />
        );
    }
    return (
        <Typography
            paragraph
            sx={{
                "white-space": "pre-wrap"
            }}
        >
            {commentaryValue}
        </Typography>
    );
};

interface CommentaryDisplayObject {
    id: string;
    label: string;
    value?: string;
    nested?: ProcessedCommentaryObject;
    canAbsorbNestedLabel: boolean;
}

interface ProcessedCommentaryObject {
    displayObjects: CommentaryDisplayObject[],
};

export interface CommentaryDisplayProps {
    commentary: Commentary | undefined;
    onRemove: (id: string) => void;
    displayLexical?: boolean;
};

const CommentaryDisplay = ({
    commentary,
    onRemove,
    displayLexical = false
}: CommentaryDisplayProps) => {
    const processCommentaryList = (commentary: Commentary | undefined): ProcessedCommentaryObject => {
        const displayObjects = [];
        if (commentary) {
            for (const c of commentary) {
                let value = undefined;
                let nested = undefined;
                let canAbsorbNestedLabel = false;
                if (c.value) {
                    value = c.value;
                }
                if (c.nested) {
                    canAbsorbNestedLabel = (
                        c.nested.every(nc => !nc['value']) && 
                        c.nested.length === 1
                    )
                    nested = processCommentaryList(c.nested);
                }
                displayObjects.push({
                    id: c.id,
                    label: c.label,
                    value,
                    nested,
                    canAbsorbNestedLabel
                });
            }
        }
        return {
            displayObjects
        };
    };

    const processedCommentaryList = useMemo(
        () => processCommentaryList(commentary),
        [commentary]
    );

    const generateCommentaryList = (parentLabels: string[], pco: ProcessedCommentaryObject | undefined, depth: number): null | JSX.Element => {
        if (!pco || pco.displayObjects.length === 0) {
            return null;
        }

        return (
            <ul 
                style={{ 
                    listStyle: "none", 
                    paddingLeft: (depth === 0) ? "0px" : "20px" 
                }}
            >
                {pco.displayObjects.map((cdo: CommentaryDisplayObject) => (
                    cdo.canAbsorbNestedLabel ? (
                        generateCommentaryList([...parentLabels, cdo.label], cdo.nested, 0)
                    ) : (
                        <li>
                            <Box sx={{ display: "inline-block" }}>
                                {cdo.value ? (
                                    <Stack>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography
                                                component="span"
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                <strong>{cdo.label}</strong>
                                            </Typography>
                                            <IconButton
                                                size="small" 
                                                color="inherit"
                                                onClick={() => onRemove(cdo.id)}
                                            >
                                                <Close fontSize="small"/>
                                            </IconButton>
                                        </Stack>
                                        <Box 
                                            marginTop={1}
                                            marginBottom={1}
                                        >
                                            <ContentForCommentary
                                                commentaryValue={cdo.value}
                                                displayLexical={displayLexical}
                                            />
                                        </Box>
                                    </Stack>
                                ) : (
                                    <Typography
                                        component="span"
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        <i>{[...parentLabels, cdo.label].join(' • ')}</i>
                                    </Typography>
                                )}
                            </Box>
                            {cdo.nested && (
                                <Box sx={{ paddingTop: "2px" }}>
                                    {generateCommentaryList([], cdo.nested, depth + 1)}
                                </Box>
                            )}
                        </li>
                    )
                ))}
            </ul>
        );
    };
    return generateCommentaryList([], processedCommentaryList, 0);
};

export default CommentaryDisplay;