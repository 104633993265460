import { useMemo, memo } from "react";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import QuestionWrapper from "./QuestionWrapper";
import { PrimitiveType } from "./QuestionAnswer";
import { GraphFulfillmentState, MappingQuestionFulfillmentState, PercentageMappingQuestionFulfillmentState, TableFulfillmentState } from "../../../types/taker/fulfillmentstate.generated";
import { BasicDataSpec, BasicTableDataSpec, Commentary, GraphFulfillment, MappingQuestionDataSpec, MappingQuestionFulfillment, Module, PercentageMappingQuestionFulfillment, QuestionChoices, DisplayedVariableReference, TableFulfillment, TableWithSingleAnalysisDataSpec, DisplayedVariableReferences } from "../../../types/builderv2.generated";
import HasManyModuleStart from "./HasManyModuleStart";
import StartIteration from "./StartIteration";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { AnalysisState, PageElementIdentifier } from "../../../containers/WidgetWrapper/states";
import EndingNode from "./EndingNode";
import MappingQuestion from "./MappingQuestion";
import PercentMappingQuestion from "./PercentMappingQuestion";
import { AnalysisFieldState, ReferencesState } from "./QuestionFooter";
import TableWrapper from "./TableWrapper";


type QuestionInfoMapping = Record<string, {
    questionId: string;
    questionLabel: string;
    questionText: string;
    questionType: "radio" | "multiselect" | "input";
    choices?: QuestionChoices[] | DisplayedVariableReference | DisplayedVariableReferences;
    questionCommentary?: Commentary;
    analysisState?: AnalysisFieldState;
    referencesState?: ReferencesState;
}>;

type ModuleInfoMapping = Record<string, Module | undefined>;

interface ModuleNodeProps {
    modulePrefixes: string[];
    module: Module;
    setScrollableRefs: (
        id: { 
            moduleIds: string[];
            iteration: null | number;
            nodeId?: string;
        },
        ref: HTMLDivElement
    ) => void;
    setSelectedElement: (peid: PageElementIdentifier) => void;
}

const ModuleNode = ({ 
    modulePrefixes,
    module,
    setScrollableRefs,
    setSelectedElement
}: ModuleNodeProps) => {
    const { getState } = useWidgetState();
    const { fulfillmentStateHolder } = useTakerState();
    const collapsedState = getState<AnalysisState>().collapsedState || {};
    if (module.dataSpec?.dataSpecType === "basic-table" && module.uiFulfillment?.fulfillmentType === "graph" && fulfillmentStateHolder) {
        let moduleIds: string[] = [];
        moduleIds.push(...modulePrefixes);
        moduleIds.push(module.id);
        let mid = moduleIds.join("_");
        return (
            <>
                <HasManyModuleStart
                    modulePrefixes={modulePrefixes}
                    praxiModuleId={module.id}
                    fulfillment={module.uiFulfillment}
                />
                {[...Array(fulfillmentStateHolder.count(moduleIds)).keys()].map(iteration => {                
                    let isCollapsedUnset = (collapsedState[mid] === undefined) || (collapsedState[mid] && collapsedState[mid][iteration - 1] === undefined);
                    let isCollapsedSetToTrue = collapsedState[mid] && collapsedState[mid][iteration - 1];
                    return (
                        <>
                            <StartIteration 
                                moduleIds={moduleIds} 
                                iteration={iteration}
                            />
                            <ModuleFulfillment
                                modulePrefixes={modulePrefixes}
                                module={module}
                                iteration={iteration}
                                visible={!(isCollapsedUnset || isCollapsedSetToTrue)}
                                setScrollableRefs={setScrollableRefs}
                                setSelectedElement={setSelectedElement}
                            />
                        </>
                    );
                })}
            </>
        );
    } else if ((module.dataSpec?.dataSpecType === "basic" || module.dataSpec?.dataSpecType === "mapping-question" || module.dataSpec?.dataSpecType === "table-with-single-analysis") && fulfillmentStateHolder) {
        return (
            <ModuleFulfillment
                modulePrefixes={modulePrefixes}
                module={module}
                iteration={null}
                visible={true}
                
                setScrollableRefs={setScrollableRefs}
                setSelectedElement={setSelectedElement}
            />
        );
    }
    return null;
};

interface GraphFulfillmentComponentProps {
    moduleIds: string[];
    nestedModules: Module[];
    graphFulfillment: GraphFulfillment;
    dataSpec: BasicDataSpec | BasicTableDataSpec;
    iteration: number | null;
    visible: boolean;
    setScrollableRefs: (
        id: { 
            moduleIds: string[];
            iteration: null | number;
            nodeId?: string;
        },
        ref: HTMLDivElement
    ) => void;
    setSelectedElement: (peid: PageElementIdentifier) => void;
};

const GraphFulfillmentComponent = ({
    moduleIds,
    nestedModules,
    graphFulfillment,
    dataSpec,
    iteration,
    visible,
    setScrollableRefs,
    setSelectedElement
}: GraphFulfillmentComponentProps) => {
    const {
        fulfillmentStateHolder,
        questionnareDataService,
        takerPermissionState
    } = useTakerState();

    const findModuleInNested = (moduleId: string) => {
        for (const m of nestedModules) {
            if (m.id === moduleId) {
                return m;
            }
        }
    };

    const isReadOnly = useMemo(() => 
        takerPermissionState.isRead && !takerPermissionState.isReadWrite, 
        [takerPermissionState]
    );

    const dataTypeMapping: Record<string, PrimitiveType> = useMemo(() => {
        const values: Record<string, PrimitiveType> = {};
        if (dataSpec.fields) {
            for (const f of dataSpec.fields) {
                values[f.name] = f.primitive;
            }
        }
        return values;
    }, [dataSpec]);

    const nodeIdToQuestionInfo: QuestionInfoMapping = useMemo(() => {
        const mapping: QuestionInfoMapping = {};
        if (graphFulfillment.nodes) {
            for (const n of graphFulfillment.nodes) {
                if (n.nodeType === "question") {
                    mapping[n.id] = {
                        questionId: n.dataSpecFieldName,
                        questionLabel: n.questionLabel,
                        questionText: n.questionText,
                        questionType: n.questionType,
                        choices: n.choices,
                        questionCommentary: n.questionCommentary,
                        analysisState: n.analysisState,
                        referencesState: n.referencesState
                    }
                }
            }
        }
        return mapping;
    }, [graphFulfillment.nodes]);

    const nodeIdToModuleInfo: ModuleInfoMapping = useMemo(() => {
        const mapping: ModuleInfoMapping = {};
        if (graphFulfillment.nodes) {
            for (const n of graphFulfillment.nodes) {
                if (n.nodeType === "module") {
                    mapping[n.id] = findModuleInNested(n.moduleId)
                }
            }
        }   
        return mapping;
    }, [graphFulfillment.nodes]);

    const nodeIdToEndingInfo = useMemo(() => {
        const mapping: Record<
            string, 
            {
                statement?: string;
                label?: string;
            }
        > = {};
        if (graphFulfillment.nodes) {
            for (const n of graphFulfillment.nodes) {
                if (n.nodeType === "end") {
                    mapping[n.id] = { 
                        statement: n.statement,
                        label: n.label
                    };
                }
            }
        }
        return mapping;
    }, [graphFulfillment.nodes]);

    const graphState = useMemo(() => 
        fulfillmentStateHolder && fulfillmentStateHolder.getSubstate(moduleIds, iteration) as GraphFulfillmentState, 
        [fulfillmentStateHolder, moduleIds, iteration]
    );

    let orderedQuestionModules: JSX.Element[] = [];
    if (graphState) {
        for (let nid of graphState.activeNodePath) {
            let questionInfo = nodeIdToQuestionInfo[nid];
            let moduleInfo = nodeIdToModuleInfo[nid];
            let endInfo = nodeIdToEndingInfo[nid];
            if (questionInfo) {
                orderedQuestionModules.push(
                    <div 
                        style={{ 
                            display: visible ? "block": "none",
                            padding: 1
                        }}
                        ref={(ref) => {
                            if (ref) {
                                setScrollableRefs(
                                    {
                                        moduleIds,
                                        iteration,
                                        nodeId: nid
                                    },
                                    ref
                                );
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedElement({moduleIds, iteration, nodeId: nid});
                        }}
                    >
                        <QuestionWrapper
                            graphFulfillmentState={graphState}
                            nodeId={nid}
                            dataType={dataTypeMapping[questionInfo.questionId]}
                            questionId={questionInfo.questionId}
                            questionLabel={questionInfo.questionLabel}
                            questionText={questionInfo.questionText}
                            questionType={questionInfo.questionType}
                            choices={questionInfo.choices}
                            questionCommentary={questionInfo.questionCommentary}
                            analysisState={questionInfo.analysisState}
                            referencesState={questionInfo.referencesState}
                            moduleIds={moduleIds}
                            iteration={iteration}
                            defaultValue={questionnareDataService.getAnswer(moduleIds, questionInfo.questionId, iteration)}
                            onChangeValue={(a) => {
                                questionnareDataService.updateAnswer(moduleIds, questionInfo.questionId, a, iteration);
                            }}
                            readOnly={isReadOnly}
                        />
                    </div>
                );
            } else if (moduleInfo) {
                orderedQuestionModules.push(
                    <div 
                        style={{ 
                            display: visible ? "block": "none",
                            padding: 1
                        }}
                        ref={(ref) => {
                            if (ref) {
                                setScrollableRefs(
                                    {
                                        moduleIds,
                                        iteration,
                                        nodeId: nid
                                    },
                                    ref
                                );
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedElement({moduleIds, iteration, nodeId: nid});
                        }}
                    >
                        <ModuleNode
                            modulePrefixes={moduleIds}
                            module={moduleInfo}
                            setScrollableRefs={setScrollableRefs}
                            setSelectedElement={setSelectedElement}
                        />
                    </div>
                );
            } else if (endInfo) {
                orderedQuestionModules.push(
                    <div 
                        style={{ 
                            display: visible ? "block": "none",
                            padding: 1 
                        }}
                    >
                        <EndingNode 
                            graphFulfillmentState={graphState}
                            nodeId={nid}
                            moduleIds={moduleIds}
                            iteration={iteration}
                            label={endInfo.label}
                            statement={endInfo.statement}
                        />
                    </div>
                );
            }
        }
    }
    return (
        <>
            {orderedQuestionModules}
        </>
    );
};

interface MappingQuestonFulfillmentComponentProps {
    moduleIds: string[];
    mappingQuestionFulfillment: MappingQuestionFulfillment;
    dataSpec: MappingQuestionDataSpec;
    iteration: number | null;
    visible: boolean;
};

const MappingQuestonFulfillmentComponent = ({
    moduleIds,
    mappingQuestionFulfillment,
    dataSpec,
    iteration,
    visible
}: MappingQuestonFulfillmentComponentProps) => {
    const {
        isTakerStateDirty,
        fulfillmentStateHolder,
        questionnareDataService,
        takerPermissionState
    } = useTakerState();

    const isReadOnly = useMemo(() => 
        takerPermissionState.isRead && !takerPermissionState.isReadWrite, 
        [takerPermissionState]
    );

    const defaultValues: Record<string, any> | null = useMemo(() => {
        const values: Record<string, any> = {};
        let uniqueName = dataSpec.mappingToField.name;
        values[uniqueName] = questionnareDataService.getAnswer(moduleIds, uniqueName, iteration) || [];
        for (const f of dataSpec.mappedFields) {
            values[f.name] = questionnareDataService.getAnswer(moduleIds, f.name, iteration) || [];   
        }
        return values;
    }, [moduleIds, isTakerStateDirty, dataSpec]);

    const mappingQuestionState = useMemo(() => 
        fulfillmentStateHolder && fulfillmentStateHolder.getSubstate(moduleIds, iteration) as MappingQuestionFulfillmentState, 
        [fulfillmentStateHolder, moduleIds, iteration]
    );

    if (mappingQuestionState) {
        return (
            <div 
                style={{ 
                    display: visible ? "block": "none",
                    padding: 1
                }}
            >
                <MappingQuestion
                    moduleIds={moduleIds}
                    analysisId={dataSpec.otherDataSpecFieldName}
                    questionLabel={mappingQuestionFulfillment.questionLabel}
                    questionText={mappingQuestionFulfillment.questionText}
                    questionCommentary={mappingQuestionFulfillment.questionCommentary}
                    lhsChoices={mappingQuestionState.lhsChoices}
                    rhsChoices={mappingQuestionState.rhsChoices}
                    mappedFieldNames={dataSpec.mappedFields.map(mf => mf.name)}
                    uniqueMappingFieldName={dataSpec.mappingToField.name}
                    defaultValues={defaultValues}
                    onChangeValue={(qid, d) => 
                        questionnareDataService.updateAnswer(moduleIds, qid, d, iteration)
                    }
                    readOnly={isReadOnly}
                />
            </div>
        );  
    }
    return null;
};


interface PercentMappingQuestonFulfillmentComponentProps {
    moduleIds: string[];
    fulfillment: PercentageMappingQuestionFulfillment;
    dataSpec: MappingQuestionDataSpec;
    iteration: number | null;
    visible: boolean;
};

const PercentMappingQuestonFulfillmentComponent = ({
    moduleIds,
    fulfillment,
    dataSpec,
    iteration,
    visible
}: PercentMappingQuestonFulfillmentComponentProps) => {
    const {
        isTakerStateDirty,
        fulfillmentStateHolder,
        questionnareDataService,
        takerPermissionState
    } = useTakerState();

    const isReadOnly = useMemo(() => 
        takerPermissionState.isRead && !takerPermissionState.isReadWrite, 
        [takerPermissionState]
    );

    const defaultValues: Record<string, any> | null = useMemo(() => {
        const values: Record<string, any> = {};
        let uniqueName = dataSpec.mappingToField.name;
        values[uniqueName] = questionnareDataService.getAnswer(moduleIds, uniqueName, iteration) || [];
        for (const f of dataSpec.mappedFields) {
            values[f.name] = questionnareDataService.getAnswer(moduleIds, f.name, iteration) || [];   
        }
        return values;
    }, [moduleIds, isTakerStateDirty, dataSpec]);

    const mappingQuestionState = useMemo(() => 
        fulfillmentStateHolder && fulfillmentStateHolder.getSubstate(moduleIds, iteration) as PercentageMappingQuestionFulfillmentState, 
        [fulfillmentStateHolder, moduleIds, iteration]
    );

    if (mappingQuestionState) {
        return (
            <div 
                style={{ 
                    display: visible ? "block": "none",
                    padding: 1
                }}
            >
                <PercentMappingQuestion
                    moduleIds={moduleIds}
                    analysisId={dataSpec.otherDataSpecFieldName}
                    questionLabel={fulfillment.questionLabel}
                    questionText={fulfillment.questionText}
                    questionCommentary={fulfillment.questionCommentary}
                    lhsChoices={mappingQuestionState.lhsChoices}
                    validationWarnings={mappingQuestionState.validationWarnings}
                    mappedFieldNames={dataSpec.mappedFields.map(mf => mf.name)}
                    mappingToFieldName={dataSpec.mappingToField.name}
                    defaultValues={defaultValues}
                    onChangeValue={(qid, d) => 
                        questionnareDataService.updateAnswer(moduleIds, qid, d, iteration)
                    }
                    readOnly={isReadOnly}
                />
            </div>
        );  
    }
    return null;
};

interface TableFulfillmentComponentProps {
    moduleIds: string[];
    fulfillment: TableFulfillment;
    dataSpec: TableWithSingleAnalysisDataSpec;
    visible: boolean;
    iteration?: number | null;
};

const TableFulfillmentComponent = ({
    moduleIds,
    fulfillment,
    dataSpec,
    visible,
    iteration
}: TableFulfillmentComponentProps) => {
    const {
        isTakerStateDirty,
        fulfillmentStateHolder,
        takerPermissionState,
        activeTargetTakerState,
        getVariableValue
    } = useTakerState();

    const isReadOnly = useMemo(() => 
        takerPermissionState.isRead && !takerPermissionState.isReadWrite, 
        [takerPermissionState]
    );

    const dataTypeMap: Record<string, PrimitiveType> = useMemo(() => {
        if (!dataSpec || !dataSpec.fields) {
            return {};
        }

        const types: Record<string, PrimitiveType> = {}; 
        for (const f of dataSpec.fields) {
            types[f.name] = f.primitive;
        }
        return types;
        
    }, [moduleIds, isTakerStateDirty, dataSpec]);

    const tableFulfillmentState = useMemo(() => 
        fulfillmentStateHolder && fulfillmentStateHolder.getSubstate(moduleIds, null) as TableFulfillmentState, 
        [fulfillmentStateHolder, moduleIds]
    );

    const injectedVariable = useMemo(() => {
        if (fulfillment.completionContexts && activeTargetTakerState) {
            for (const cc of fulfillment.completionContexts) {
                if (cc.completionContextType === "variable-injected-loop-context") {
                    let table = getVariableValue(moduleIds, cc.variableReference);
                    if (!!table) {
                        return table.data;
                    }
                } 
            }
        }
    }, [fulfillment]);

    if (tableFulfillmentState) {
        return (
            <div 
                style={{ 
                    display: visible ? "block": "none",
                    padding: 1
                }}
            >
                <TableWrapper
                    moduleIds={moduleIds}
                    commentary={fulfillment.commentary}
                    dataTypeMap={dataTypeMap}
                    tableLabel={fulfillment.tableLabel}
                    tabelText={tableFulfillmentState.renderedTableText}
                    renderedQuestionChoices={tableFulfillmentState.renderedQuestionChoices}
                    validationWarnings={tableFulfillmentState.validationWarnings}
                    injectedTableRows={injectedVariable}
                    columns={fulfillment.columns}
                    dataSpec={dataSpec}
                    readOnly={isReadOnly}
                />
            </div>
        );  
    }
    return null;
};

interface ModuleFulfillmentProps {
    modulePrefixes: string[];
    module: Module;
    iteration: number | null;
    visible: boolean;
    setScrollableRefs: (
        id: { 
            moduleIds: string[];
            iteration: null | number;
            nodeId?: string;
        },
        ref: HTMLDivElement
    ) => void;
    setSelectedElement: (peid: PageElementIdentifier) => void;
}

const ModuleFulfillment = ({
    modulePrefixes,
    module,
    iteration,
    visible,
    setScrollableRefs,
    setSelectedElement
}: ModuleFulfillmentProps) => {
    let moduleIds = useMemo(() => {
        let mids = [];
        mids.push(...modulePrefixes);
        mids.push(module.id);
        return mids;
    }, [module.id, modulePrefixes]);

    return (
        <>
            {(module.uiFulfillment?.fulfillmentType === "graph" && (module.dataSpec?.dataSpecType === "basic" || module.dataSpec?.dataSpecType === "basic-table")) && (
                <GraphFulfillmentComponent
                    moduleIds={moduleIds}
                    nestedModules={module.nestedModules || []}
                    graphFulfillment={module.uiFulfillment}
                    dataSpec={module.dataSpec}
                    iteration={iteration}
                    visible={visible}
                    setScrollableRefs={setScrollableRefs}
                    setSelectedElement={setSelectedElement}
                />
            )}
            {(module.uiFulfillment?.fulfillmentType === "mapping-question" && module.dataSpec?.dataSpecType === "mapping-question") && (
                <MappingQuestonFulfillmentComponent
                    moduleIds={moduleIds}
                    mappingQuestionFulfillment={module.uiFulfillment}
                    dataSpec={module.dataSpec}
                    iteration={iteration}
                    visible={visible}
                />
            )}
            {(module.uiFulfillment?.fulfillmentType === "percentage-mapping-question" && module.dataSpec?.dataSpecType === "mapping-question") && (
                <PercentMappingQuestonFulfillmentComponent
                    moduleIds={moduleIds}
                    fulfillment={module.uiFulfillment}
                    dataSpec={module.dataSpec}
                    iteration={iteration}
                    visible={visible}
                />
            )}
            {(module.uiFulfillment?.fulfillmentType === "table" && module.dataSpec?.dataSpecType === "table-with-single-analysis") && (
                <TableFulfillmentComponent
                    moduleIds={moduleIds}
                    fulfillment={module.uiFulfillment}
                    dataSpec={module.dataSpec}
                    visible={visible}
                    iteration={iteration}
                />
            )}
        </>
    );
}

export default memo(ModuleFulfillment);