import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { BasicDataSpec, BasicTableDataSpec, GraphFulfillment, MappingQuestionDataSpec, MappingQuestionFulfillment, Module, PercentageMappingQuestionFulfillment, TableFulfillment, TableWithSingleAnalysisDataSpec } from "../../../types/builderv2.generated";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ArrowDownward, ArrowRight, Delete, Edit } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Grid, TextField, Typography } from "@mui/material";
import NodesEditor from "./nodes";
import VariablesEditor from "./variables";
import EdgesEditor from "./edges";
import GraphVisual from './graph';
import { CrudButtonGroup, buttonItem } from "../../../components/buttons/crudButtonGroup";

interface ModuleRowProps {
    praxiModule: Module;
    setEditModuleId: (mid: string) => void;
    onDelete: (moduleId: string) => void;
    level: number;
}

const ModuleRow = ({
    praxiModule,
    setEditModuleId,
    onDelete,
    level
}: ModuleRowProps) => {
    const [open, setOpen] = useState<boolean>(true);
    const buttons: buttonItem[] = [
        {
            disabled: (praxiModule.nestedModules?.length === 0),
            icon: (
                open ?
                    <ArrowDownward fontSize="small" /> :
                    <ArrowRight fontSize="small" />
            ),
            handleClick: () => {
                setOpen(!open);
            }
        },
        {
            icon: (<Edit fontSize="small" />),
            handleClick: () => {
                setEditModuleId(praxiModule.id);
            }
        }
    ];

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    }
                }}
            >
                <TableCell colSpan={level} />
                <TableCell component="th" scope="row">
                    {praxiModule.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {praxiModule.uiFulfillment?.fulfillmentType}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup buttons={buttons} />
                </TableCell>
            </TableRow>
            <Collapse in={open}>
                {praxiModule.nestedModules?.map(pm => (
                    <TableRow>
                        <TableCell colSpan={3 + level}>
                            <ModuleRow
                                level={level + 1}
                                praxiModule={pm}
                                setEditModuleId={setEditModuleId}
                                onDelete={onDelete}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </Collapse>
        </React.Fragment>
    );
};

interface ModuleEditorProps {
    fullSchema: RJSFSchema;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
}

const ModuleEditor = ({
    fullSchema,
    initialTopModule,
    onUpdate
}: ModuleEditorProps) => {
    const [editModuleId, setEditModuleId] = React.useState<string>(initialTopModule.id);
    const [newModuleId, setNewModuleId] = useState<string>('placeholder');
    const [newDisplayName, setNewDisplayName] = useState<string>('Placeholder');

    const [createModuleOpen, setCreateModuleOpen] = useState<boolean>(false);
    const [dataSpecOpen, setDataSpecOpen] = useState<boolean>(false);
    const [graphOpen, setGraphOpen] = useState<boolean>(false);
    const [fulfillmentOpen, setFulfillmentOpen] = useState<boolean>(false);

    const searchModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (moduleId === module.id) {
            return module;
        }

        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                let res = searchModuleHelper(moduleId, m);
                if (!!res) {
                    return res;
                }
            }
        }
        return null;
    };

    const editModule = React.useMemo(() => 
        searchModuleHelper(editModuleId, initialTopModule), 
        [editModuleId, initialTopModule]
    );

    const addModuleHelper = (moduleId: string, moduleToAdd: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (!newModule.nestedModules) {
                newModule.nestedModules = [];
            }
            newModule.nestedModules = addModuleHelper(moduleId, moduleToAdd, newModule.nestedModules);
            if (m.id === moduleId) {
                newModule.nestedModules.push(JSON.parse(JSON.stringify(moduleToAdd)));
            }
            newModules.push(newModule);
        }
        return newModules;
    };

    const addModule = (moduleId: string, m: Module) => {
        m.id = newModuleId;
        m.displayName = newDisplayName;
        const newTopModule = addModuleHelper(moduleId, m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    const searchParentModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                if (m.id === moduleId) {
                    return module;
                } else {
                    let res = searchParentModuleHelper(moduleId, m);
                    if (!!res) {
                        return res;
                    }
                }
            }
        }
        return null;
    };

    const updateModuleHelper = (moduleToUpdate: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (m.id === moduleToUpdate.id) {
                newModules.push(JSON.parse(JSON.stringify(moduleToUpdate)));
            } else if (newModule.nestedModules) {
                newModule.nestedModules = updateModuleHelper(moduleToUpdate, newModule.nestedModules);
                newModules.push(newModule);
            } else {
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateModule = (m: Module) => {
        const newTopModule = updateModuleHelper(m, [initialTopModule])[0];
        onUpdate(newTopModule);

    };

    const onDeleteHelper = (moduleId: string, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            if (m.id !== moduleId) {
                const newModule: Module = JSON.parse(JSON.stringify(m));
                if (m.nestedModules) {
                    newModule.nestedModules = onDeleteHelper(moduleId, m.nestedModules);
                }
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const onDelete = (moduleId: string) => {
        const newTopModule = onDeleteHelper(moduleId, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    const addBasicGraphModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "basic",
                fields: []
            } as BasicDataSpec,
            uiFulfillment: {
                fulfillmentType: "graph",
                nodes: [],
                edges: [],
                endConditions: []
            } as GraphFulfillment
        } as Module);
    };

    const addTableGraphModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "basic-table",
                fields: []
            } as BasicTableDataSpec,
            uiFulfillment: {
                fulfillmentType: "graph",
                nodes: [],
                edges: [],
                endConditions: []
            } as GraphFulfillment
        } as Module);
    };

    const addMappingQuestionModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "mapping-question"
            } as MappingQuestionDataSpec,
            uiFulfillment: {
                fulfillmentType: "mapping-question"
            } as MappingQuestionFulfillment
        } as Module);
    };

    const addPercentageMappingQuestionModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "mapping-question"
            } as MappingQuestionDataSpec,
            uiFulfillment: {
                fulfillmentType: "percentage-mapping-question"
            } as PercentageMappingQuestionFulfillment
        } as Module);
    };

    const addTableModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "table-with-single-analysis"
            } as TableWithSingleAnalysisDataSpec,
            uiFulfillment: {
                fulfillmentType: "table"
            } as TableFulfillment
        } as Module);
    };

    if (!editModule) {
        return null;
    }

    return (
        <>
            <Grid container rowGap={1}>
                <Grid item xs={12} p={1}>
                    <Typography variant="h5">
                        {editModule.displayName} - ({editModule.id})
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {initialTopModule.id !== editModule.id && (
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                let m = searchParentModuleHelper(editModule.id, initialTopModule);
                                if (!!m) {
                                    setEditModuleId(m.id);
                                }
                            }}
                        >
                            Back
                        </Button>
                    )}
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setCreateModuleOpen(true);
                        }}
                    >
                        Add Nested Module
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => setDataSpecOpen(true)}
                    >
                        Edit Data Specifications
                    </Button>
                    {editModule.uiFulfillment?.fulfillmentType === "graph" && (
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => setGraphOpen(true)}
                        >
                            Visualize Graph
                        </Button>
                    )}
                    {editModule.uiFulfillment?.fulfillmentType === "mapping-question" && (
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => setFulfillmentOpen(true)}
                        >
                            Edit Mapping Question Fulfillment Properties
                        </Button>
                    )}
                    {editModule.uiFulfillment?.fulfillmentType === "percentage-mapping-question" && (
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => setFulfillmentOpen(true)}
                        >
                            Edit Percent Mapping Question Fulfillment Properties
                        </Button>
                    )}
                    {editModule.uiFulfillment?.fulfillmentType === "table" && (
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => setFulfillmentOpen(true)}
                        >
                            Edit Table Fulfillment Properties
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="modules-content"
                            id="modules-header"
                        >
                            Nested Modules
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {editModule.nestedModules?.map(pm => (
                                            <TableRow>
                                                <TableCell>
                                                    <ModuleRow
                                                        level={0}
                                                        praxiModule={pm}
                                                        setEditModuleId={setEditModuleId}
                                                        onDelete={onDelete}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="variables-content"
                            id="variables-header"
                        >
                            Variables
                        </AccordionSummary>
                        <AccordionDetails>
                            <VariablesEditor
                                initialVariables={editModule.variableDefinitions}
                                fullSchema={fullSchema}
                                onUpdate={(vars) => {
                                    let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                    newMod.variableDefinitions = vars;
                                    updateModule(newMod);
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {(editModule.uiFulfillment && editModule.uiFulfillment.fulfillmentType === "graph") && (
                    <>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="nodes-content"
                                    id="nodes-header"
                                >
                                    Nodes
                                </AccordionSummary>
                                <AccordionDetails>
                                    <NodesEditor
                                        nodes={editModule.uiFulfillment.nodes || []}
                                        fullSchema={fullSchema}
                                        onUpdate={(nodes) => {
                                            let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                            if (newMod.uiFulfillment && newMod.uiFulfillment.fulfillmentType === "graph") {
                                                newMod.uiFulfillment.nodes = nodes;
                                                updateModule(newMod);
                                            }
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="edges-content"
                                    id="edges-header"
                                >
                                    Edges
                                </AccordionSummary>
                                <AccordionDetails>
                                    <EdgesEditor
                                        edges={editModule.uiFulfillment.edges || []}
                                        fullSchema={fullSchema}
                                        onUpdate={(edges) => {
                                            let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                            if (newMod.uiFulfillment && newMod.uiFulfillment.fulfillmentType === "graph") {
                                                newMod.uiFulfillment.edges = edges;
                                                updateModule(newMod);
                                            }
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {editModule.uiFulfillment?.fulfillmentType === "graph" && (
                            <SimpleModalWrapper
                                headerText="Visualize Graph"
                                open={graphOpen}
                                handleClose={() => setGraphOpen(false)}
                                sx={{ width: "80vw", height: "80vh", padding: "16px 24px 24px 24px" }}
                            >
                                <GraphVisual graph={editModule.uiFulfillment} />
                            </SimpleModalWrapper>
                        )}
                    </>
                )}
                {(editModule.uiFulfillment && editModule.uiFulfillment.fulfillmentType === "mapping-question") && (
                    <SimpleModalWrapper
                        headerText="Mapping Question fulfillment"
                        open={fulfillmentOpen}
                        handleClose={() => setFulfillmentOpen(false)}
                        sx={{ width: "80vw", padding: "16px 24px 24px 24px" }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                paddingTop: '3%',
                                alignContent: "center"
                            }}
                        >
                            <Form
                                formData={editModule.uiFulfillment}
                                onSubmit={(data, event) => {
                                    let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                    newMod.uiFulfillment = data.formData;
                                    setFulfillmentOpen(false);
                                    updateModule(newMod);
                                }}
                                schema={({
                                    $ref: "#/$defs/mappingQuestionFulfillment",
                                    $defs: fullSchema.$defs
                                } as RJSFSchema)}
                                validator={validator}
                            />
                        </Box>
                    </SimpleModalWrapper>
                )}
                {(editModule.uiFulfillment && editModule.uiFulfillment.fulfillmentType === "percentage-mapping-question") && (
                    <SimpleModalWrapper
                        headerText="Percentage Mapping Question fulfillment"
                        open={fulfillmentOpen}
                        handleClose={() => setFulfillmentOpen(false)}
                        sx={{ width: "80vw", padding: "16px 24px 24px 24px" }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                paddingTop: '3%',
                                alignContent: "center"
                            }}
                        >
                            <Form
                                formData={editModule.uiFulfillment}
                                onSubmit={(data, event) => {
                                    let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                    newMod.uiFulfillment = data.formData;
                                    setFulfillmentOpen(false);
                                    updateModule(newMod);
                                }}
                                schema={({
                                    $ref: "#/$defs/percentageMappingQuestionFulfillment",
                                    $defs: fullSchema.$defs
                                } as RJSFSchema)}
                                validator={validator}
                            />
                        </Box>
                    </SimpleModalWrapper>
                )}
                {(editModule.uiFulfillment && editModule.uiFulfillment.fulfillmentType === "table") && (
                    <SimpleModalWrapper
                        headerText="Table fulfillment"
                        open={fulfillmentOpen}
                        handleClose={() => setFulfillmentOpen(false)}
                        sx={{ width: "80vw", padding: "16px 24px 24px 24px" }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                paddingTop: '3%',
                                alignContent: "center"
                            }}
                        >
                            <Form
                                formData={editModule.uiFulfillment}
                                onSubmit={(data, event) => {
                                    let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                    newMod.uiFulfillment = data.formData;
                                    setFulfillmentOpen(false);
                                    updateModule(newMod);
                                }}
                                schema={({
                                    $ref: "#/$defs/tableFulfillment",
                                    $defs: fullSchema.$defs
                                } as RJSFSchema)}
                                validator={validator}
                            />
                        </Box>
                    </SimpleModalWrapper>
                )}
                <SimpleModalWrapper
                    headerText="Data Specifications"
                    open={dataSpecOpen}
                    handleClose={() => setDataSpecOpen(false)}
                    sx={{ width: "80vw", padding: "16px 24px 24px 24px" }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '3%',
                            alignContent: "center"
                        }}
                    >
                        <Form
                            formData={editModule.dataSpec}
                            onSubmit={(data, event) => {
                                let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                newMod.dataSpec = data.formData;
                                setDataSpecOpen(false);
                                updateModule(newMod);
                            }}
                            schema={({
                                anyOf: [
                                    {
                                        "$ref": "#/$defs/basicDataSpec"
                                    },
                                    {
                                        "$ref": "#/$defs/basicTableDataSpec"
                                    },
                                    {
                                        "$ref": "#/$defs/mappingQuestionDataSpec"
                                    },
                                    {
                                        "$ref": "#/$defs/tableWithSingleAnalysisDataSpec"
                                    }
                                ],
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator}
                        />
                    </Box>
                </SimpleModalWrapper>
                <SimpleModalWrapper
                    headerText="Create Module"
                    open={createModuleOpen}
                    handleClose={() => setCreateModuleOpen(false)}
                    sx={{ width: "80vw", padding: "16px 24px 24px 24px" }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '3%',
                            alignContent: "center"
                        }}
                    >
                        <TextField
                            sx={{ m: 1 }}
                            size="small"
                            value={newModuleId}
                            onChange={(e) => {
                                setNewModuleId(e.target.value);
                            }}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            size="small"
                            value={newDisplayName}
                            onChange={(e) => {
                                setNewDisplayName(e.target.value);
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '3%',
                            alignContent: "center"
                        }}
                    >
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addBasicGraphModule(editModule.id);
                                setCreateModuleOpen(false);
                            }}
                        >
                            Basic Graph Module
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addTableGraphModule(editModule.id);
                                setCreateModuleOpen(false);
                            }}
                        >
                            Table Graph Module
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addMappingQuestionModule(editModule.id);
                                setCreateModuleOpen(false);
                            }}
                        >
                            Mapping Question Module
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addPercentageMappingQuestionModule(editModule.id);
                                setCreateModuleOpen(false);
                            }}
                        >
                            Percent Mapping Question Module
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addTableModule(editModule.id);
                                setCreateModuleOpen(false);
                            }}
                        >
                            Table Module
                        </Button>
                    </Box>
                </SimpleModalWrapper>
            </Grid>
        </>
    );
}

export default ModuleEditor;
