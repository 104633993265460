import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Application } from "../../redux/models/dataModelTypes";
import PagedTable from '../../components/tables/pagedTable';
import { useListApplicationsQuery } from '../../redux/services/application';
import { UpdateApplicationModal } from './updateApplicationModal';
import { CrudButtonGroup } from '../../components/buttons/crudButtonGroup';


export const ApplicationTable = () => {
  const [applicationIdToEdit, setApplicationIdToEdit] = useState<string>();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const { data: applications } = useListApplicationsQuery({ page, limit });

  const hasApplicationToEdit = Boolean(applicationIdToEdit);

  return (
    <>
      <PagedTable
        paginatedResponse={applications}
        tableSpan={5}
        rowMapper={(app: Application) => (
          <TableRow
            key={app.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {app.tag?.type}
            </TableCell>
            <TableCell style={{ width: 160 }} align="right">
              <CrudButtonGroup 
                buttons={[
                  {
                    type: "edit",
                    handleClick: () => {
                      setApplicationIdToEdit(app.id);
                    }
                  }
                ]} 
              />
            </TableCell>
          </TableRow>
        )}
        headers={[
          "Type",
          ""
        ]}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
      {applicationIdToEdit !== undefined && (
        <UpdateApplicationModal
          applicationId={applicationIdToEdit}
          open={hasApplicationToEdit}
          onClose={() => setApplicationIdToEdit(undefined)} />
      )}
    </>
  );
}
