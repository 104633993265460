import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import KeyTermGroupState, { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';
import { TakerDocumentUpload } from '../../../../redux/models/dataModelTypes';
import { KeyTerm } from '../../../../types/taker/documentkeyterms.generated';
import { Add } from '@mui/icons-material';
import { KeyTermSource } from '../../../../types/taker/uidatastate.generated';

interface PartialKeyTerm {
    id: string;
    name: string;
}

interface InnerSourceListProps {
    existing: KeyTermSource[]
    onUpdateSelectedKeyTerms: (skt: PartialKeyTerm[]) => void;
};

const InnerSourceList = ({
    existing,
    onUpdateSelectedKeyTerms
}: InnerSourceListProps) => {
    const [keyTerms, setKeyTerms] = useState<string[]>([]);
    const {
        documentKeyTermsService,
        lastSavedTimestamp,
        takerDocumentUpload 
    } = useKeyTermGroupState();

    const allKeyTerms = useMemo(() =>
        documentKeyTermsService?.keyTerms || [],
        [lastSavedTimestamp, documentKeyTermsService]
    );

    const existingKeyTermIds = useMemo(() =>
        new Set(existing
            .filter(s => s.keyTermGroupIdentifier === takerDocumentUpload?.id)
            .map(s => s.keyTermIdentifier)
        ),
        [takerDocumentUpload]
    );

    useEffect(() => {
        const selected = []
        for (const ktId of keyTerms) {
            const kt = allKeyTerms.find(kt => kt.identifier === ktId);
            if (kt?.identifier) {
                selected.push({
                    id: kt.identifier,
                    name: kt?.termName
                });
            }
        }
        onUpdateSelectedKeyTerms(selected);
    }, [allKeyTerms, keyTerms]);

    return (
        <FormControl sx={{ minWidth: 120 }}>
            <Select
                size="small"
                data-testid="key-term-name-select"
                multiple
                value={keyTerms}
                onChange={(event: SelectChangeEvent<string[]>) => {
                    const {
                        target: { value },
                    } = event;
                    setKeyTerms(
                        typeof value === 'string' ? value.split(',') : value,
                    );
                }}
                placeholder="Key Term Names"
            >
                {allKeyTerms.map((kt: KeyTerm) => (
                    <MenuItem value={kt.identifier} disabled={!!kt.identifier && existingKeyTermIds.has(kt.identifier)}>
                        {kt.termName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

interface SourcesListProps {
    readOnly: boolean;
    defaultValue: KeyTermSource[];
    onUpdate: (s: KeyTermSource[]) => void;
}

const SourcesList = ({
    readOnly,
    defaultValue,
    onUpdate
}: SourcesListProps) => {
    const [keyTermGroupId, setKeyTermGroupId] = useState<string | undefined>();
    const [selectedKeyTerms, setSelectedKeyTerms] = useState<PartialKeyTerm[]>([]);
    const { activeTakerDocument } = useTakerState();

    const allKeyTermGroups = useMemo(() =>
        activeTakerDocument?.takerDocumentUploads || [],
        [activeTakerDocument]
    );

    return (
        <Box marginTop={1}>
            <Stack>
                {defaultValue.length > 0 ? (
                    <Box paddingBottom={2}>
                        <Stack direction="row" spacing={1}>
                            {defaultValue.map((source) => 
                                <Chip 
                                    label={source.keyTermName} 
                                    onDelete={readOnly ? undefined : (() => {
                                        onUpdate(defaultValue.filter(s => s.keyTermIdentifier !== source.keyTermIdentifier));
                                    })}
                                />
                            )}
                        </Stack>
                    </Box>
                ) : (
                    <Box paddingBottom={2}>
                        <Typography variant="body1">
                            <i>None</i>
                        </Typography>
                    </Box>
                )}
                {!readOnly && (
                    <Stack direction="row" spacing={1}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                size="small"
                                displayEmpty
                                data-testid="key-term-group-name-select"
                                value={keyTermGroupId}
                                onChange={(event: SelectChangeEvent<string>) => {
                                    setKeyTermGroupId(event.target.value);
                                }}
                                label=""
                            >
                                <MenuItem value={undefined}>
                                    Select a Group
                                </MenuItem>
                                {allKeyTermGroups.map((ktg: TakerDocumentUpload) => (
                                    <MenuItem value={ktg.id}>
                                        {ktg.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {keyTermGroupId && (
                            <KeyTermGroupState takerDocumentUploadId={keyTermGroupId}>
                                <InnerSourceList
                                    existing={defaultValue}
                                    onUpdateSelectedKeyTerms={setSelectedKeyTerms}
                                />
                            </KeyTermGroupState>
                        )}
                        <IconButton
                            data-testid="add-source-button"
                            disabled={(selectedKeyTerms.length === 0)}
                            onClick={() => {
                                const newSources = [...defaultValue];
                                const ktg = allKeyTermGroups.find(g => g.id === keyTermGroupId);
                                if (ktg) {
                                    for (const selectedKeyTerm of selectedKeyTerms) {
                                        newSources.push({
                                            type: "KEY_TERM",
                                            keyTermIdentifier: selectedKeyTerm.id,
                                            keyTermName: selectedKeyTerm.name,
                                            keyTermGroupIdentifier: ktg.id,
                                            keyTermGroupName: ktg?.name
                                        });
                                    }
                                    onUpdate(newSources);
                                }
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </Box>
    );
}

export default memo(SourcesList);
