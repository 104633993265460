import React, { useState } from 'react';
import { Card, CardContent, Divider, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Commentary } from '../../../../types/builderv2.generated';
import { CommentaryOrGuidanceReference } from '../../../../types/taker/uidatastate.generated';
import ResearchGuidance from './ResearchGuidance';
import ResearchKeyTerms from './ResearchKeyTerms';

interface ResearchCardProps {
    commentary?: Commentary;
    enableGuidance: boolean;
}

const ResearchCard = ({
    commentary,
    enableGuidance,
}: ResearchCardProps) => {
    const researchOptions = [
        {
            id: "key-terms",
            display: "Key Terms"
        }
    ];
    if (enableGuidance || (commentary && commentary.length > 0)) {
        researchOptions.push({
            id: "commentary-guidance",
            display: "Commentary and Guidance"
        });
    }

    const [selectedResearchType, setSelectedResearchType] = useState<string | undefined>(researchOptions[0].id);

    return (
        <Card
            variant="outlined"
            sx={{
                height: "100%",
                overflowY: "auto"
            }}
        >
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h6">
                        Research
                    </Typography>
                    <Select
                        size="small"
                        value={selectedResearchType}
                        label=""
                        placeholder="Research Options"
                        data-testid="research-type-select"
                        onChange={(e) => {
                            setSelectedResearchType(e.target.value);
                        }}
                        inputProps={{
                            sx: {
                                paddingTop: "1px",
                                paddingBottom: "1px",
                                paddingLeft: "4px"
                            }
                        }}
                    >
                        {researchOptions.map(o => (
                            <MenuItem value={o.id}>
                                {o.display}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                {selectedResearchType === "commentary-guidance" && (
                    <ResearchGuidance commentary={commentary} />
                )}
                {selectedResearchType === "key-terms" && (
                    <ResearchKeyTerms />
                )}
            </CardContent>
        </Card>
    );
}

export default ResearchCard;