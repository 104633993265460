import { RJSFSchema, getDefaultFormState } from "@rjsf/utils";
import validator from '@rjsf/validator-ajv8';
import { ListResponse } from "../../redux/models/commonTypes";
import { ApplicationConfigurationEntry, ApplicationTag } from "../../redux/models/dataModelTypes";
import { FeatureFlags } from "../../types/featureflags.generated";

export type FeatureFlagNames = keyof FeatureFlags;

export class FlagProvider {
    _configurationEntries: Array<ApplicationConfigurationEntry>;
    _orgConfigurationEntries: Array<ApplicationConfigurationEntry>;    
    _defaultFlagValues: any;
    _organizationId: string;

    constructor(organizationId: string, ) {
        this._organizationId = organizationId;
        this._configurationEntries = new Array();
        this._orgConfigurationEntries = new Array();
        this._defaultFlagValues = {};
    }

    setConfigurationEntriesForApplicationTags(listResponse: ListResponse<ApplicationTag>) {
        for (const appTag of listResponse.data) {
            if (!appTag.application || !appTag.application.configuration) {
                continue;
            }

            if (appTag.type === "PRIMARY") {
                this._configurationEntries.push(...appTag.application.configuration.entries);
            } else if (appTag.type === "ORGANIZATION" && appTag.data === this._organizationId) {
                this._orgConfigurationEntries.push(...appTag.application.configuration.entries);
            } 
        }
    }

    setDefaultFlagValues(featureFlagSchema: RJSFSchema) {
        this._defaultFlagValues = getDefaultFormState(validator, featureFlagSchema, undefined, featureFlagSchema);
    }

    setPartialDefaultFlagValues(defaults: FeatureFlags) {
        for (const [flagName, flagValue] of Object.entries(defaults)) {
            this._defaultFlagValues[flagName] = flagValue;
        }
    }

    populateFlagValues(defaultFlags: FeatureFlagNames[]) : FeatureFlags {
        const flags: Record<string, any> = {};
        for (const flagName of defaultFlags) {
            let flagVal = !!this._defaultFlagValues ? this._defaultFlagValues[flagName] : undefined;
            for (const entry of this._configurationEntries) {
                if (entry.key === flagName) {
                    console.debug(`found flag value for ${flagName} in base configuration`);
                    flagVal = entry.data;
                }
            }
            for (const entry of this._orgConfigurationEntries) {
                if (entry.key === flagName) {
                    if (flagVal) {
                        console.debug(`overriding flag value for ${flagName} in org configuration`);
                    } else {
                        console.debug(`found flag value for ${flagName} in org configuration`);
                    }
                    flagVal = entry.data;
                }
            }
            flags[flagName] = flagVal;
        }
        return flags as FeatureFlags;
    }
}
