import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { KeyTerm } from "../../../types/builderv2.generated";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Delete, Edit } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";

interface KeyTermRowProps {
    keyTerm: KeyTerm; 
    setEditKeyTerm: (n: KeyTerm) => void;
    onDelete: () => void;
}

const KeyTermRow = ({ 
    keyTerm, 
    setEditKeyTerm,
    onDelete
}: KeyTermRowProps) => {
    return (
        <React.Fragment>    
            <TableRow 
                sx={{ 
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    {keyTerm.label}
                </TableCell>
                <TableCell component="th" scope="row">
                    {keyTerm.description}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup
                        buttons={[
                            {
                                icon: (<Edit fontSize="small"/>),
                                handleClick: () => {
                                    setEditKeyTerm(keyTerm);
                                }
                            },
                            {
                                icon: (<Delete fontSize="small"/>),
                                handleClick: () => {
                                    onDelete();
                                }
                            }
                        ]} 
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

interface KeyTermsEditorProps {
    fullSchema: RJSFSchema;
    initialKeyTerms?: KeyTerm[];
    onUpdate: (b: KeyTerm[]) => void;
} 

const KeyTermsEditor = ({ 
    fullSchema,
    initialKeyTerms,
    onUpdate
}: KeyTermsEditorProps) => {
    const [editKeyTerm, setEditKeyTerm] = React.useState<KeyTerm | null>(null);
    const [keyTerms, setKeyTerms] = React.useState<KeyTerm[]>([]);
    const [adding, setAdding] = React.useState<boolean>(false);

    useEffect(() => {
        if (initialKeyTerms) {
            setKeyTerms(JSON.parse(JSON.stringify(initialKeyTerms)));
        }
    }, [initialKeyTerms]);

    const updateKeyTerm = (rt: KeyTerm) => {
        const newKeyTerms = JSON.parse(JSON.stringify(keyTerms));
        if (adding) {
            newKeyTerms.push(rt);
        } else {
            for (let i = 0; i < newKeyTerms.length; i ++) {
                if (rt.label === newKeyTerms[i].label) {
                    newKeyTerms[i] = rt;
                }
            }
        }

        onUpdate(newKeyTerms);
        setAdding(false);
    };

    const onDelete = (i: number) => {
        const newKeyTerms = JSON.parse(JSON.stringify(keyTerms));
        newKeyTerms.splice(i, 1);
        onUpdate(newKeyTerms);
    };

    return (
        <>
            <Grid container p={1}>
                <Grid item xs={12}>
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setAdding(true);
                            setEditKeyTerm({
                                label: "",
                                description: ""
                            } as KeyTerm);
                        }}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Label
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keyTerms.map((rt, i) => (
                            <KeyTermRow 
                                key={rt.label} 
                                keyTerm={rt}
                                setEditKeyTerm={setEditKeyTerm}
                                onDelete={() => {
                                    onDelete(i);
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleModalWrapper
                headerText="Edit Key Term"
                open={!!editKeyTerm}
                handleClose={() => setEditKeyTerm(null)}
                sx={{ width: "80vw", padding: "16px 24px 24px 24px" }}
            >
                <Box 
                    sx={{ 
                        width: '100%',
                        paddingTop: '3%',
                        alignContent: "center"
                    }}
                >
                    {editKeyTerm && (
                       <Form
                            formData={editKeyTerm}
                            onSubmit={(data, event) => {
                                updateKeyTerm(data.formData);
                                setEditKeyTerm(null)
                            }}
                            schema={({
                                $ref: "#/$defs/keyTerm",
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator} 
                        />  
                    )}
                </Box>
            </SimpleModalWrapper>
        </>
    );
  }

export default KeyTermsEditor;
