import React, { useMemo, useRef, useState } from 'react';
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import AnalysisField from './AnalysisField';
import GuidanceList from './GuidanceList';
import SourcesList from './SourcesList';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../../../types/taker/uidatastate.generated';
import { Choices1 } from '../../../../types/taker/fulfillmentstate.generated';
import { Commentary, TableWithSingleAnalysisDataSpec } from '../../../../types/builderv2.generated';
import { BoltButton } from '../../../../components/buttons/boltButton';
import TableComponent, { ColumnType, PrimitiveType } from '../TableComponent';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';
import { useUserScopedAppData } from '../../../../containers/UserScopedAppData/UserScopedAppData';

const TOP_CENTER_OPTION = {
    variant: 'info',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
} as OptionsWithExtraProps<'info'>;

interface TableAiGeneratedCardProps {
    moduleIds: string[];
    commentary?: Commentary;
    dataTypeMap: Record<string, PrimitiveType>;
    tableLabel?: string;
    tabelText?: {
        text: string;
        rendered: boolean;
    };
    renderedQuestionChoices?: {
        dataSpecFieldName: string;
        choices: Choices1[];
        rendered: boolean;
    }[];
    validationWarnings?: string[];
    injectedTableRows?: Record<string, string>[];
    columns: ColumnType[];
    dataSpec: TableWithSingleAnalysisDataSpec;
}

const TableAiGeneratedCard = ({
    moduleIds,
    dataTypeMap,
    tabelText,
    renderedQuestionChoices,
    validationWarnings,
    injectedTableRows,
    columns,
    dataSpec
}: TableAiGeneratedCardProps) => {
    const { questionnareDataService } = useTakerState();
    const { enqueueSnackbar } = useSnackbar();
    const { flagProvider } = useUserScopedAppData();
    const [rows, setRows] = useState<any[][]>([]);
    const [analysis, setAnalysis] = useState<string>("");
    const [reference, setReference] = useState<CommentaryOrGuidanceReference[]>([]);
    const [source, setSource] = useState<KeyTermSource[]>([]);

    const mockDefaultRows: any[][] = useMemo(() => {
        if (!dataSpec || !dataSpec.fields) {
            return [];
        }

        const firstField = dataSpec.fields[0];
        const firstValues = questionnareDataService.getMockAnswer(moduleIds, firstField.name, null);

        const rows: any[][] = [];
        if (!!firstValues) {
            for (const v of firstValues) {
                rows.push([v]);
            }
            for (let i = 1; i < dataSpec.fields.length; i++) {
                let field = dataSpec.fields[i];
                let values = questionnareDataService.getMockAnswer(moduleIds, field.name, null);
                for (let j = 0; j < values.length; j++) {
                    rows[j].push(values[j]);
                }
            }
        }
        return rows;
    }, [moduleIds, dataSpec, questionnareDataService]);

    const handleClick = () => {
        if (mockDefaultRows.length > 0) {
            setRows(mockDefaultRows);
            if (dataSpec.otherDataSpecFieldName) {
                setAnalysis(questionnareDataService.getMockAnalysis(moduleIds, dataSpec.otherDataSpecFieldName, null) || "");
                setReference(questionnareDataService.getMockReference(moduleIds, dataSpec.otherDataSpecFieldName, null) || []);
                setSource(questionnareDataService.getMockSource(moduleIds, dataSpec.otherDataSpecFieldName, null) || []);
            }
        } else {
            enqueueSnackbar("AI Generated Answer is not available", TOP_CENTER_OPTION);
        }
    };

    let flags = flagProvider.populateFlagValues([
        "DEMO_MODE__enable_demo_mode",
        "AI_FEATURES_QUESTIONNAIRE__enable_ai_for_questionnaire"
    ]);
    let tooltipText = "Generate AI Answer";
    let disableButton = false;
    if(flags.DEMO_MODE__enable_demo_mode === "TRUE" && flags.AI_FEATURES_QUESTIONNAIRE__enable_ai_for_questionnaire === "FALSE") {
        tooltipText = "Generate AI Answer";
    } else if(flags.DEMO_MODE__enable_demo_mode === "FALSE" && flags.AI_FEATURES_QUESTIONNAIRE__enable_ai_for_questionnaire === "FALSE") {
        tooltipText = "AI Answer Generation coming soon!";
        disableButton = true;
    }

    return (
        <Card variant="outlined" sx={{ height: "100%", overflowY: "auto" }}>
            <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        Proposed
                    </Typography>
                    <React.Fragment>
                        <BoltButton
                            boltId="table-generate-answer"
                            disableMenu={true}
                            disableAll={disableButton}
                            tooltipText={tooltipText}
                            onClick={() => handleClick()}
                            onClickDelay={(Math.random() * 3000) + 3000}
                            size="small"
                        />
                    </React.Fragment>
                </Stack>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                {tabelText && (
                    <Box
                        alignItems="left"
                        width="100%"
                        display="flex"
                        marginBottom={1}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                "white-space": "pre-wrap"
                            }}
                        >
                            {tabelText.text}
                        </Typography>
                    </Box>
                )}
                <TableComponent
                    isSaving={false}
                    dataTypeMap={dataTypeMap}
                    renderedQuestionChoices={renderedQuestionChoices}
                    validationWarnings={validationWarnings}
                    injectedTableRows={injectedTableRows}
                    columns={columns}
                    defaultRows={rows}
                    onChangeColumns={() => { }}
                    hasToolbarDefaults={true}
                    readOnly={true}
                />
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>Analysis</strong>
                    </Typography>
                    <AnalysisField
                        readOnly={true}
                        defaultValue={analysis}
                        onUpdate={() => { }}
                    />
                </Box>
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>Guidance</strong>
                    </Typography>
                    <GuidanceList
                        readOnly={true}
                        defaultValue={reference}
                        onUpdate={() => { }}
                    />
                </Box>
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>Sources</strong>
                    </Typography>
                    <SourcesList
                        readOnly={true}
                        defaultValue={source}
                        onUpdate={() => { }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default TableAiGeneratedCard;
