import React, { useMemo, useState } from "react";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material/";
import { useDeleteTakerDocumentReviewCommentMutation } from "../../../../redux/services/taker";
import { ExpandLessOutlined, ExpandMoreOutlined, MoreHoriz } from "@mui/icons-material";
import { TakerDocumentReviewComment, User } from "../../../../redux/models/dataModelTypes";
import Comment from "./Comment";

interface CommentRowProps {
    viewerUser: User;
    comment: TakerDocumentReviewComment;
    takerDocumentId: string;
}

const MS_IN_MINUTE = 1000 * 60;
const MS_IN_HOUR = MS_IN_MINUTE * 60;

function CommentRow({
    viewerUser,
    comment,
    takerDocumentId
}: CommentRowProps) {
    const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [
        deleteTakerDocumentReviewComment,
        deleteTakerDocumentReviewCommentRes
    ] = useDeleteTakerDocumentReviewCommentMutation();

    const userDisplay = useMemo(() => {
        let user = comment.user;
        let now = new Date();
        let createdDatetime = new Date(comment.createdAt);
        let diff = (now.getTime() - createdDatetime.getTime());
        if (diff < MS_IN_MINUTE) {
            return `By ${user.firstName} ${user.lastName} just now`;
        } else if (diff < (12 * MS_IN_HOUR)) {
            return `By ${user.firstName} ${user.lastName} at ${createdDatetime.toLocaleTimeString()}`;
        }
        return `By ${user.firstName} ${user.lastName} at ${createdDatetime.toLocaleTimeString()} on ${createdDatetime.toLocaleDateString()}`;
    }, [comment]);

    const contentShort = useMemo(() => {
        let t = comment.commentText;
        if (t.length > 30) {
            return `${t.substring(0, 30)}...`;
        }
        return t;
    }, [comment]);

    const settings = useMemo(() => {
        const open = Boolean(settingsAnchorEl);

        let menuItems = [];
        if (viewerUser.id === comment.userId) {
            menuItems.push(
                <MenuItem
                    color="inherit"
                    disableRipple
                    onClick={(e: any) => {
                        deleteTakerDocumentReviewComment({
                            id: comment.id,
                            takerDocumentId
                        });
                    }}
                >
                    Delete
                </MenuItem>
            );
        }

        if (menuItems.length > 0) {
            return (
                <>
                    <IconButton
                        size="small"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(event: React.MouseEvent<HTMLElement>) => setSettingsAnchorEl(event.currentTarget)}
                    >
                        <MoreHoriz />
                    </IconButton>
                    <Menu
                        elevation={1}
                        anchorEl={settingsAnchorEl}
                        open={open}
                        onClose={() => setSettingsAnchorEl(null)}
                    >
                        {menuItems}
                    </Menu>
                </>
            );
        }
        return null;
    }, [settingsAnchorEl, viewerUser, comment]);

    return (
        <Grid
            sx={{
                padding: 1,
                paddingRight: 1,
                backgroundColor: "rgb(25, 118, 210, 0.05)",
                borderRadius: 1
            }}
            item
            xs={12}
        >
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <div>
                    <i style={{ opacity: 0.5 }}>{userDisplay}</i>
                    {collapsed && (
                        <>
                            {" - "}<span>{contentShort}</span>
                        </>
                    )}
                </div>
                <div>
                    <IconButton
                        size="small"
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {collapsed ?
                            <ExpandLessOutlined /> :
                            <ExpandMoreOutlined />
                        }
                    </IconButton>
                    {!!settings && settings}
                </div>
            </Grid>
            {!collapsed && (
                <Grid
                    item
                    xs={12}
                >
                    <Comment lexical={comment.commentLexical} />
                </Grid>
            )}
        </Grid>
    );
}

export default CommentRow;
