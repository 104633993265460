import { useMemo, useState } from 'react';
import { Box, Button, Grid, Card, CardContent, Typography, CardHeader, Toolbar, Stack, Select, MenuItem, SelectChangeEvent, Divider } from '@mui/material';
import SourcesList from './SourcesList';
import AnalysisField from './AnalysisField';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { Commentary, DisplayedVariableReference, DisplayedVariableReferences, StaticChoices } from '../../../../types/builderv2.generated';
import GuidanceList from './GuidanceList';
import QuestionAnswer, { PrimitiveType } from '../QuestionAnswer';
import TableComponent, { ColumnType } from '../TableComponent';
import { Choices1 } from '../../../../types/taker/fulfillmentstate.generated';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../../../types/taker/uidatastate.generated';

interface CurrentTableAnswerCardProps {
    moduleIds: string[];
    commentary?: Commentary;
    dataTypeMap: Record<string, PrimitiveType>;
    tableLabel?: string;
    tabelText?: {
        text: string;
        rendered: boolean;
    };
    renderedQuestionChoices?: {
        dataSpecFieldName: string;
        choices: Choices1[];
        rendered: boolean;
    }[];
    validationWarnings?: string[];
    injectedTableRows?: Record<string, string>[];
    columns: ColumnType[],
    defaultRows: any[][];
    onChangeColumns: (c: Record<string, any[]>) => void;
    analysis: string;
    setAnalysis: (a: string) => void;
    reference: CommentaryOrGuidanceReference[], 
    setReference: (a: CommentaryOrGuidanceReference[]) => void;
    source: KeyTermSource[],
    setSource: (a: KeyTermSource[]) => void;
    readOnly: boolean;
}

const CurrentTableAnswerCard = ({
    commentary,
    dataTypeMap,
    tableLabel,
    tabelText,
    renderedQuestionChoices,
    validationWarnings,
    injectedTableRows,
    columns,
    defaultRows,
    onChangeColumns,
    analysis,
    setAnalysis,
    reference, 
    setReference,
    source,
    setSource,
    readOnly
}: CurrentTableAnswerCardProps) => {
    return (
        <Card variant="outlined" sx={{ height: "100%", overflowY: "auto" }}>
            <CardContent>
                <Typography variant="h6">
                    Current
                </Typography>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                {tabelText && (
                    <Box
                        alignItems="left"
                        width="100%"
                        display="flex"
                        marginBottom={1}
                    >
                        <Typography 
                            variant="subtitle2"
                            sx={{
                                "white-space": "pre-wrap"
                            }}
                        >
                            {tabelText.text}
                        </Typography>
                    </Box>
                )}
                <TableComponent
                    isSaving={false}
                    dataTypeMap={dataTypeMap}
                    renderedQuestionChoices={renderedQuestionChoices}
                    validationWarnings={validationWarnings}
                    injectedTableRows={injectedTableRows}
                    columns={columns}
                    defaultRows={defaultRows}
                    onChangeColumns={onChangeColumns}
                    hasToolbarDefaults={true}
                    readOnly={readOnly}
                />
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>Analysis</strong>
                    </Typography>
                    <AnalysisField
                        readOnly={readOnly}
                        defaultValue={analysis}
                        onUpdate={setAnalysis}
                    />
                </Box>
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>Guidance</strong>
                    </Typography>
                    <GuidanceList
                        readOnly={readOnly}
                        defaultValue={reference}
                        commentary={commentary}
                        onUpdate={setReference}
                    />
                </Box>
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>Sources</strong>
                    </Typography>
                    <SourcesList
                        readOnly={readOnly}
                        defaultValue={source}
                        onUpdate={setSource}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default CurrentTableAnswerCard;
