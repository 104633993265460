import { Card, CardContent, Divider, Grid, IconButton, List, ListItem, ListItemText, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import { ContentPasteGoRounded, Delete, LocationOn } from "@mui/icons-material";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import { createRef, useEffect, useRef, useState } from "react";

interface HighlightDetailsWidgetProps {
    keyTerm: KeyTerm;
    handleClose: () => void;
    setImportedText: (s: string) => void;
    importedTextChanges: boolean;
    setImportedTextChanges: (s: boolean) => void;
}

interface HighlightsTableProps {
    keyTerm: KeyTerm;
    handleClose: () => void;
    onTableRowClick: (row_id: number) => void;
    selectedRow: number;
    setImportedText: (s: string) => void;
    importedTextChanges: boolean;
    setImportedTextChanges: (s: boolean) => void;
}

interface HighlightsTextListProps {
    keyTerm: KeyTerm;
    selectedRow: number;
}


const HighlightsTable = ({ keyTerm, handleClose, onTableRowClick, selectedRow, setImportedText, importedTextChanges, setImportedTextChanges }: HighlightsTableProps) => {
    const {
        takerDocumentUpload,
        documentKeyTermsService,
    } = useKeyTermGroupState();
    const { mutateState } = useWidgetState();

    const documentIdToNameMap: Record<string, string> = {};
    if (takerDocumentUpload) {
        for (let i = 0; i < takerDocumentUpload.fileUpload.fileUploadItems.length; i++) {
            documentIdToNameMap[takerDocumentUpload.fileUpload.fileUploadItems[i].id] = takerDocumentUpload.fileUpload.fileUploadItems[i].label;
        }
    }

    // Sample data
    const rows = [];
    if (keyTerm.documentAnnotations) {
        for (let i = 0; i < keyTerm?.documentAnnotations?.length; i++) {
            rows.push({
                id: i + 1,
                highlightNumber: (i + 1).toString(),
                document: documentKeyTermsService.getTakerDocumentName(keyTerm.documentAnnotations[i].lexicalDocumentIdentifier),
                pageNumber: (keyTerm.documentAnnotations[i].page),
                fullAnnotationObject: keyTerm.documentAnnotations[i]
            });
        }
    }

    return (
        <TableContainer sx={{ height: "100%", overflow: 'auto' }}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Highlight Number</TableCell>
                        <TableCell>Document</TableCell>
                        <TableCell>Page Number</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: selectedRow === row.id ? '#f0f0f0' : 'inherit',
                            }}
                            onClick={() => {
                                onTableRowClick(row.id)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <TableCell component="th" scope="row">
                                {row.highlightNumber}
                            </TableCell>
                            <TableCell>{row.document}</TableCell>
                            <TableCell>{row.pageNumber + 1}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={"Import Tagged Text Into Summary"} arrow>
                                        <IconButton
                                            onClick={() => {
                                                let text = documentKeyTermsService.getTextForAnnotation(row.fullAnnotationObject.annotationId)
                                                setImportedText(text);
                                                setImportedTextChanges(!importedTextChanges);
                                            }}
                                        >
                                            <ContentPasteGoRounded />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Locate Text in Open Document"} arrow>
                                        <IconButton data-testid="locate-key-term"
                                            onClick={() => {
                                                mutateState<KeyTermsState>({
                                                    scrollToPage: row.pageNumber,
                                                    activeAnnotationIds: [...row.fullAnnotationObject.annotationId]
                                                })
                                                handleClose();
                                            }
                                            }
                                        >
                                            <LocationOn />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Delete Tagging"} arrow>
                                        <IconButton data-testid="delete-key-term"
                                            onClick={() => {
                                                documentKeyTermsService.removeDocumentAnnotation(keyTerm.termName, row.fullAnnotationObject)
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const HighlightsTextList = ({ keyTerm, selectedRow }: HighlightsTextListProps) => {
    const { documentKeyTermsService } = useKeyTermGroupState();

    const listItems = [];
    if (keyTerm.documentAnnotations) {
        for (let i = 0; i < keyTerm.documentAnnotations.length; i++) {
            let text = documentKeyTermsService.getTextForAnnotation(keyTerm.documentAnnotations[i].annotationId)
            listItems.push({ id: i, title: "#" + (i + 1).toString() + ", " + documentKeyTermsService.getTakerDocumentName(keyTerm.documentAnnotations[i].lexicalDocumentIdentifier), description: text })
        }
    }
    selectedRow = selectedRow - 1;
    const rowRefs = useRef<React.RefObject<HTMLLIElement>[]>([]);
    rowRefs.current = listItems.map((_, i) => rowRefs.current[i] ?? createRef());

    useEffect(() => {
        if (selectedRow && selectedRow >= 0 && selectedRow < listItems.length) {
            // Scroll the selectedRow into view
            rowRefs.current[selectedRow - 1].current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [selectedRow, listItems.length]);


    return (
        <List sx={{ width: '100%', height: "100%", overflow: 'auto' }}>
            {listItems.map((item) => (
                <ListItem
                    alignItems="flex-start"
                    key={item.id}
                    ref={rowRefs.current[item.id]}
                    sx={{
                        borderBottom: 1, // This adds a bottom border with default thickness
                        borderColor: 'divider', // Uses the theme's divider color for the border
                        '&:last-child': {
                            borderBottom: 0, // Removes the bottom border for the last item
                        },
                        backgroundColor: selectedRow === item.id ? '#f0f0f0' : 'inherit',
                    }}
                >
                    <ListItemText
                        primary={item.title}
                        secondary={
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {item.description}
                            </Typography>
                        }
                    />
                </ListItem>
            ))}
        </List>
    )
}

export const HighlightDetailsWidget = ({ keyTerm, handleClose, setImportedText, importedTextChanges, setImportedTextChanges }: HighlightDetailsWidgetProps) => {
    const [selectedRow, setSelectedRow] = useState<number>(-1);
    return (
        <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Typography variant="subtitle1">
                    Highlight Details
                </Typography>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                <Grid
                    container
                    spacing={1}
                    sx={{ height: "100%" }}
                >
                    <Grid item xs={6} sx={{ height: "100%" }}>
                        <HighlightsTable
                            keyTerm={keyTerm}
                            handleClose={handleClose}
                            onTableRowClick={setSelectedRow}
                            selectedRow={selectedRow}
                            setImportedText={setImportedText}
                            importedTextChanges={importedTextChanges}
                            setImportedTextChanges={setImportedTextChanges}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ height: "100%" }}>
                        <HighlightsTextList
                            keyTerm={keyTerm}
                            selectedRow={selectedRow}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}