import React from "react";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import {
    useAddTakerDocumentUploadDataMutation
} from "../../redux/services/takerData";
import { Box, Grid, Typography } from "@mui/material";
import { KeyTermsState } from "../../containers/WidgetWrapper/states";
import KeyTermsComponent from "./KeyTerms";
import { useKeyTermGroupState } from "../../containers/TakerDocumentState/KeyTermGroupState";
import DocumentAnnotator from "./DocumentAnnotator";
import { LoadingButton } from "@mui/lab";
import { DocumentKeyTerms } from "../../types/taker/documentkeyterms.generated";
import GeneratedSummaries from "./GeneratedSummaries";

interface KeyTermGroupProps {
    readOnly: boolean;
}

const KeyTermGroup = ({
    readOnly
}: KeyTermGroupProps) => {
    const [addTakerDocumentUploadData, addTakerDocumentUploadDataRes] = useAddTakerDocumentUploadDataMutation();
    const {
        taker,
        takerDocumentId,
        isTakerDocumentLoading
    } = useTakerState();
    const { getState } = useWidgetState();
    const { 
        takerDocumentUpload, 
        lastSavedTimestamp,
        isKeyTermsDataLoading
    } = useKeyTermGroupState();
    const panelViewMode = getState<KeyTermsState>().panelViewMode;

    if (!taker) {
        return null;
    }

    let showSummaries = panelViewMode === 0 || panelViewMode === 1;
    let showDocuments = panelViewMode === 0 || panelViewMode === 2;

    if (isKeyTermsDataLoading) {
        return (
            <span data-testid="taker-document-loading-keytermgroup">loading</span>
        );
    }

    if (takerDocumentUpload) {
        return (
            lastSavedTimestamp ? (
                <Grid
                    container
                    padding={1}
                    columnSpacing={1}
                    sx={{
                        height: "100%"
                    }}
                >
                    {showSummaries && (
                        <Grid
                            item
                            xs={showDocuments ? 6 : 12}
                            sx={{
                                height: '100%'
                            }}
                        >
                            <KeyTermsComponent isReadOnly={readOnly} />
                        </Grid>
                    )}
                    {showDocuments && (
                        <Grid
                            item
                            xs={showSummaries ? 6 : 12}
                            sx={{
                                height: '100%'
                            }}
                        >
                            <DocumentAnnotator readOnly={readOnly} />
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Box
                    sx={{ backgroundColor: "white" }}
                    border="1px solid rgb(195, 195, 195)"
                    borderRadius={1}
                    height="calc(100% - 5px)"
                    display="block"
                    alignItems="center"
                    alignContent="center"
                    textAlign="center"
                    padding={1}
                >
                    <LoadingButton
                        data-testid="start-key-terms"
                        loading={addTakerDocumentUploadDataRes.isLoading}
                        onClick={() => {
                            if (!readOnly) {
                                addTakerDocumentUploadData({
                                    takerId: taker?.id,
                                    takerDocumentId,
                                    takerDocumentUploadId: takerDocumentUpload.id,
                                    contentType: "KEY_TERMS",
                                    content: {
                                        lexicalDocuments: [],
                                        keyTerms: []
                                    } as DocumentKeyTerms
                                });
                            }
                        }}
                    >
                        Start Key Terms
                    </LoadingButton>
                </Box>
            )
        )
    } else {
        return (
            <Box
                sx={{ backgroundColor: "white" }}
                border="1px solid rgb(195, 195, 195)"
                borderRadius={1}
                height="calc(100% - 5px)"
                display="block"
                alignItems="center"
                alignContent="center"
                textAlign="center"
                padding={1}
            >
                <Typography
                    data-testid="create-group-text"
                >
                    Create or Select a Key Term Group to get started!
                </Typography>
            </Box>
        )
    }
}

export default function ({
    readOnly
}: {
    readOnly: boolean;
}) {
    return (
        <>
            <Box
                padding={1}
                height="100%"
            >
                <KeyTermGroup readOnly={readOnly}/>
            </Box>
            <GeneratedSummaries
                data-testid="generate-summaries"
                readOnly={readOnly}
            />
        </>
    );
};