import { useState, FormEvent } from "react";
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Alert,
} from "@mui/material/";
import logo from "./../../assets/images/Logo_08_Blue_Outlined_Black_Print.png";
import logoIcon from "./../../assets/images/logo_08_white_outlined.png";
import { signIn } from "../../redux/actionCreators/authActionCreators";
import { useDispatch } from "../../redux/reduxUtils/functions";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";


export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPasswordReset, setShowPasswordReset] = useState<boolean>(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetAlertState, setResetAlertState] = useState<null | "SENT_EMAIL" | "EMAIL_FAILURE">(null);

  const dispatch = useDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(signIn(email, password));
  };

  return (
    <>
      <Grid container sx={{ height: "100vh" }}>
        <Grid container item sm={4} component={Paper}>
          <Grid item>
            <Box component="img" src={logo} alt="" sx={{ width: "100%", paddingTop: "5%" }}></Box>
          </Grid>
        </Grid>
        <Grid container item sm={8} direction="column" alignItems="center" justifyContent="center">
          <Grid
            item
            component={Paper}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1.25rem",
              width: "45%",
              minWidth: "35ch",
            }}
          >
            <Box component="form" sx={{ width: "100%" }} onSubmit={handleSubmit}>
              <Box sx={{ paddingTop: "0.6rem" }}>
                <TextField
                  data-testid="email-input"
                  fullWidth
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  label="Email address"
                />
              </Box>
              <Box sx={{ paddingTop: "0.6rem" }}>
                <TextField
                  data-testid="password-input"
                  fullWidth
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  label="Password"
                />
              </Box>
              <Box sx={{ paddingTop: "0.6rem" }}>
                <Button
                  data-testid="sign-in-button"
                  startIcon={(
                    <Avatar
                      src={logoIcon}
                      alt="Praxi Logo"
                    />
                  )}
                  variant="contained"
                  fullWidth
                  type="submit">
                  <Typography variant="body1">Sign In With Email</Typography>
                </Button>
              </Box>
              <Box
                sx={{
                  paddingTop: "0.6rem",
                  textAlign: "center"
                }}>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setShowPasswordReset(true);
                    setResetEmail(email);
                  }}
                >
                  <Typography variant="subtitle2">
                    forgot your password?
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={showPasswordReset}
          onClose={() => setShowPasswordReset(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {"Reset Password"}
          </DialogTitle>
          <DialogContent>
            {resetAlertState === "SENT_EMAIL" && (
              <Alert>
                Email sent for password reset
              </Alert>
            )}
            {resetAlertState === "EMAIL_FAILURE" && (
              <Alert severity="error">
                There was a problem. It's likely there is no account associated with that email.
              </Alert>
            )}
            <Box sx={{ width: "100%" }}>
              <Box sx={{ paddingTop: "1rem" }}>
                <TextField
                  fullWidth
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.currentTarget.value)}
                  label="Email address"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                const auth = getAuth();
                sendPasswordResetEmail(auth, resetEmail)
                  .then(() => {
                    setResetAlertState("SENT_EMAIL");
                  })
                  .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    //console.log(errorCode, errorMessage);
                    setResetAlertState("EMAIL_FAILURE");
                  });
              }}
              autoFocus
              variant="contained"
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                setShowPasswordReset(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
