import { mergeRegister } from '@lexical/utils';
import {
    COMMAND_PRIORITY_LOW,
    LexicalEditor,
    CLICK_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { Edit, Sync } from '@mui/icons-material';
import { UPDATE_REPORT_SECTION_COMMAND, getDOMRangeRect, setFloatingElemPosition } from '../common';
import { ReportSectionUpdatePayload } from '../../nodes/ReportSectionNode';

interface FloatingReportSectionToolbarProps {
    editor: LexicalEditor;
    anchorElem: HTMLElement;
    selectedReportSectionNode: ReportSectionUpdatePayload;
}

export default function FloatingReportSectionToolbar({
    editor,
    anchorElem,
    selectedReportSectionNode
}: FloatingReportSectionToolbarProps): JSX.Element {
    const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

    const editReportSection = useCallback(() => {
        editor.dispatchCommand(UPDATE_REPORT_SECTION_COMMAND, {
            basicEdit: true
        });
    }, [editor]);

    const resyncReportSection = useCallback(() => {
        editor.dispatchCommand(UPDATE_REPORT_SECTION_COMMAND, {
            resync: true
        });
    }, [editor]);

    function mouseMoveListener(e: MouseEvent) {
        if (
            popupCharStylesEditorRef?.current &&
            (e.buttons === 1 || e.buttons === 3)
        ) {
            if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
                const x = e.clientX;
                const y = e.clientY;
                const elementUnderMouse = document.elementFromPoint(x, y);

                if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
                    // Mouse is not over the target element => not a normal click, but probably a drag
                    popupCharStylesEditorRef.current.style.pointerEvents = 'none';
                }
            }
        }
    }
    function mouseUpListener(e: MouseEvent) {
        if (popupCharStylesEditorRef?.current) {
            if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
                popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
            }
        }
    }

    useEffect(() => {
        if (popupCharStylesEditorRef?.current) {
            document.addEventListener('mousemove', mouseMoveListener);
            document.addEventListener('mouseup', mouseUpListener);
            return () => {
                document.removeEventListener('mousemove', mouseMoveListener);
                document.removeEventListener('mouseup', mouseUpListener);
            };
        }
    }, [popupCharStylesEditorRef]);

    const updateFloatingReportSectionToolbar = useCallback(() => {
        const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
        const nativeSelection = window.getSelection();
        if (popupCharStylesEditorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            nativeSelection !== null &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
            setFloatingElemPosition(
                rangeRect,
                popupCharStylesEditorElem,
                anchorElem
            );
        }
    }, [editor, anchorElem]);

    useEffect(() => {
        const scrollerElem = anchorElem.parentElement;
        const update = () => {
            editor.getEditorState().read(() => {
                updateFloatingReportSectionToolbar();
            });
        };

        window.addEventListener('resize', update);
        if (scrollerElem) {
            scrollerElem.addEventListener('scroll', update);
        }

        return () => {
            window.removeEventListener('resize', update);
            if (scrollerElem) {
                scrollerElem.removeEventListener('scroll', update);
            }
        };
    }, [editor, updateFloatingReportSectionToolbar, anchorElem]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateFloatingReportSectionToolbar();
        });
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateFloatingReportSectionToolbar();
                });
            }),
            editor.registerCommand(
                CLICK_COMMAND,
                () => {
                    updateFloatingReportSectionToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateFloatingReportSectionToolbar]);

    return (
        <div ref={popupCharStylesEditorRef} className="floating-report-section-toolbar">
            <ButtonGroup
                size="small"
                variant="text"
                color="inherit"
                aria-label="Floating Report Section Update Button Group"
            >
                <Button onClick={editReportSection}>
                    <Edit />
                </Button>
                {selectedReportSectionNode.outOfSync && (
                    <Button onClick={resyncReportSection}>
                        <Sync />
                    </Button>
                )}
            </ButtonGroup>
        </div>
    );
}