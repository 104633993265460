import { TextField } from "@mui/material/";
import { useMemo } from "react";


interface StringQuestionProps {
    uniqueId: string;
    defaultValue: any;
    onChangeValue: (a: any) => void;
    readOnly: boolean;
}

const StringQuestion = ({
    uniqueId,
    defaultValue,
    onChangeValue,
    readOnly
}: StringQuestionProps) => {
    const error = useMemo(
        () => !defaultValue || defaultValue === "",
        [defaultValue]
    );

    const helperText = useMemo(
        () => error ? "Input is required" : null,
        [error]
    );

    return (
        <TextField
            id={uniqueId}
            fullWidth
            multiline={true}
            variant="outlined"
            InputProps={{
                readOnly,
            }}
            FormHelperTextProps={{
                sx: { marginLeft: 0 }
            }}
            error={error}
            helperText={helperText}
            value={defaultValue}
            onChange={(event) => {
                onChangeValue(event.target.value);
            }}
        />
    );
}

export default StringQuestion;