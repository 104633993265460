import RadioQuestion, { ChoiceType } from "./RadioQuestion";
import StringQuestion from "./StringQuestion";
import DateQuestion from "./DateQuestion";
import IntegerQuestion from "./IntegerQuestion";
import MultiSelectQuestion from "./MultiSelectQuestion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { StaticChoices, DisplayedVariableReference, DisplayedVariableReferences } from "../../../types/builderv2.generated";
import { GraphFulfillmentState } from "../../../types/taker/fulfillmentstate.generated";

export type PrimitiveType = "string" | "integer" | "date" | "boolean" | "double";

const QUESTION_VALUE_UPDATE_DELAY_MS = 200;

interface QuestionAnswerProps {
    uniqueId: string;
    graphFulfillmentState: GraphFulfillmentState;
    nodeId: string;
    dataType: PrimitiveType;
    questionType: "radio" | "multiselect" | "input";
    choices?: StaticChoices | DisplayedVariableReference | DisplayedVariableReferences;
    defaultValue: any;
    onChangeValue: (a: any) => void;
    readOnly?: boolean;
}

const QuestionAnswer = ({
    uniqueId,
    graphFulfillmentState,
    nodeId,
    dataType,
    questionType,
    choices,
    defaultValue,
    onChangeValue,
    readOnly = false
}: QuestionAnswerProps) => {
    const delayedAnswerUpdate = useRef<any>();
    const delayedAnswerUpdated = useRef<boolean>(true);

    const [localAnswer, setLocalAnswer] = useState<string>(defaultValue);

    const questionChoices: null | ChoiceType[] = useMemo(() => {
        if (!choices || !graphFulfillmentState.renderedQuestionChoices) {
            return null;
        }

        for (const renderedQuestionChoice of graphFulfillmentState.renderedQuestionChoices) {
            if (renderedQuestionChoice.nodeId === nodeId && renderedQuestionChoice.rendered) {
                return renderedQuestionChoice.choices.map(
                    c => ({
                        label: c.label,
                        value: c.value
                    } as ChoiceType)
                );
            }
        }
        if (choices) {
            return (choices as StaticChoices).map(
                c => ({
                    label: c.label,
                    value: c.value
                } as ChoiceType)
            );
        }
        return null;
    }, [choices, graphFulfillmentState]);

    useEffect(() => {
        if (!(questionType === "input" && dataType === "string") || delayedAnswerUpdated.current) {
            return;
        }

        if (delayedAnswerUpdate.current) {
            clearTimeout(delayedAnswerUpdate.current);
        }

        delayedAnswerUpdate.current = setTimeout(function () {
            onChangeValue(localAnswer)
        }, QUESTION_VALUE_UPDATE_DELAY_MS);
    }, [localAnswer]);

    useEffect(() => {
        if (!(questionType === "input" && dataType === "string")) {
            return;
        }

        if (delayedAnswerUpdate.current) {
            clearTimeout(delayedAnswerUpdate.current);
        }
        setLocalAnswer(defaultValue);
    }, [defaultValue]);

    return (
        <>
            {(questionType === "radio" && !!questionChoices) && (
                <RadioQuestion
                    uniqueId={uniqueId}
                    choices={questionChoices}
                    defaultValue={defaultValue}
                    onChangeValue={onChangeValue}
                    onResetField={() => onChangeValue(undefined)}
                    readOnly={readOnly}
                />
            )}
            {(questionType === "multiselect" && dataType === "string" && !!questionChoices) && (
                <MultiSelectQuestion
                    uniqueId={uniqueId}
                    choices={questionChoices}
                    defaultValue={defaultValue}
                    onChangeValue={onChangeValue}
                    onResetField={() => onChangeValue(undefined)}
                    readOnly={readOnly}
                />
            )}
            {(questionType === "input" && dataType === "string") && (
                <StringQuestion
                    uniqueId={uniqueId}
                    defaultValue={localAnswer}
                    onChangeValue={(a) => {
                        delayedAnswerUpdated.current = false;
                        setLocalAnswer(a);
                    }}
                    readOnly={readOnly}
                />
            )}
            {(questionType === "input" && (dataType === "integer" || dataType === "double")) && (
                <IntegerQuestion
                    uniqueId={uniqueId}
                    defaultValue={defaultValue}
                    onChangeValue={onChangeValue}
                    readOnly={readOnly}
                />
            )}
            {(questionType === "input" && dataType === "date") && (
                <DateQuestion
                    uniqueId={uniqueId}
                    defaultValue={defaultValue}
                    onChangeValue={onChangeValue}
                    readOnly={readOnly}
                />
            )}
        </>
    );
};

export default QuestionAnswer;