import { LexicalEditor } from 'lexical';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLayoutEffect } from 'react';
import { Autocomplete, Box, Button, FormControl, FormHelperText, TextField } from '@mui/material';
import { useWidgetState } from '../../../../containers/WidgetWrapper/wrapper';
import { KeyTermsState } from '../../../../containers/WidgetWrapper/states';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';

import './index.css';

export function AddAnnotationInputBox({
  editor,
  cancelAddAnnotation,
  submitAddAnnotation,
  originalRangeRect,
  originalSelectionRects
}: {
  cancelAddAnnotation: () => void;
  editor: LexicalEditor;
  submitAddAnnotation: (
    keyTermNames: string[],
    lexicalDocumentIdentifier: string
  ) => void;
  originalRangeRect?: DOMRect;
  originalSelectionRects?: ClientRect[];  
}) {
  const [keyTerms, setKeyTerms] = useState<string[]>([]);
  const { documentKeyTermsService } = useKeyTermGroupState();
  const { getState } = useWidgetState();

  const boxRef = useRef<HTMLDivElement>(null);
  const selectionState = useMemo(
    () => ({
      container: document.createElement('div'),
      elements: [],
    }),
    []
  );

  const targetFileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;

  const currentKeyTermNames = useMemo(() =>
    documentKeyTermsService.keyTerms?.map(kt => kt.termName),
    [documentKeyTermsService.keyTerms]
  );

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    // If the user clicks outside the box, cancel the annotation. We exclude the MuiAutocomplete-option class because it does not render as a child of the boxRef.
    if (boxRef.current && !boxRef.current.contains(event.target as Node) &&  !(event.target as Element).className.includes('MuiAutocomplete-option')) {
      cancelAddAnnotation();
    }
  };

  const updateLocation = useCallback(() => {
    if (originalRangeRect && originalSelectionRects) {
      const boxElem = boxRef.current;
      if (boxElem !== null) {
        const { left, bottom, width } = originalRangeRect;
        let correctedLeft = left + (width / 2);
        if (correctedLeft < 10) {
          correctedLeft = 10;
        }
        boxElem.style.left = `${correctedLeft}px`;
        boxElem.style.top = `${bottom +
          20 +
          (window.pageYOffset || document.documentElement.scrollTop)
          }px`;
        const selectionRectsLength = originalSelectionRects.length;
        const { container } = selectionState;
        const elements: Array<HTMLSpanElement> = selectionState.elements;
        const elementsLength = elements.length;

        for (let i = 0; i < selectionRectsLength; i++) {
          const selectionRect = originalSelectionRects[i];
          let elem: HTMLSpanElement = elements[i];
          if (elem === undefined) {
            elem = document.createElement('span');
            elements[i] = elem;
            container.appendChild(elem);
          }
          const color = '255, 212, 0';
          const style = `position:absolute;top:${selectionRect.top +
            (window.pageYOffset || document.documentElement.scrollTop)
            }px;left:${selectionRect.left}px;height:${selectionRect.height
            }px;width:${selectionRect.width
            }px;background-color:rgba(${color}, 0.3);pointer-events:none;z-index:5;`;
          elem.style.cssText = style;
        }
        for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
          const elem = elements[i];
          container.removeChild(elem);
          elements.pop();
        }
      }
    }
  }, [editor, selectionState, originalRangeRect, originalSelectionRects]);

  useLayoutEffect(() => {
    updateLocation();
    const container = selectionState.container;
    const body = document.body;
    if (body !== null) {
      body.appendChild(container);
      return () => {
        body.removeChild(container);
      };
    }
  }, [selectionState.container, updateLocation]);

  useEffect(() => {
    window.addEventListener('resize', updateLocation);
    return () => {
      window.removeEventListener('resize', updateLocation);
    };
  }, [updateLocation]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const clickSave = () => {
    if (!!targetFileUploadItemId) {
      submitAddAnnotation(
        keyTerms,
        targetFileUploadItemId
      );
    }
  };

  return (
    <div className="CommentPlugin_CommentInputBox" ref={boxRef}>
      <FormControl sx={{ m: 1, width: "95%" }}>
        <Autocomplete
          size='small'
          multiple
          data-testid="key-terms-autocomplete"
          options={currentKeyTermNames || []}
          getOptionLabel={(option) => option}
          id="key-terms-input"
          value={keyTerms}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              let newKeyTerms = [...keyTerms];
              newKeyTerms.push(newValue);
              setKeyTerms(newKeyTerms);
            } else {
              setKeyTerms(newValue);
            }
          }}
          freeSolo
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Key Terms"
              placeholder="Key Terms"
              data-testid="key-terms-input"
            />
          )}
        />
        <FormHelperText>
          Click enter to add a new key term or select 1 or more previous terms.
        </FormHelperText>
      </FormControl>
      <Box m={1}>
        <Button
          data-testid="save-comment-button"
          disabled={(keyTerms.length === 0)}
          size='small'
          color="primary"
          onClick={clickSave}
        >
          Save
        </Button>
        <Button
          data-testid="cancel-comment-button"
          size='small'
          onClick={cancelAddAnnotation}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );
}
