import React, { useContext } from "react";
import { ValidWidgetStates } from "./states";

export interface AdditionalAction {
    display: string;
    onClick?: () => void;
    disabled?: boolean;
}

interface WidgetStateHookData {
    getState: <S>() => S;
    mutateState: <S>(s: Partial<S>) => void;
}

const Context = React.createContext({});

export function useWidgetState(): WidgetStateHookData {
    return useContext(Context) as WidgetStateHookData;
}

interface WidgetWrapperProps {
    widgetState: ValidWidgetStates | undefined;
    setPartialWidgetState: (partialState: Partial<ValidWidgetStates>) => void;
    children: any;
}

const WidgetWrapper = ({
    setPartialWidgetState,
    widgetState,
    children
}: WidgetWrapperProps) => {
    return (
        <Context.Provider
            value={{
                getState: () => widgetState || {},
                mutateState: (s: Partial<ValidWidgetStates>) => {
                    setPartialWidgetState(s);
                },
            }}
        >
            {children}
        </Context.Provider>
    );
}

export default WidgetWrapper;
