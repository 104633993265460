import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import ReactFlow, {
  addEdge,
  Controls,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  MiniMap,
} from 'reactflow';
import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import { useReadOnlyBuilderData } from '../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData';
import { MarkerType, useReactFlow } from 'reactflow';
import { useWidgetState } from '../../../containers/WidgetWrapper/wrapper';
import { AnalysisState } from '../../../containers/WidgetWrapper/states';
import IterationChooserDialog from './IterationChooserDialog';
import { Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import { GraphFulfillmentNode } from './common';
import { GraphFulfillmentState } from '../../../types/taker/fulfillmentstate.generated';
import { GraphFulfillment, Module } from '../../../types/builderv2.generated';
import { Navigation } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { NodeDetails } from '../../Builder/SpecBuilder/fullGps';

import 'reactflow/dist/style.css';
import './overview.css';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const NODE_WIDTH = 275;
const NODE_HEIGHT = 150;
const initBgColor = '#1976d217';
const defaultMiniMapNodeColor = '#1976d2';
const miniMapStartNodeColor = '#000';



export default function Diagram() {
  const { getState, mutateState } = useWidgetState();
  const { builderHolder, positionedModuleList } = useReadOnlyBuilderData();
  const {
    fulfillmentStateHolder,
    questionnareDataService,
    activeTargetTakerState,
    getVariableValue
  } = useTakerState();

  const [centeredNodeId, setCenteredNodeId] = useState<string>()
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const { fitView } = useReactFlow();

  const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), []);

  const initializedTopModule = useRef(false);
  const lockedDiagram = useRef(true);

  const controlledByQuestionnaire = getState<AnalysisState>().controlledByQuestionnaire;
  const selectedIteration = getState<AnalysisState>().selectedIteration;
  const selectedMid = getState<AnalysisState>().selectedMid;
  const selectedQuestionnaireElement = getState<AnalysisState>().selectedElement;
  const questionnaireCollapsedState = getState<AnalysisState>().collapsedState;

  const isEdgeActive = (mid: string, edgeId: string, iteration: number | null) => {
    let moduleIds = mid.split('_');
    if (fulfillmentStateHolder && moduleIds) {
      let module = builderHolder.getModule(moduleIds);
      let state = fulfillmentStateHolder.getSubstate(moduleIds, iteration);
      if (state && module && module.uiFulfillment?.fulfillmentType === "graph") {
        let graphState = state as GraphFulfillmentState;
        return graphState.activeEdgePath.includes(edgeId);
      }
    }
    return false;
  };

  const isNodeActive = (mid: string, nodeId: string, iteration: number | null) => {
    let moduleIds = mid.split('_');
    if (fulfillmentStateHolder && moduleIds) {
      let module = builderHolder.getModule(moduleIds);
      let state = fulfillmentStateHolder.getSubstate(moduleIds, iteration);
      if (state && module && module.uiFulfillment?.fulfillmentType === "graph") {
        let graphState = state as GraphFulfillmentState;
        return graphState.activeNodePath.includes(nodeId);
      }
    }
    return false;
  };

  const nodeHasAnswer = (mid: string, node: GraphFulfillmentNode, iteration: number | null) => {
    if (node.nodeType === "question") {
      let moduleIds = mid.split('_');
      return !!questionnareDataService.getAnswer(
        moduleIds,
        node.dataSpecFieldName,
        iteration
      );
    } else if (node.nodeType === "module") {
      let moduleIds = mid.split('_');
      moduleIds.push(node.moduleId);
      if (fulfillmentStateHolder) {
        return fulfillmentStateHolder.isSubstateFulfilled(moduleIds);
      }
    }
    return false;
  };

  const getActualItems = (moduleIds: string[]) => activeTargetTakerState && activeTargetTakerState.count(moduleIds);

  const getExpectedItems = (modulePrefixes: string[], fulfillment: GraphFulfillment) => {
    if (fulfillment.completionContexts && activeTargetTakerState) {
      for (const cc of fulfillment.completionContexts) {
        if (cc.completionContextType === "number-iterations") {
          let numIterationsValue = getVariableValue(modulePrefixes, cc.variableReference);
          if (typeof numIterationsValue === "number") {
            return numIterationsValue;
          } else {
            return numIterationsValue ? parseInt(numIterationsValue) : 0;
          }
        } else if (cc.completionContextType === "variable-injected-loop-context") {
          let table = getVariableValue(modulePrefixes, cc.variableReference);
          if (!!table) {
            return table.data.length;
          }
        }
      }
    }
  };

  const doesRequireAttention = (mid: string, node: GraphFulfillmentNode) => {
    if (node.nodeType === "module") {
      // Format module ids
      let modulePrefixes = mid.split('_');
      let moduleIds = [...modulePrefixes];
      moduleIds.push(node.moduleId);

      // Fetch module to get the fulfillment configuration
      let praxiModule = builderHolder.getModule(moduleIds);

      if (praxiModule && praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table") {
        let expectedItems = getExpectedItems(modulePrefixes, praxiModule.uiFulfillment);
        let actualItems = getActualItems(moduleIds);
        return (
          expectedItems !== undefined &&
          actualItems !== undefined &&
          expectedItems !== actualItems
        );
      }
    }
  };

  const countNodesForModule: (m: Module) => number = (m: Module) => {
    let total = 0;
    if (m.uiFulfillment?.fulfillmentType === "mapping-question"
      || m.uiFulfillment?.fulfillmentType === "percentage-mapping-question"
      || m.uiFulfillment?.fulfillmentType === "table"
      || (m.uiFulfillment?.fulfillmentType === "graph" && m.dataSpec?.dataSpecType === "basic-table")) {
      total += 1;
    } else if (m.uiFulfillment?.fulfillmentType === "graph") {
      if (m.uiFulfillment.nodes) {
        total += m.uiFulfillment.nodes.length;
      }
    }

    if (m.nestedModules) {
      for (const nm of m.nestedModules) {
        total += countNodesForModule(nm);
      }
    }
    return total;
  }


  /*
  Listeners to adjust the Diagram visual state to incoming props or default props.
  */
  useEffect(() => {
    const topModule = builderHolder.builder.topModule;
    if (topModule && !selectedMid && !initializedTopModule.current) {
      mutateState<AnalysisState>({
        selectedMid: topModule.id
      });
      initializedTopModule.current = true;
    }
  }, []);

  useEffect(() => {
    if (controlledByQuestionnaire) {
      let shouldUpdate = (selectedQuestionnaireElement && selectedQuestionnaireElement.moduleIds) &&
        (
          selectedIteration !== selectedQuestionnaireElement.iteration ||
          selectedMid !== selectedQuestionnaireElement.moduleIds.join('_')
        );

      if (shouldUpdate) {
        mutateState<AnalysisState>({
          selectedMid: selectedQuestionnaireElement.moduleIds.join('_'),
          selectedIteration: selectedQuestionnaireElement.iteration
        });
      }

      if (selectedQuestionnaireElement && selectedQuestionnaireElement.nodeId && (centeredNodeId !== selectedQuestionnaireElement.nodeId)) {
        lockedDiagram.current = false;
        setTimeout(() => {
          setCenteredNodeId(selectedQuestionnaireElement.nodeId);
        }, 100);
      }
    }
  }, [selectedQuestionnaireElement, controlledByQuestionnaire, questionnaireCollapsedState])

  useEffect(() => {
    if (centeredNodeId && !lockedDiagram.current && selectedMid) {
      const selectedMidReformated = selectedMid.split('_').join('-');
      let selectedNodes = nodes.filter(n => n.id === `${selectedMidReformated}-${selectedQuestionnaireElement.nodeId}`);
      if (selectedNodes.length > 0) {
        window.requestAnimationFrame(
          () => fitView({
            minZoom: 0.7,
            maxZoom: 0.7,
            nodes: [selectedNodes[0]],
            duration: 500
          })
        );
        lockedDiagram.current = true;
      }
    }
  }, [centeredNodeId, selectedMid])

  useEffect(() => {
    if (!selectedMid) {
      return;
    }

    const moduleIdsSplit = selectedMid.split('_');
    const module = builderHolder.getModule(moduleIdsSplit);
    if (!module) {
      return;
    }

    let targetModuleData = positionedModuleList.find(pm => pm.module.id == module.id);

    if (!targetModuleData) {
      targetModuleData = positionedModuleList[0];
    }

    let targetModule = targetModuleData.module;

    const newEdges: Edge[] = [];
    const newNodes: Node[] = [];

    const allNodes = new Set();
    const allParents = new Set();
    const allChildren = new Set();

    const activeEdges: Edge[] = [];
    const inactiveEdges: Edge[] = [];
    const edgesFromSource: Record<string, number> = {};
    const edgesFromSourceCounter: Record<string, number> = {};

    const addEdgesForModule = (m: Module, path: string[]) => {
      let pathId = [...path, m.id].join('-')
      let altPathId = [...path, m.id].join('_')
      if (m.uiFulfillment?.fulfillmentType === "graph") {
        if (m.uiFulfillment.edges) {
          for (const e of m.uiFulfillment.edges) {
            if (edgesFromSource[e.source] === undefined) {
              edgesFromSource[e.source] = 0;
              edgesFromSourceCounter[e.source] = 0;
            }
            edgesFromSource[e.source]++;

            allNodes.add(`${pathId}-${e.source}`);
            allNodes.add(`${pathId}-${e.target}`);
            allParents.add(`${pathId}-${e.source}`);
            allChildren.add(`${pathId}-${e.target}`);

            const isActive = isEdgeActive(altPathId, e.id, selectedIteration);
            (isActive ? activeEdges : inactiveEdges).push({
              id: `${pathId}-${e.id}`,
              source: `${pathId}-${e.source}`,
              target: `${pathId}-${e.target}`,
              type: "custom",
              style: {
                strokeWidth: 4,
                stroke: (isActive ? blue[400] : undefined)
              },
              markerEnd: {
                type: MarkerType.Arrow,
                strokeWidth: 2,
                width: 10,
                height: 10,
                color: (isActive ? blue[400] : undefined)
              },
              label: e.label,
              data: {
                isActive
              }
            });
            edgesFromSourceCounter[e.source]++;
          }
        }
      }
      if (m.nestedModules) {
        for (const nm of m.nestedModules) {
          if (nm.uiFulfillment?.fulfillmentType === "graph" && nm.dataSpec?.dataSpecType === "basic") {
            addEdgesForModule(nm, [...path, m.id]);
          }
        }
      }
    };

    addEdgesForModule(targetModule, targetModuleData.path);

    newEdges.push(...inactiveEdges);
    newEdges.push(...activeEdges);

    const onlyParents = new Set(allParents);
    const onlyChildren = new Set(allChildren);
    allChildren.forEach((c) => onlyParents.delete(c));
    allParents.forEach((c) => onlyChildren.delete(c));

    const addNodesForModule = (m: Module, path: string[]) => {
      let pathId = [...path, m.id].join('-');
      let altPathId = [...path, m.id].join('_')
      if (m.uiFulfillment?.fulfillmentType === "graph") {
        if (m.uiFulfillment.nodes) {
          for (const n of m.uiFulfillment.nodes) {
            if (n.nodeType === "question") {
              newNodes.push({
                id: `${pathId}-${n.id}`,
                type: 'custom',
                draggable: false,
                data: {
                  node: n,
                  isStart: onlyParents.has(n.id),
                  isEnd: onlyChildren.has(n.id),
                  mid: altPathId,
                  iteration: selectedIteration,
                  isActive: isNodeActive(altPathId, n.id, selectedIteration),
                  hasAnswer: nodeHasAnswer(altPathId, n, selectedIteration),
                  requiresAttention: doesRequireAttention(altPathId, n)
                },
                style: {},
                parentId: (targetModule.id === m.id) ? undefined : pathId
              } as Node);
            } else if (n.nodeType === "module") {
              // need to either push a group or a plain node
              // module == graph (but not group) -> push group
              // module == other -> push node
              const praxiModule = builderHolder.getModule([...path, m.id, n.moduleId]);
              if (!praxiModule) {
                throw Error(`module with moduleId=${[...path, m.id, n.moduleId]} doesn't exist`)
              } else if (praxiModule.uiFulfillment?.fulfillmentType === "mapping-question"
                || praxiModule.uiFulfillment?.fulfillmentType === "percentage-mapping-question"
                || praxiModule.uiFulfillment?.fulfillmentType === "table"
                || (praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table")) {
                newNodes.push({
                  id: `${pathId}-${n.id}`,
                  type: 'custom',
                  draggable: false,
                  data: {
                    node: n,
                    isStart: onlyParents.has(n.id),
                    isEnd: onlyChildren.has(n.id),
                    mid: altPathId,
                    iteration: selectedIteration,
                    isActive: isNodeActive(altPathId, n.id, selectedIteration),
                    hasAnswer: nodeHasAnswer(altPathId, n, selectedIteration),
                    requiresAttention: doesRequireAttention(altPathId, n)
                  },
                  style: {},
                  parentId: (targetModule.id === m.id) ? undefined : pathId,
                } as Node);
              } else if (praxiModule.uiFulfillment?.fulfillmentType === "graph") {
                newNodes.push({
                  id: `${pathId}-${n.id}`,
                  draggable: false,
                  data: {
                    label: praxiModule?.displayName
                  },
                  className: 'light',
                  style: {
                    fontSize: "30px",
                    backgroundColor: 'rgba(0, 0, 255, 0.05)'
                  },
                  parentId: (targetModule.id === m.id) ? undefined : pathId
                } as Node);
              }
            } else if (n.nodeType === "end") {
              const hasInfo = n.label || n.statement
              newNodes.push({
                id: `${pathId}-${n.id}`,
                type: 'custom',
                draggable: false,
                data: {
                  node: n,
                  isStart: onlyParents.has(n.id),
                  isEnd: onlyChildren.has(n.id),
                  mid: altPathId,
                  iteration: selectedIteration,
                  isActive: isNodeActive(altPathId, n.id, selectedIteration),
                  hasAnswer: nodeHasAnswer(altPathId, n, selectedIteration),
                },
                style: {
                  width: hasInfo ? NODE_WIDTH : 50,
                  height: hasInfo ? NODE_HEIGHT : 50
                },
                width: hasInfo ? NODE_WIDTH : 50,
                height: hasInfo ? NODE_HEIGHT : 50,
                parentNode: (targetModule.id === m.id) ? undefined : pathId
              } as Node);
            }
          }
        }
      }
      if (m.nestedModules) {
        for (const nm of m.nestedModules) {
          if (nm.uiFulfillment?.fulfillmentType === "graph" && nm.dataSpec?.dataSpecType === "basic") {
            addNodesForModule(nm, [...path, m.id]);
          }
        }
      }
    };

    addNodesForModule(targetModule, targetModuleData.path);

    let exactPositionsAndSizesByNodeId: Record<string, NodeDetails> = builderHolder.builder.positioningMetadata?.exactPositionsAndSizesByNodeId as any;
    for (const n of newNodes) {
      if (exactPositionsAndSizesByNodeId) {
        let pos = exactPositionsAndSizesByNodeId[n.id];
        if (pos) {
          n.position = {
            x: pos.xPos,
            y: pos.yPos
          };

          if (pos.absolute) {
              n.positionAbsolute = {
                x: pos.absolute.x,
                  y: pos.absolute.y
              }
          } 

          if (n.style && !n.style.width && !n.style.height && pos.height && pos.width) {
            n.style.width = pos.width;
            n.style.height = pos.height;
            n.width = pos.width;
            n.height = pos.height;
          }
        } else {
          console.warn(`cannot find positioning for ${n.id}`)
        }
      }
    }

    setNodes(newNodes);
    setEdges(newEdges);
  }, [
    selectedMid,
    selectedIteration,
    fulfillmentStateHolder,
    questionnareDataService,
    builderHolder,
    selectedQuestionnaireElement
  ]);

  const mapToolbar = useMemo(() => {
    if (!selectedMid) {
      return null;
    }

    let moduleIds = selectedMid.split('_');
    if (moduleIds.length > 0) {
      let cumulativeMids = [];
      let breadcrumbs = [];
      for (let mid of moduleIds) {
        cumulativeMids.push(mid)

        const praxiModule = builderHolder.getModule(cumulativeMids);
        breadcrumbs.push(!!praxiModule ? praxiModule.displayName : mid);
      }

      let otherBreadcrumbs = [];
      if (selectedIteration !== null) {
        otherBreadcrumbs.push(`iteration ${selectedIteration + 1}`);
      }

      return (
        <Grid
          sx={{
            width: "100%",
            position: 'absolute',
            zIndex: 1,
            marginTop: 1,
            marginLeft: 1
          }}
          container
          alignItems="center"
          columnSpacing={1}
        >
          <Grid item>
            <IconButton
              data-testid="toggle-controlled-by-questionnaire"
              color={controlledByQuestionnaire ? "info" : "default"}
              size="small"
              sx={{
                border: (controlledByQuestionnaire ? `1px solid ${blue[400]}` : 'none')
              }}
              onClick={() => {
                mutateState<AnalysisState>({
                  controlledByQuestionnaire: !controlledByQuestionnaire
                });
              }}
            >
              <Navigation />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{
              paddingLeft: 2,
            }}
          >
            <Breadcrumbs
              sx={{
                paddingRight: 1,
                paddingLeft: 1,
                backgroundColor: "rgba(153, 153, 153, 0.15)",
                borderRadius: 1
              }}
            >
              {breadcrumbs.map((bc, i) => (
                i === breadcrumbs.length - 1 ? (
                  <Typography color="text.primary">{bc}</Typography>
                ) : (
                  <Link
                    component="button"
                    disabled={controlledByQuestionnaire}
                    color="inherit"
                    onClick={() => {
                      let existingModuleIds = [...moduleIds];
                      existingModuleIds.splice(i + 1, (breadcrumbs.length - (i + 1)));
                      mutateState<AnalysisState>({
                        selectedMid: existingModuleIds.join('_'),
                        selectedIteration: null
                      });
                    }}
                  >
                    {bc}
                  </Link>
                )
              ))}
              {otherBreadcrumbs.map((bc) => (
                <Typography color="text.primary">{bc}</Typography>
              ))}
            </Breadcrumbs>
          </Grid>
        </Grid>
      );
    }
    return null;
  }, [builderHolder, selectedMid, selectedIteration, controlledByQuestionnaire]);

  useEffect(() => {
    if (!controlledByQuestionnaire) {
      window.requestAnimationFrame(
        () => fitView({ minZoom: 0.6, maxZoom: 0.6, nodes: [nodes[0]] })
      );
    }
  }, [selectedMid]);

  return (
    <>
      {mapToolbar}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        style={{ background: initBgColor }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        minZoom={0.025}
        maxZoom={1.25}
      >
        {!controlledByQuestionnaire && (
          <MiniMap
            nodeStrokeColor={(n: Node) => {
              return defaultMiniMapNodeColor;
            }}
            nodeColor={(n: Node) => {
              if (n.data.isStart) {
                return miniMapStartNodeColor;
              }
              return defaultMiniMapNodeColor;
            }}
            zoomable
            pannable
          />
        )}
        <Controls
          showFitView={false}
          showInteractive={false}
        />
      </ReactFlow>
      {!controlledByQuestionnaire && (
        <IterationChooserDialog />
      )}
    </>
  );
};
