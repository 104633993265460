import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import DefaultLoadingScreen from "../loading/defaultLoadingScreen";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { pageOption } from "./../../data/pages";
import { UserScopedAppDataContainer } from "../../containers/UserScopedAppData/UserScopedAppData";

interface RoleGatedRoutesProps {
  roles?: Array<string>;
}

const GatedRoutes = ({ roles }: RoleGatedRoutesProps) => {
  const {
    authenticated,
    user,
    loaded
  } = useSelector((state: RootReducerType) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated && loaded) {
      navigate("/");
    }
  }, [authenticated, loaded]);

  if (!user || !loaded) {
    return <DefaultLoadingScreen />
  }

  const shouldAllow = authenticated 
    && (!roles  || user.roles.some((r: string) => roles.includes(r)));
  return shouldAllow ? (
    <UserScopedAppDataContainer
    >
      <Outlet />
    </UserScopedAppDataContainer>
  ) : (
    <Navigate to={pageOption.SIGN_IN.route} />
  );
};

export default GatedRoutes;
