import Box from '@mui/material/Box';
import React, { useCallback, useMemo } from "react";
import { Divider, Grid, Typography } from '@mui/material';
import { Commentary, TableWithSingleAnalysisDataSpec } from '../../../types/builderv2.generated';
import QuestionFooter from './QuestionFooter';
import { Choices1 } from '../../../types/taker/fulfillmentstate.generated';
import { useWidgetState } from '../../../containers/WidgetWrapper/wrapper';
import { AnalysisState } from '../../../containers/WidgetWrapper/states';
import { blue } from '@mui/material/colors';
import TableDetailsModal from './DetailsModals/TableDetailsModal';
import TableComponent, { PrimitiveType, ColumnType } from './TableComponent';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';

interface TableWrapperProps {
    moduleIds: string[];
    commentary?: Commentary;
    dataTypeMap: Record<string, PrimitiveType>;
    tableLabel?: string;
    tabelText?: {
        text: string;
        rendered: boolean;
    };
    renderedQuestionChoices?: {
        dataSpecFieldName: string;
        choices: Choices1[];
        rendered: boolean;
    }[];
    validationWarnings?: string[];
    injectedTableRows?: Record<string, any>[];
    columns: ColumnType[],
    dataSpec: TableWithSingleAnalysisDataSpec;
    readOnly: boolean;
}

const TableWrapper = ({
    moduleIds,
    commentary,
    dataTypeMap,
    tableLabel,
    tabelText,
    renderedQuestionChoices,
    validationWarnings,
    injectedTableRows,
    columns,
    dataSpec,
    readOnly
}: TableWrapperProps) => {
    const {
        isTakerStateDirty,
        questionnareDataService
    } = useTakerState();
    const { getState } = useWidgetState();
    const selectedQuestionnaireElement = getState<AnalysisState>().selectedElement;

    const uniqueId = useMemo(() => moduleIds.join('_'), [moduleIds]);

    const isSelected = useMemo(() =>
        moduleIds &&
        selectedQuestionnaireElement &&
        selectedQuestionnaireElement.moduleIds &&
        moduleIds[moduleIds.length - 1] === selectedQuestionnaireElement.nodeId &&
        selectedQuestionnaireElement.moduleIds.every((val, i) => val === moduleIds[i]),
        [
            selectedQuestionnaireElement
        ]
    );

    const defaultRows: any[][] = useMemo(() => {
        if (!dataSpec || !dataSpec.fields) {
            return [];
        }

        const firstField = dataSpec.fields[0];
        const firstValues = questionnareDataService.getAnswer(moduleIds, firstField.name, null);

        const rows: any[][] = [];
        if (!!firstValues) {
            for (const v of firstValues) {
                rows.push([v]);
            }
            for (let i = 1; i < dataSpec.fields.length; i++) {
                let field = dataSpec.fields[i];
                let values = questionnareDataService.getAnswer(moduleIds, field.name, null);
                for (let j = 0; j < values.length; j++) {
                    rows[j].push(values[j]);
                }
            }
        }
        return rows;
    }, [moduleIds, isTakerStateDirty, dataSpec]);

    const onChangeColumns = useCallback((colMap: Record<string, any[]>) => {
        const nameValuePairs = []
        for (const [name, d] of Object.entries(colMap)) {
            nameValuePairs.push({ name, value: d });
        }
        questionnareDataService.updateAnswers(moduleIds, nameValuePairs, null);
    }, [
        moduleIds,
        questionnareDataService
    ]);

    const border = isSelected ? `2px solid ${blue[400]}` : "1px solid #ddd";

    return (
        <Box
            sx={{
                marginTop: 1,
                marginBottom: 1,
                padding: 1,
                backgroundColor: "white",
                borderRadius: 1,
                border: border
            }}
            data-testid={uniqueId}
        >
            <Grid container>
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            sx={{
                                marginLeft: 1
                            }}
                            data-testid={`${uniqueId}-label`}
                        >
                            <strong>
                                {tableLabel}
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        {dataSpec.otherDataSpecFieldName && (
                            <TableDetailsModal
                                moduleIds={moduleIds}
                                commentary={commentary}
                                dataTypeMap={dataTypeMap}
                                tableLabel={tableLabel}
                                tabelText={tabelText}
                                renderedQuestionChoices={renderedQuestionChoices}
                                validationWarnings={validationWarnings}
                                injectedTableRows={injectedTableRows}
                                columns={columns}
                                dataSpec={dataSpec}
                                defaultRows={defaultRows}
                                readOnly={readOnly}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} paddingTop={1} paddingBottom={1}>
                    <Divider />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                    <div
                        style={{
                            alignItems: 'left',
                            width: "100%",
                            display: 'flex'
                        }}
                        data-testid={`${uniqueId}-text`}
                    >
                        <Typography
                            paragraph
                            sx={{
                                "white-space": "pre-wrap"
                            }}
                        >
                            {tabelText?.text}
                        </Typography>
                    </div>
                </Grid>
                <Grid 
                    item xs={12} 
                    paddingTop={2}
                    data-testid={`${uniqueId}-tableComponent`}
                >
                    <TableComponent
                        isSaving={isTakerStateDirty}
                        dataTypeMap={dataTypeMap}
                        renderedQuestionChoices={renderedQuestionChoices}
                        validationWarnings={validationWarnings}
                        injectedTableRows={injectedTableRows}
                        columns={columns}
                        defaultRows={defaultRows}
                        onChangeColumns={onChangeColumns}
                        readOnly={readOnly}
                    />
                </Grid>
                {dataSpec.otherDataSpecFieldName && (
                    <>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <QuestionFooter
                                uniqueId={uniqueId}
                                moduleIds={moduleIds}
                                questionId={dataSpec.otherDataSpecFieldName}
                                iteration={null}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}

export default TableWrapper;
