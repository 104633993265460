import { Box, Chip, Stack, Typography } from '@mui/material';
import { CommentaryOrGuidanceReference } from '../../../../types/taker/uidatastate.generated';
import GuidanceSearch from './GuidanceSearch';
import { Commentary } from '../../../../types/builderv2.generated';

interface GuidanceListProps {
    readOnly: boolean;
    defaultValue: CommentaryOrGuidanceReference[];
    commentary?: Commentary;
    onUpdate: (s: CommentaryOrGuidanceReference[]) => void;
}

const GuidanceList = ({
    readOnly,
    defaultValue,
    commentary,
    onUpdate
}: GuidanceListProps) => {
    return (
        <Box marginTop={1}>
            <Stack>
                {defaultValue.length > 0 ? (
                    <Box paddingBottom={2}>
                        <Stack direction="row" spacing={1}>
                            {defaultValue.map((guideRef) => 
                                <Chip 
                                    label={`${guideRef.data?.alias || guideRef.id}`} 
                                    onDelete={readOnly ? undefined : (() => {
                                        onUpdate(defaultValue.filter(g => g.id !== guideRef.id));
                                    })}
                                />
                            )}
                        </Stack>
                    </Box>
                ) : (
                    <Box paddingBottom={2}>
                        <Typography variant="body1">
                            <i>None</i>
                        </Typography>
                    </Box>
                )}
                {!readOnly && (
                    <GuidanceSearch 
                        commentary={commentary}
                        onAdd={(commentaryOrGuidanceReference) => {
                            let newReferences = [...defaultValue];
                            newReferences.push(commentaryOrGuidanceReference);
                            onUpdate(newReferences);
                        }}
                    />
                )}
            </Stack>
        </Box>
    );
};

export default GuidanceList;