import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridRenderEditCellParams,
    useGridApiContext,
    GridRenderCellParams
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import React, { useMemo, useState } from "react";
import { Alert, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { Commentary } from '../../../types/builderv2.generated';
import QuestionFooter from './QuestionFooter';


interface PercentMappingQuestionProps {
    moduleIds: string[];
    analysisId?: string;
    questionLabel?: string;
    questionText?: string;
    questionCommentary?: Commentary;
    lhsChoices?: any[][];
    validationWarnings?: string[];
    mappedFieldNames: string[];
    mappingToFieldName: string;
    defaultValues: Record<string, any>;
    onChangeValue: (qid: string, d: any) => void;
    readOnly: boolean;
}

interface AdditionalCustomEditInputCellProps {
}

function CustomEditInputCell(props: (GridRenderEditCellParams & AdditionalCustomEditInputCellProps)) {
    const { id, value, field, hasFocus } = props;

    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>();

    useEnhancedEffect(() => {
        if (hasFocus && ref.current) {
            const input = ref.current.querySelector<HTMLInputElement>(
                `input[value="${value}"]`,
            );
            input?.focus();
        }
    }, [hasFocus, value]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: "100%" }}>
            <TextField
                value={value}
                onChange={(event) => {
                    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
                }}
                type="number"
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                }}
                variant="outlined"
            />
        </Box>
    );
}

const PercentMappingQuestion = ({
    moduleIds,
    analysisId,
    questionLabel,
    questionText,
    questionCommentary,
    lhsChoices,
    validationWarnings,
    mappedFieldNames,
    mappingToFieldName,
    defaultValues,
    onChangeValue,
    readOnly
}: PercentMappingQuestionProps) => {
    const [rows, setRows] = useState<GridRowsProp>(() => {
        const transformedRows = [];
        let values = defaultValues[mappingToFieldName];
        for (let i = 0; i < values.length; i++) {
            let v = values[i];
            let row = {
                id: randomId(),
                [mappingToFieldName]: v
            }
            for (const f of mappedFieldNames) {
                row[f] = defaultValues[f][i];
            }
            transformedRows.push(row)
        }
        return transformedRows;
    });
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const uniqueId = useMemo(() => moduleIds.join('_'), [moduleIds]);
    
    const propagateNewRowsUpdate = (newRows: GridRowModel<any>[]) => {
        const updateMap: Record<string, any[]> = {};
        for (const f of mappedFieldNames) {
            updateMap[f] = [];
        }
        updateMap[mappingToFieldName] = [];

        for (const row of newRows) {
            for (const key of Object.keys(updateMap)) {
                updateMap[key].push(row[key]);
            }
        }

        setRows(newRows);
        for (const [key, values] of Object.entries(updateMap)) {
            onChangeValue(key, values);
        }
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        propagateNewRowsUpdate(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            propagateNewRowsUpdate(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel<any>) => {
        const updatedRow = { ...newRow, isNew: false };
        propagateNewRowsUpdate(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [];
    if (lhsChoices) {
        for (let i = 0; i < mappedFieldNames.length; i++) {
            columns.push({
                field: mappedFieldNames[i],
                headerName: mappedFieldNames[i],
                editable: true,
                type: 'singleSelect',
                valueOptions: lhsChoices[i] || []
            });
        }
    }

    columns.push({
        field: mappingToFieldName,
        headerName: mappingToFieldName,
        renderCell: (params: GridRenderCellParams<any, number>) => (
            <>
                {params.value && `${params.value}%`}
            </>
        ),
        renderEditCell: (params: GridRenderEditCellParams) => (
            <CustomEditInputCell
                {...params}
            />
        ),
        editable: true,
        type: "number"
    });
    columns.push({
        field: 'actions',
        type: 'actions',
        resizable: true,
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
                return [
                    <IconButton
                        sx={{
                            color: 'primary.main',
                        }}
                        size="small"
                        onClick={handleSaveClick(id)}
                    >
                        <SaveIcon />
                    </IconButton>,
                    <IconButton
                        size="small"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    >
                        <CancelIcon />
                    </IconButton>,
                ];
            }
            return [
                <IconButton
                    size="small"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                >
                    <EditIcon />
                </IconButton>,
                <IconButton
                    size="small"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                >
                    <DeleteIcon />
                </IconButton>,
            ];
        },
    });

    return (
        <Box
            sx={{
                marginTop: 1,
                marginBottom: 1,
                padding: 1,
                backgroundColor: "white",
                borderRadius: 1,
                border: "1px solid #ddd"
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs={12}
                >
                    <Typography
                        component="span"
                        variant="subtitle1"
                        sx={{
                            marginLeft: 1
                        }}
                    >
                        <strong>
                            {questionLabel}
                        </strong>
                    </Typography>
                    <Button
                        sx={{
                            marginLeft: 1,
                            float: "right"
                        }}
                        size="small"
                        variant="outlined"
                        color="inherit"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setRows((oldRows) =>
                                [...oldRows, { id: randomId(), isNew: true }]
                            );
                        }}
                    >
                        Row
                    </Button>
                </Grid>
                <Grid item xs={12} paddingTop={1}>
                    <Divider />
                </Grid>
                {(!!validationWarnings && validationWarnings.length > 0) && (
                    <Grid item xs={12} paddingTop={1}>
                        <Alert severity="warning">
                            {validationWarnings.map(w => <div>* {w}</div>)}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12} paddingTop={2}>
                    <div
                        style={{
                            alignItems: 'left',
                            width: "100%",
                            display: 'flex'
                        }}
                    >
                        <Typography variant="subtitle2">
                            {questionText}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .actions': {
                                color: 'text.secondary',
                            },
                            '& .textPrimary': {
                                color: 'text.primary',
                            },
                        }}
                    >
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={(error) => {
                                //console.log(error);
                            }}
                            hideFooter
                            autoHeight
                        />
                    </Box>
                </Grid>
                {analysisId && (
                    <>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <QuestionFooter
                                uniqueId={uniqueId}
                                moduleIds={moduleIds}
                                questionId={analysisId}
                                iteration={null}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}

export default PercentMappingQuestion;
