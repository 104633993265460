import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "../../components/navigation/SideNav";
import { Box, Paper, Typography, Fab, Chip, Button, Grid, Toolbar } from "@mui/material/";
import MyLanguageModels from "./LanguageModels";
import MyAssistants from "./Assistants";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import {
  LanguageModel,
  Assistant
} from "../../redux/models/dataModelTypes";
import { useAddLanguageModelMutation } from "../../redux/services/languageModel";
import { useAddAssistantMutation } from "../../redux/services/assistant";


interface CreateLanguageModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  languageModel: LanguageModel | null;
}

const CreateLanguageModal = ({ 
    open, 
    setOpen,
    languageModel
}: CreateLanguageModalProps) => {
    const navigate = useNavigate();

    const [addAssistant, addAssistantResult] = useAddAssistantMutation();

    useEffect(() => {
        if (addAssistantResult.isSuccess) {
            let assistant = addAssistantResult.data;
            navigate(`/assistant/${assistant.id}`)
        }
    }, [addAssistantResult]);

    if (!languageModel) {
        return null;
    }

    return (
        <SimpleModalWrapper
            headerText="Assistants"
            open={open}
            handleClose={() => setOpen(false)}
            sx={{ height: "auto", padding: "16px 24px 16px 24px" }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Toolbar 
                            variant="dense"
                            sx={{ 
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "100%",
                            justifyContent: "end"
                        }}
                    >
                        <Fab
                            variant="extended"
                            color="primary"
                            size="medium"
                            sx={{ 
                                float: "right"                
                            }}
                            component="button"
                            onClick={(e: any) => {
                                if (languageModel) {
                                    addAssistant({
                                        name: "new assistant",
                                        description: "",
                                        languageModelId: languageModel.id
                                    });
                                }
                            }}
                        >
                            Create New
                        </Fab>
                    </Toolbar>
                </Grid>
                <Grid item xs={12}>
                    <MyAssistants languageModelId={languageModel.id}/>
                </Grid>
            </Grid>
        </SimpleModalWrapper>
    );
}

const Models = () => {
    const [targetLanguageModel, setTargetLanguageModel] = useState<LanguageModel | null>(null);
    const [takerModalOpen, setTakerModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const [createLanguageModel, languageModelResult] = useAddLanguageModelMutation()

    const createNewBuilder = () => {
        createLanguageModel({
            name: "new model",
            description: ""
        });
    };

    useMemo(() => {
        if (languageModelResult.isSuccess && languageModelResult.data) {
            navigate(`/languageModel/${languageModelResult.data?.id}`);
        }        
    }, [languageModelResult]);
  
    return (
        <SideNav>
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            padding: 2, 
                            width: "100%"
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                    Language Models
                                </Typography>
                            </Box>
                            <Fab
                                variant="extended"
                                color="primary"
                                size="medium"
                                sx={{ fontWeight: "bold" }}
                                component="button"
                                onClick={(e: any) => createNewBuilder()}
                            >
                                Create Language Model
                            </Fab>
                        </Box>            
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box 
                        sx={{ 
                            padding: 2, 
                            width: "100%" 
                        }}>    
                            <MyLanguageModels 
                                onOpenDetails={(lm: LanguageModel) => {
                                    setTargetLanguageModel(lm);
                                    setTakerModalOpen(true);
                                }}
                            />
                    </Box>
                </Grid>
            </Grid>
            <CreateLanguageModal
                open={takerModalOpen}
                setOpen={setTakerModalOpen}
                languageModel={targetLanguageModel}
            />
        </SideNav>
    );
}

export default Models;