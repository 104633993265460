import { Button, Grid, Typography } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';

import { GraphFulfillmentNode, ACTIVE_COLOR, ANSWERED_COLOR, ATTENTION_COLOR, INACTIVE_COLOR } from './common';
import { GraphFulfillmentState } from '../../../types/taker/fulfillmentstate.generated';
import { useReadOnlyBuilderData } from '../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData';
import { MappingQuestionFulfillment } from '../../../types/builderv2.generated';
import { useWidgetState } from '../../../containers/WidgetWrapper/wrapper';
import { AnalysisState } from '../../../containers/WidgetWrapper/states';

import './custom-node.css';

interface CustomNodeProps {
    id: any;
    data: any;
}

const MAX_LINES = 4;
const LINE_HEIGHT = 2.5;
const CHARS_PER_LINE = 26; // max characters in 3 lines
const TITLE_MAX_CHARS = 23;

function CustomNode({ id, data }: CustomNodeProps) {
    const {
        fulfillmentStateHolder
    } = useTakerState();
    const { getState, mutateState } = useWidgetState();
    const { builderHolder } = useReadOnlyBuilderData();

    const controlledByQuestionnaire = getState<AnalysisState>().controlledByQuestionnaire;

    const isModuleNode = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        return node.nodeType === "module"
    }, [data.node]);

    const moduleForNode = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        if (node.nodeType === "module") {
            let moduleIds = data.mid.split('_');
            moduleIds.push(node.moduleId);
            return builderHolder.getModule(moduleIds);
        }
    }, [isModuleNode, builderHolder, data]);
    
    const moduleNodeMappingQuestionFulfillmentInfo = useMemo(() => {
        if (moduleForNode) {
            if (moduleForNode?.uiFulfillment?.fulfillmentType === "mapping-question") {
                return moduleForNode.uiFulfillment as MappingQuestionFulfillment;
            }
        }
    }, [moduleForNode]);

    const isQuestionNode = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        return node.nodeType === "question"
    }, [data.node]);

    const isEndNode = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        return node.nodeType === "end";
    }, [data.node]);

    const moduleId = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        if (node.nodeType === "module") {
            return node.moduleId;
        }
    }, [data.node]);

    const textTitle = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        if (node.nodeType === "question") {
            return (
                <Typography
                    style={{
                        fontSize: "1em"
                    }}
                >
                    {(node.questionLabel.length > TITLE_MAX_CHARS) ? (
                        `${node.questionLabel.slice(0, TITLE_MAX_CHARS)}...`
                    ) : (
                        node.questionLabel
                    )}
                </Typography>
            );
        }
        return null;
    }, [data.node]);

    const statementText = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        if (node.nodeType === "end") {
            return (
                <Typography
                    style={{
                        fontSize: "1em"
                    }}
                >
                    {node.label || node.statement}
                </Typography>
            );
        }
        return null;
    }, [data.node]);

    const textDetails = useMemo(() => {
        let node: GraphFulfillmentNode = data.node;
        let moduleIds = data.mid.split('_');
        let selectedIteration = data.iteration;
        if (node.nodeType === "question" && fulfillmentStateHolder) {
            let module = builderHolder.getModule(moduleIds);
            let state = fulfillmentStateHolder.getSubstate(moduleIds, selectedIteration);
            if (state && module && module.uiFulfillment?.fulfillmentType === "graph") {
                let graphState = state as GraphFulfillmentState;
                let details = node.questionText;
                for (const rt of graphState.renderedText) {
                    if (rt.nodeId === node.id && rt.rendered) {
                        details = rt.qText;
                    }
                }
                let numChars = details ? details.length : 0;
                let charsPerLine = CHARS_PER_LINE;
                let numLines = Math.min(Math.ceil(numChars / charsPerLine), MAX_LINES);
                return (
                    <Typography
                        paragraph
                        style={{
                            fontSize: "0.75em",
                            width: "100%",
                            lineHeight: `${LINE_HEIGHT}ex`,
                            height: `${(numLines * LINE_HEIGHT)}ex`,
                            overflow: 'hidden'
                        }}
                    >
                        {details}
                    </Typography>
                );
            }
        }
        return null;
    }, [data, fulfillmentStateHolder]);

    const borderStyle = useMemo(() => {
        if (data.isActive) {
            if (data.requiresAttention) {
                return `4px solid ${ATTENTION_COLOR}`;
            } else if (data.hasAnswer) {
                return `4px solid ${ANSWERED_COLOR}`;
            } else if (isEndNode) {
                return `4px solid #ddd`;
            } else {
                return `4px solid ${ACTIVE_COLOR}`;
            }
        } else {
            return `1px solid ${INACTIVE_COLOR}`;
        }
    }, [data]);

    const backgroundColor = useMemo(() => {
        // TODO: include data.hasUnrenderedText
        if (isEndNode) {
            return ANSWERED_COLOR;
        } else {
            return `white`;
        }
    }, [isEndNode]);

    const textColor = useMemo(() => {
        if (backgroundColor === ANSWERED_COLOR) {
            return "white";
        } else {
            return "black";
        }
    }, [backgroundColor]);

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "inline-flex",
                alignItems: "center"
            }}
        >
            {!data.isStart && (
                <Handle
                    type="target"
                    position={Position.Top}
                    style={{ top: "0" }}
                    //onConnect={(params) => console.log('handle onConnect', params)}
                />
            )}
            <Grid
                container
                padding={1}
                sx={{
                    height: "100%",
                    borderRadius: 2,
                    border: borderStyle,
                    background: backgroundColor,
                    color: textColor
                }}
            >
                {isModuleNode && (
                    !!moduleNodeMappingQuestionFulfillmentInfo ? (
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                {moduleNodeMappingQuestionFulfillmentInfo.questionLabel}
                            </Grid>
                            <Grid item xs={12}>
                                {moduleNodeMappingQuestionFulfillmentInfo.questionText}
                            </Grid>
                        </Grid>
                    ) : (
                        controlledByQuestionnaire ? (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    height: "100%",
                                    width: "100%"
                                }}
                            >
                                <Grid
                                    item
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <Typography
                                        color="inherit"
                                        variant="h5"
                                    >
                                        {!!moduleForNode ? (
                                            moduleForNode.displayName
                                        ): (
                                            moduleId
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid 
                                justifyItems="center" 
                                justifySelf="center" 
                                justifyContent="center" 
                                item
                                container
                            >
                                <Button
                                    data-testid="module-button"
                                    sx={{ 
                                        width: "100%", 
                                        height: "100%" 
                                    }}
                                    color="inherit"
                                    onClick={() => {
                                        if (moduleId) {
                                            let existingModuleIds: string[] = [...data.mid.split('_')];
                                            existingModuleIds.push(moduleId);
                                            mutateState<AnalysisState>({
                                                targetMid: existingModuleIds.join('_')
                                            });
                                        }
                                    }}
                                >
                                    {!!moduleForNode ? (
                                        moduleForNode.displayName
                                    ): (
                                        moduleId
                                    )}
                                </Button>
                            </Grid>
                        )
                    )
                )}
                {isQuestionNode && (
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            {textTitle}
                        </Grid>
                        <Grid item xs={12}>
                            {textDetails}
                        </Grid>
                    </Grid>
                )}
                {isEndNode && (
                    <Grid 
                        item 
                        xs={12}
                        sx={{
                            background: ANSWERED_COLOR
                        }}
                    >
                        <Grid item xs={12}>
                            {statementText}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {(!data.isEnd) && (
                <Handle
                    type="source"
                    position={Position.Bottom}
                    style={{ bottom: "0" }}
                    id={id}
                />
            )}
        </div>
    );
}

export default memo(CustomNode);
