import React, { useMemo, useRef } from "react";
import {
    TextField,
    Box,
    IconButton
} from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { Cancel, Save } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
    noBorder: {
        border: "none",
    }
}));

interface EditableTextProps {
    defaultValue: string;
    onChangeValue: (s: string) => void;
    placeholder?: string;
    readOnly: boolean;
    multiline?: boolean;
}

const EditableText = ({
    defaultValue,
    onChangeValue,
    placeholder,
    readOnly,
    multiline = false
}: EditableTextProps) => {
    const [editing, setEditing] = React.useState(false);
    const [editedState, setEditedState] = React.useState(defaultValue)

    const customClasses = useStyles();

    const inputPropsClasses = useMemo(() => {
        const classes: Record<string, string> = {};
        if (!editing) {
            classes['notchedOutline'] = customClasses.noBorder;
        }
        return classes;
    }, [editing, customClasses]);

    return (
        <Box
            sx={{
                width: "100%",
                display: "inline-flex",
                alignItems: "center"
            }}
        >
            <TextField
                data-testid="editable-text"
                id="editable-text"
                placeholder={placeholder || "enter text"}
                size="small"
                value={editedState}
                onChange={(e: any) => {
                    if (!readOnly) {
                        setEditedState(e.target.value);
                    }
                }}
                onClick={() => {
                    setEditing(true);
                }}
                variant="outlined"
                multiline={multiline}
                InputProps={{
                    readOnly,
                    autoComplete: "off",
                    classes: inputPropsClasses
                }}
            />
            {editing && (
                <IconButton 
                    data-testid="save-text-button"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                        onChangeValue(editedState);
                        setEditing(false);
                    }}
                    color="info"
                >
                    <Save />
                </IconButton>
            )}
            {editing && (
                <IconButton 
                    data-testid="cancel-text-button"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                        setEditedState(defaultValue);
                        setEditing(false);
                    }}
                    color="error"
                >
                    <Cancel />
                </IconButton>
            )}
        </Box>
    );
};

export default EditableText;