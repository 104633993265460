import { Box, Card, CardContent, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent } from "@mui/material";
import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";

import { useEffect, useState } from "react";
import { useReadOnlyBuilderData } from "../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
interface CategoriesWidgetProps {
    keyTerm: KeyTerm;
}
export const CategoriesWidget = ({ keyTerm }: CategoriesWidgetProps) => {
    const { keyTerms } = useReadOnlyBuilderData();
    const { documentKeyTermsService } = useKeyTermGroupState();
    const [editedKeyTermCategories, setEditedKeyTermCategories] = useState<string[]>(keyTerm.categories);
    useEffect(() => {
        documentKeyTermsService.updateKeyTermInfo(
            keyTerm.termName,
            keyTerm.termName,
            editedKeyTermCategories
        )
    }, [editedKeyTermCategories]);
    return (
        <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent>
                <FormControl sx={{ m: 1, width: "95%" }}>
                    <InputLabel id="demo-multiple-chip-label">Key Term Categories</InputLabel>
                    <Select
                        fullWidth
                        data-testid="key-term-categories"
                        inputProps={{ "data-testid": "key-term-cateogries-input" }}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={editedKeyTermCategories}
                        onChange={(event: SelectChangeEvent<string[]>) => {
                            const {
                                target: { value },
                            } = event;

                            setEditedKeyTermCategories(typeof value === 'string' ? value.split(',') : value);

                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Key Term Categories" />}
                        renderValue={(selected: string[]) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value: string) => (
                                    <Chip key={value} id={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {keyTerms.map((kt) => (
                            <MenuItem
                                data-testid={`key-term-category-${kt.label}`}
                                key={kt.label}
                                value={kt.label}
                            >
                                {`${kt.label}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </CardContent>
        </Card>
    )
}