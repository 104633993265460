import React, { memo } from 'react';
import { Card, CardContent, Typography, Stack, Box, Divider } from '@mui/material';
import SourcesList from './SourcesList';
import AnalysisField from './AnalysisField';
import { Commentary, DisplayedVariableReference, DisplayedVariableReferences, StaticChoices } from '../../../../types/builderv2.generated';
import GuidanceList from './GuidanceList';
import QuestionAnswer, { PrimitiveType } from '../QuestionAnswer';
import { GraphFulfillmentState } from '../../../../types/taker/fulfillmentstate.generated';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../../../types/taker/uidatastate.generated';

interface QuestionDetailsModalProps {
    uniqueId: string;
    graphFulfillmentState: GraphFulfillmentState;
    nodeId: string;
    dataType: PrimitiveType;
    questionText: string | null;
    questionType: "radio" | "multiselect" | "input";
    choices?: StaticChoices | DisplayedVariableReference | DisplayedVariableReferences;
    answer: any,
    setAnswer: (a: any) => void;
    analysis: string,
    setAnalysis: (a: string) => void;
    reference: CommentaryOrGuidanceReference[], 
    setReference: (a: CommentaryOrGuidanceReference[]) => void;
    source: KeyTermSource[],
    setSource: (a: KeyTermSource[]) => void;
    enableAnalysis: boolean;
    enableCommentary: boolean;
    commentary?: Commentary;
    readOnly: boolean;
}

const CurrentAnswerCard = ({
    uniqueId,
    graphFulfillmentState,
    nodeId,
    dataType,
    questionText,
    questionType,
    choices,
    answer,
    setAnswer,
    analysis,
    setAnalysis,
    reference, 
    setReference,
    source,
    setSource,
    enableAnalysis,
    enableCommentary,
    commentary,
    readOnly
}: QuestionDetailsModalProps) => {
    return (
        <Card variant="outlined" sx={{ height: "100%", overflowY: "auto" }}>
            <CardContent>
                <Typography variant="h6">
                    Current
                </Typography>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                {questionText && (
                    <Box
                        alignItems="left"
                        width="100%"
                        display="flex"
                        marginBottom={1}
                    >
                        <Typography 
                            variant="subtitle2"
                            sx={{
                                "white-space": "pre-wrap"
                            }}
                        >
                            {questionText}
                        </Typography>
                    </Box>
                )}
                <QuestionAnswer
                    uniqueId={uniqueId}
                    graphFulfillmentState={graphFulfillmentState}
                    nodeId={nodeId}
                    dataType={dataType}
                    questionType={questionType}
                    choices={choices}
                    defaultValue={answer}
                    onChangeValue={setAnswer}
                    readOnly={readOnly}
                />
                {enableAnalysis && (
                    <Box paddingTop="15px">
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Analysis</strong>
                        </Typography>
                        <AnalysisField
                            readOnly={readOnly}
                            defaultValue={analysis}
                            onUpdate={setAnalysis}
                        />
                    </Box>
                )}
                {(enableCommentary) && (
                    <Box paddingTop="15px">
                        <Typography variant="subtitle1" color="text.secondary">
                            <strong>Guidance</strong>
                        </Typography>
                        <GuidanceList
                            readOnly={readOnly}
                            defaultValue={reference}
                            commentary={commentary}
                            onUpdate={setReference}
                        />
                    </Box>
                )}
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>Sources</strong>
                    </Typography>
                    <SourcesList
                        readOnly={readOnly}
                        defaultValue={source}
                        onUpdate={setSource}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default memo(CurrentAnswerCard);