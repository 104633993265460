import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import axios from 'axios';
import { Grid } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import { MemoGenerationState } from "../../containers/WidgetWrapper/states";
import ReportsModal from "./ReportsModal";
import { useReportState } from "../../containers/TakerDocumentState/ReportState";

const Toolbar = () => {
    const { latestReportRevision } = useReportState();
    const { takerPermissionState } = useTakerState();
    const { getState, mutateState } = useWidgetState()

    const anchorRef = useRef<HTMLDivElement>(null);
    const [exportBtnOpen, setExportBtnOpen] = useState(false);
    const exportAnchorRef = useRef<HTMLButtonElement>(null);

    const generatedReportsModalOpen = getState<MemoGenerationState>().generatedReportsModalOpen;
    const currentEditor = getState<MemoGenerationState>().currentEditor;

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
    };

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    const tfToDocx = useCallback(() => {
        if (currentEditor) {
            axios({
                url: `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/v1/lexical_to_docx`,
                method: 'POST',
                responseType: 'blob',
                data: {
                    lexical: currentEditor.getEditorState().toJSON(),
                    report_revision: latestReportRevision
                },
                withCredentials: true
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (latestReportRevision) {
                    const dateString = (new Date(latestReportRevision.updatedAt)).toLocaleString()
                    link.setAttribute('download', `report (revised ${dateString}).docx`);
                } else {
                    link.setAttribute('download', 'report.docx');
                }
                document.body.appendChild(link);
                link.click();
            });
        }
    }, [
        currentEditor,
        latestReportRevision
    ]);

    return (
        <Box
            sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                paddingLeft: 1,
                border: "1px solid #ddd",
                borderRadius: 1,
                backgroundColor: "rgba(221,221,221,0.25)"
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                paddingRight={1}
                paddingTop={0.5}
                paddingBottom={0.5}
            >
                <Grid item xs={4} textAlign="start">
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="Button group with a nested menu"
                    >
                        <Button
                            data-testid="reports-button"
                            onClick={() => {
                                mutateState<MemoGenerationState>({
                                    generatedReportsModalOpen: true
                                });
                            }}
                        >
                            Reports
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={4} textAlign="end">
                    <Button
                        data-testid="export-button"
                        ref={exportAnchorRef}
                        variant="contained"
                        endIcon={<ArrowDropDown />}
                        aria-controls={exportBtnOpen ? 'split-button-menu' : undefined}
                        aria-expanded={exportBtnOpen ? 'true' : undefined}
                        aria-label="select export"
                        aria-haspopup="menu"
                        onClick={() => setExportBtnOpen((prevOpen) => !prevOpen)}
                    >
                        Export
                    </Button>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={exportBtnOpen}
                        anchorEl={exportAnchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {["DOCX"].map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    disabled={index === 2}
                                                    onClick={(event) => {
                                                        if (index === 0) {
                                                            tfToDocx();
                                                        }
                                                    }}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
            <ReportsModal
                readOnly={isReadOnly}
                open={generatedReportsModalOpen}
                setOpen={(o) => mutateState<MemoGenerationState>({
                    generatedReportsModalOpen: o
                })}
            />
        </Box>
    );
};

export default Toolbar;