import { Commentary } from "../../../../types/builderv2.generated";

export interface DisplayGuidance {
    id: string;
    group: string;
    display: string;
}

export const collectHeadersForTarget = (labeledRefs: any[], currentHeaders: string[], targetId: string, callback: (h: string[]) => void) => {
    for (const labeledRef of labeledRefs) {
        const id = labeledRef["id"];
        if (targetId === id) {
            // reached the target, insert into tfCommentary
            const label = labeledRef["label"];
            currentHeaders.push(label);
            callback(currentHeaders);
        } else {
            const nested = labeledRef["nested"];
            const label = labeledRef["label"];
            let newHeaders = [...currentHeaders];
            newHeaders.push(label);
            collectHeadersForTarget(nested, newHeaders, targetId, callback);
        }
    }
};

export const collectHeadersAndContentForTarget = (commentary: Commentary | undefined, currentHeaders: string[], targetId: string, callback: (v: string | undefined, h: string[]) => void) => {
    if (!commentary) {
        return;
    }

    for (const c of commentary) {
        const id = c.id;
        if (targetId === id) {
            currentHeaders.push(c.label);
            callback(c.value, currentHeaders);
        } else {
            const nested = c.nested;
            let newHeaders = [...currentHeaders];
            newHeaders.push(c.label);
            collectHeadersAndContentForTarget(nested, newHeaders, targetId, callback);
        }
    }
};

export const updateCommentaryWithHeadersAndContent = (nestedCommentary: Commentary | undefined, headers: string[], content: string, targetId: string) => {
    let header = headers.shift();
    if (header === undefined) {
        return [];
    }

    const updatedCommentary: Commentary = [];
    let headerExists = false;
    if (nestedCommentary) {
        for (const c of nestedCommentary) {
            if (c.label === header && c.nested) {
                headerExists = true;
                c.nested = updateCommentaryWithHeadersAndContent(
                    JSON.parse(JSON.stringify(c.nested)),
                    headers,
                    content,
                    targetId
                );
            }
            updatedCommentary.push(c);
        }
    }

    // If header doesn't exist in the commentary we can add it
    if (!headerExists) {
        if (headers.length === 0) {
            updatedCommentary.push({
                id: targetId,
                label: header,
                value: content
            });
        } else {
            updatedCommentary.push({
                id: header,
                label: header,
                nested: updateCommentaryWithHeadersAndContent([], headers, content, targetId)
            });
        }
    }

    return updatedCommentary;
};

export const collectAllHeaders = (labeledRefs: any[], currentHeaders: string[], finalHeaders: DisplayGuidance[]) => {
    for (const labeledRef of labeledRefs) {
        const id = labeledRef["id"];
        const hasContent = labeledRef["hasContent"];
        if (hasContent) {
            const headerCpy = [...currentHeaders];
            finalHeaders.push({
                id: id,
                group: headerCpy.join(' • '),
                display: labeledRef["label"]
            });
        } else {
            const label = labeledRef["label"];
            let newHeaders = [...currentHeaders];
            newHeaders.push(label);
            collectAllHeaders(labeledRef["nested"], newHeaders, finalHeaders);
        }
    }
};

export const collectHeaders = (idsToInclude: Set<string>, labeledRefs: any[], currentHeaders: string[], finalHeaders: DisplayGuidance[]) => {
    for (const labeledRef of labeledRefs) {
        const id = labeledRef["id"];
        const hasContent = labeledRef["hasContent"];
        if (hasContent) {
            const altIds: string[] = labeledRef["alt_ids"] || [];
            if (idsToInclude.has(id) || altIds.some(a => idsToInclude.has(a))) {
                const headerCpy = [...currentHeaders];
                finalHeaders.push({
                    id: id,
                    group: headerCpy.join(' • '),
                    display: labeledRef["label"]
                });
            }
        } else {
            const label = labeledRef["label"];
            let newHeaders = [...currentHeaders];
            newHeaders.push(label);
            collectHeaders(idsToInclude, labeledRef["nested"], newHeaders, finalHeaders);
        }
    }
};

export const collectCommentary = (commentary: Commentary | undefined, currentHeaders: string[], finalHeaders: DisplayGuidance[]) => {
    if (!commentary) {
        return;
    }

    for (const c of commentary) {
        const id = c.id;
        const hasNested = !!c.nested && c.nested.length !== 0;
        if (hasNested) {            
            collectCommentary(c.nested, [...currentHeaders, c.label], finalHeaders);
        } else {
            const headerCpy = [...currentHeaders];
            finalHeaders.push({
                id: id,
                group: headerCpy.join(' • '),
                display: c.label
            });
        }
    }
};