import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
    $getSelection,
    $isRangeSelection,
    ElementNode,
    LexicalEditor,
} from 'lexical';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import FloatingReportSectionToolbar from './FloatingReportSectionToolbar';
import { ReportSectionUpdatePayload } from '../../nodes/ReportSectionNode';
import { getSelectedNode } from '../common';
import { $isInnerReportSectionNode } from '../../nodes/InnerReportSectionNode';

import './index.css';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';

function useFloatingReportSection(
    readOnly: boolean,
    editor: LexicalEditor,
    reportSectionNode: ReportSectionUpdatePayload,
    anchorElem: HTMLElement
): JSX.Element | null {
    const [showToolbar, setShowToolbar] = useState(false);

    const updatePopup = useCallback(() => {
        editor.getEditorState().read(() => {
            // Should not to pop up the floating toolbar when using IME input
            if (editor.isComposing()) {
                return;
            }
            const selection = $getSelection();
            const nativeSelection = window.getSelection();
            const rootElement = editor.getRootElement();
            if (
                nativeSelection !== null &&
                (!$isRangeSelection(selection) ||
                    rootElement === null ||
                    !rootElement.contains(nativeSelection.anchorNode))
            ) {
                setShowToolbar(false);
                return;
            }

            if (!$isRangeSelection(selection)) {
                return;
            }

            const node = getSelectedNode(selection);

            // look ~4 levels up, for tables the clicks can be nested in cells
            const parent = node?.getParent();

            let p: undefined | ElementNode | null = parent;
            let isInnerReportSectionNode = false;
            for (let i = 0; i < 4; i ++) {
                p = p?.getParent();
                if (p && $isInnerReportSectionNode(p)) {
                    isInnerReportSectionNode = true;
                    break;
                }
            }

            if ($isInnerReportSectionNode(parent) || isInnerReportSectionNode) {
                setShowToolbar(true);
            } else {
                setShowToolbar(false);
            }
        });
    }, [editor]);

    useEffect(() => {
        document.addEventListener('selectionchange', updatePopup);
        return () => {
            document.removeEventListener('selectionchange', updatePopup);
        };
    }, [updatePopup]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(() => {
                updatePopup();
            }),
            editor.registerRootListener(() => {
                if (editor.getRootElement() === null) {
                    setShowToolbar(false);
                }
            }),
        );
    }, [editor, updatePopup]);

    if (!readOnly && showToolbar) {
        return createPortal(
            <FloatingReportSectionToolbar
                editor={editor}
                anchorElem={anchorElem}
                selectedReportSectionNode={reportSectionNode}
            />,
            anchorElem,
        );
    }
    return null;
}

export default function FloatingReportSectionToolbarPlugin({
    reportSectionNode,
    anchorElem = document.body,
}: {
    reportSectionNode: ReportSectionUpdatePayload;
    anchorElem?: HTMLElement;
}): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    const { takerPermissionState } = useTakerState();

    const readOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    return useFloatingReportSection(
        readOnly,
        editor,
        reportSectionNode,
        anchorElem
    );
}