import React from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Box, Button } from "@mui/material/";
import * as yup from "yup";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddOrganizationMutation } from "../../redux/services/organization";


interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface formInputs {
  name: string;
}

export function CreateOrganizationModal({ open, setOpen }: Props) {
  const [addOrganization, addOrganizationResult] = useAddOrganizationMutation()

  const schema = yup.object().shape({
    name: yup.string().required()
  });

  const defaultValues = {
    name: ""
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<formInputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitCust = (d: any) => {
    addOrganization({
      name: d.name
    });
    setOpen(false);
  };

  return (
    <SimpleModalWrapper
      headerText="Create Organization"
      open={open}
      handleClose={handleClose}
      sx={{ width: "30vw", height: "auto", padding: "16px 24px 16px 24px" }}
    >
      <Box>
        <form
          onSubmit={handleSubmit(handleSubmitCust)}
          style={{
            padding: "16px 0px 16px 0px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <TextFieldRhf
            label="Name"
            fieldName="name"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <Box>
            <Button variant="contained" type="submit" sx={{ width: "30%" }}>
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ width: "30%", marginLeft: "30px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </SimpleModalWrapper>
  );
}
