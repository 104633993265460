import React, { useContext, PropsWithChildren, useMemo, useEffect, useState } from 'react';
import { BuilderHolder } from "../../types/builder";
import { 
  useGetBuilderDocumentQuery, 
  useGetDefaultTakerDocumentSettingsQuery 
} from '../../redux/services/builder';
import JSZip from "jszip";
import axios from 'axios';
import { BuilderDocumentGuidance, TakerDocumentSettings } from '../../redux/models/dataModelTypes';
import { Module } from '../../types/builderv2.generated';

interface BuilderDataHookData {
  builderHolder: BuilderHolder;
  tableLabelLookup: Record<string, string>;
  keyTerms: Array<{ label: string, description: string }>;
  positionedModuleList: { path: string[]; module: Module; }[];
  getContentFromGuidance: (guidancePackage: JSZip, guidanceContentId: string) => any;
  getRefFromGuidance: (guidancePackage: JSZip) => any;
  packageByGuidanceId: Record<string, JSZip>;
  builderDocumentGuidances: BuilderDocumentGuidance[];
  guidanceIdLookup: Record<string, {id: string, packageTag: string, label: string}>;
  aliasLookup: Record<string, string>;
  defaultTakerDocumentSettings?: TakerDocumentSettings;
}

const REFS_FILENAME = "refs.json";
const  baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";

type BuilderDataHook = () => BuilderDataHookData;

const Context = React.createContext({});

export const useReadOnlyBuilderData: BuilderDataHook = () => {
  return useContext(Context) as BuilderDataHookData;
}

interface ReadOnlyBuilderDataContainerProps {
  builderDocumentId: string;
}

export const ReadOnlyBuilderDataContainer: React.FC<PropsWithChildren<ReadOnlyBuilderDataContainerProps>> = ({
  builderDocumentId,
  children
}) => {
  const [packageByGuidanceId, setPackageByGuidanceId] = useState<Record<string, JSZip>>({});
  const [guidanceIdLookup, setGuidanceIdLookup] = useState<Record<string, {id: string, packageTag: string}>>({});
  const [aliasLookup, setAliasLookup] = useState<Record<string, string>>({});

  const { data: builderDocument } = useGetBuilderDocumentQuery(builderDocumentId);
  const { data: defaultTakerDocumentSettings } = useGetDefaultTakerDocumentSettingsQuery(builderDocumentId);

  const builderHolder = useMemo(() => {
    if (builderDocument) {
      return new BuilderHolder(builderDocument.configuration);
    }
  }, [builderDocument]);

  const builderHolderData = useMemo(() => {
    if (builderHolder) {
      return {
        builderHolder: builderHolder,
        keyTerms: builderHolder.getKeyTerms(),
        positionedModuleList: builderHolder.getPositionedModuleList()
      }
    }
  }, [builderHolder]);

  useEffect(() => {
    if (!builderDocument) {
      return;
    }

    for (const bdg of builderDocument.builderDocumentGuidances) {
      if (!bdg) {
        continue;
      }

      axios({ 
        url: `${baseUrl}/v1/indexed_guidance/${bdg.indexedGuidanceId}/download_package`, 
        method: "POST", 
        headers: {
          'Cache-control': 'max-age=86400'
        },
        data: {
          version: bdg.indexedGuidanceVersion
        },
        responseType: "blob",
        withCredentials: true
      }).then(d => {
        const zip = new JSZip();
        zip.loadAsync(d.data).then(async (zipped) => {
          if (zipped && bdg.indexedGuidance) {
            packageByGuidanceId[bdg.indexedGuidance.guidanceId] = zipped;
            setPackageByGuidanceId(packageByGuidanceId);
          }
        })
      });
    }
  }, [builderDocument]);

  useEffect(() => {
    if (!builderDocument) {
      return;
    }

    const idLookup: Record<
      string, 
      {id: string, packageTag: string, label: string}
    > = {};
    const aliasToIdLookup: Record<string, string> = {};
    for (const bdg of builderDocument.builderDocumentGuidances) {
      if (!bdg || !bdg.indexedGuidance) {
        continue;
      }
      const collectAllHeaders = (labeledRefs: any[]) => {
        for (const labeledRef of labeledRefs) { 
            const id = labeledRef["id"];
            const hasContent = labeledRef["hasContent"];
            if (hasContent && bdg.indexedGuidance) {
                idLookup[id] = {
                  id: bdg.indexedGuidance.guidanceId,
                  packageTag: "latest",
                  label: bdg.indexedGuidance.name
                };
                if (labeledRef["alt_ids"]) {
                  for (const alts of labeledRef["alt_ids"]) {
                    aliasToIdLookup[alts] = id;
                  }
                 }
            } else {
                collectAllHeaders(labeledRef["nested"]);
            }
        }
      };

      const refs = bdg.indexedGuidance.referenceMap
      collectAllHeaders(refs.labeledRefs);
    }
    setAliasLookup(aliasToIdLookup);
    setGuidanceIdLookup(idLookup);
  }, [builderDocument]);

  const builderDocumentGuidances = useMemo(() => {
    if (!builderDocument) {
      return [];
    }
    return builderDocument.builderDocumentGuidances;
  }, [builderDocument]);

  const getContentFromGuidance = async (guidancePackage: JSZip, guidanceContentId: string) => {
    let jsonFile = guidancePackage.file(`${guidanceContentId}.json`);
    if (jsonFile) {
      return JSON.parse(await jsonFile.async('string'));
    }
  };

  const getRefFromGuidance = async (guidancePackage: JSZip) => {
    let jsonFile = guidancePackage.file(REFS_FILENAME);
    if (jsonFile) {
      return JSON.parse(await jsonFile.async('string'));
    }
  };

  if (!builderHolderData) {
    return (
      <>
        loading
      </>
    );
  }

  return (
    <Context.Provider 
      value={{
        ...builderHolderData,
        getContentFromGuidance,
        getRefFromGuidance,
        packageByGuidanceId,
        builderDocumentGuidances,
        guidanceIdLookup,
        aliasLookup,
        defaultTakerDocumentSettings
      }}
    >
      {children}
    </Context.Provider>
  );
};
