import React from "react";
import { DialogContentText } from "@mui/material/";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import { MultiStepDialog } from "../../components/dialog/MultiStepDialog";
import { KeyTermsState } from "../../containers/WidgetWrapper/states";

export const GenerateSummariesDialog = () => {
    const { createSummaryAnalyses } = useTakerState();
    const {
        getState,
        mutateState
    } = useWidgetState();

    return (
        <MultiStepDialog
            data-testid="generate-summaries-dialog"
            defaultShowDialog={getState<KeyTermsState>().showGenerationDialog}
            allDialogSteps={[
                {
                    stepId: "confirm",
                    stepElement: (
                        <DialogContentText id="alert-dialog-description">
                            Are you sure?
                        </DialogContentText>
                    )
                }
            ]}
            modalTitle="Generate Summaries"
            onCompleteDialog={() => createSummaryAnalyses()}
            onChangeShowDialog={(v) =>
                mutateState<KeyTermsState>({
                    showGenerationDialog: v
                })
            }
        />
    );
}