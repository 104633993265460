import { Box, TextField } from "@mui/material/";
import React, { useEffect, useState, useRef } from "react";

interface AutoScalingInputProps {
    value: string | undefined;
    onChange: (s: string) => void;
    fontSize?: string;
}

const AutoScalingInput = ({
    value,
    onChange,
    fontSize = "1em",
}: AutoScalingInputProps) => {
    const [width, setWidth] = useState<number>(0);
    const spanElem = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanElem.current) {
            setWidth(spanElem.current.offsetWidth + 30);
        }
    }, [value]);

    const changeHandler = (evt: any) => {
        onChange(evt.target.value);
    };

    return (
        <Box>
            <span
                ref={spanElem}
                style={{
                    position: "absolute",
                    opacity: 0,
                    zIndex: -100,
                    whiteSpace: "pre",
                    fontSize: fontSize
                }}>
                {value}
            </span>
            <TextField
                label=""
                size="small"
                value={value}
                onChange={changeHandler}
                margin="normal"
                sx={{
                    margin: 0
                }}
                InputProps={{
                    style: {
                        fontSize: fontSize,
                        width: width,
                        border: "none",
                        minWidth: "90px"
                    }
                }}
            />
        </Box>
    );
};

export default AutoScalingInput;