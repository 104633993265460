import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridRenderEditCellParams,
    useGridApiContext,
    GridRenderCellParams,
    GridValueGetterParams,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Autocomplete, Chip, IconButton, Stack, TextField } from '@mui/material';
import { StaticChoices, DisplayedVariableReference, DisplayedVariableReferences } from '../../../types/builderv2.generated';
import { ChoiceType } from './RadioQuestion';
import { Choices1 } from '../../../types/taker/fulfillmentstate.generated';

export type PrimitiveType = "string" | "integer" | "date" | "boolean" | "double";

export interface ColumnType {
    dataSpecFieldName: string;
    questionLabel: string;
    questionText: string;
    allowWriteIn?: boolean;
    allowMultiple?: boolean;
    choices?: StaticChoices | DisplayedVariableReference | DisplayedVariableReferences;
    readValueFromCompletionContexts?: boolean;
};

interface TableComponentProps {
    isSaving: boolean;
    dataTypeMap: Record<string, PrimitiveType>;
    renderedQuestionChoices?: {
        dataSpecFieldName: string;
        choices: Choices1[];
        rendered: boolean;
    }[];
    validationWarnings?: string[];
    injectedTableRows?: Record<string, any>[];
    columns: ColumnType[],
    defaultRows: any[][];
    onChangeColumns: (cols: Record<string, any[]>) => void;
    hasToolbarDefaults?: boolean;
    readOnly: boolean;
}

interface AdditionalCustomEditInputCellProps {
    choices?: ChoiceType[];
    allowWriteIn?: boolean;
    allowMutliple?: boolean;
}

function CustomEditInputCell(props: (GridRenderEditCellParams & AdditionalCustomEditInputCellProps)) {
    const {
        id,
        value,
        field,
        description,
        hasFocus,
        choices,
        allowMultiple,
        allowWriteIn
    } = props;

    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLElement>();

    useEffect(() => {
        if (hasFocus && ref.current) {
            const input = ref.current.querySelector<HTMLInputElement>(
                `input[value="${value}"]`,
            );
            input?.focus();
        }
    }, [hasFocus, value]);

    const options = useMemo(
        () => (choices || []).map((c) => ({
            value: c.value,
            label: c.label
        })),
        [choices]
    );

    const valueAsList = useMemo(() => {
        if (!!value) {
            return value.split(',');
        }
        return []
    }, [value]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: "100%" }}>
            <Autocomplete
                fullWidth
                value={allowMultiple ? valueAsList : value}
                multiple={allowMultiple}
                id={`${id}-autocomplete`}
                options={options.map((option) => option.value)}
                freeSolo={allowWriteIn}
                renderOption={(props, option: string) => {
                    const selectedOption = options.find((o) => o.label === option);
                    return (
                        <li {...props}>
                            {!!selectedOption ? selectedOption.value : option}
                        </li>
                    );
                }}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{ outline: "none" }}
                        label=""
                        placeholder={description}
                        onChange={(event) => {
                            // if this isn't a mutiselect, we can update on key stroke
                            if (!allowMultiple) {
                                const selectedOption = options.find((option) => option.label === event.target.value);
                                if (!!selectedOption) {
                                    apiRef.current.setEditCellValue({ id, field, value: selectedOption.value });
                                } else {
                                    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
                                }
                            }
                        }}
                    />
                )}
                onChange={(event, value: string | string[] | null) => {
                    if (!value || typeof value === "string") {
                        apiRef.current.setEditCellValue({ id, field, value: value });
                    } else {
                        apiRef.current.setEditCellValue({
                            id,
                            field,
                            value: value.join(',')
                        });
                    }
                }}
            />
        </Box>
    );
}

const TableComponent = ({
    isSaving,
    dataTypeMap,
    renderedQuestionChoices,
    validationWarnings,
    injectedTableRows,
    columns,
    defaultRows,
    onChangeColumns,
    hasToolbarDefaults = false,
    readOnly
}: TableComponentProps) => {
    const [rows, setRows] = useState<GridRowsProp>([]);

    useEffect(() => {
        const transformedRows = [];
        for (let rowI = 0; rowI < defaultRows.length; rowI++) {
            let row = defaultRows[rowI];
            let tfrRow: Record<string, any> = { id: randomId() };
            let rowColCount = 0;
            for (const col of columns) {
                if (col.readValueFromCompletionContexts) {
                    if (injectedTableRows && injectedTableRows[rowI]) {
                        tfrRow[col.dataSpecFieldName] = injectedTableRows[rowI][col.dataSpecFieldName];
                    } else {
                        tfrRow[col.dataSpecFieldName] = "unknown";
                    }
                } else {
                    tfrRow[col.dataSpecFieldName] = row[rowColCount++];
                }
            }
            transformedRows.push(tfrRow);
        }
        setRows(transformedRows);
    }, [
        defaultRows.length
    ])

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const propagateNewRowsUpdate = (newRows: GridRowModel<any>[]) => {
        setRows(newRows);

        // Transpose rows/cols
        const cols: any[][] = columns.map(() => []);
        for (let i = 0; i < newRows.length; i++) {
            for (let j = 0; j < columns.length; j++) {
                if (!columns[j].readValueFromCompletionContexts) {
                    cols[j].push(newRows[i][columns[j].dataSpecFieldName]);
                }
            }
        }
        
        const columnMap: Record<string, any[]> = {};
        for (let i = 0; i < columns.length; i++) {
            if (!columns[i].readValueFromCompletionContexts) {
                columnMap[columns[i].dataSpecFieldName] = cols[i];
            }            
        }
        onChangeColumns(columnMap);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        propagateNewRowsUpdate(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            propagateNewRowsUpdate(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel<any>) => {
        const updatedRow = { ...newRow, isNew: false };
        propagateNewRowsUpdate(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const getQuestionChoices = (col: ColumnType) => {
        if (!col.choices) {
            return;
        }

        if (renderedQuestionChoices) {
            for (const rqc of renderedQuestionChoices) {
                if (rqc.dataSpecFieldName === col.dataSpecFieldName && rqc.rendered) {
                    return rqc.choices.map(
                        c => ({
                            label: c.label,
                            value: c.value
                        } as ChoiceType)
                    );
                }
            }
        }
        if (col.choices) {
            return (col.choices as StaticChoices).map(
                c => ({
                    label: c.label,
                    value: c.value
                } as ChoiceType)
            );
        }
    };

    const dataGridColumns: GridColDef[] = [];
    for (const col of columns) {
        if (!!col.readValueFromCompletionContexts) {
            dataGridColumns.push({
                flex: 1,
                description: col.questionText,
                field: col.dataSpecFieldName,
                headerName: col.questionLabel,
                editable: false,
                type: 'string'
            });
        } else {
            let field = col.dataSpecFieldName;
            let choices = getQuestionChoices(col);
            if (dataTypeMap[col.dataSpecFieldName] === "string" && (Array.isArray(choices) && choices.length > 0)) {
                if (col.allowMultiple || col.allowWriteIn) {
                    dataGridColumns.push({
                        flex: 1,
                        description: col.questionText,
                        field: field,
                        headerName: col.questionLabel,
                        renderCell: (params: GridRenderCellParams<any, string>) => (
                            <>
                                {params.value}
                            </>
                        ),
                        renderEditCell: (params: GridRenderEditCellParams) => (
                            <CustomEditInputCell
                                {...params}
                                allowMultiple={col.allowMultiple}
                                allowWriteIn={col.allowWriteIn}
                                choices={choices}
                            />
                        ),
                        editable: true,
                        type: "string"
                    });
                } else {
                    dataGridColumns.push({
                        flex: 1,
                        description: col.questionText,
                        field: field,
                        headerName: col.questionLabel,
                        editable: true,
                        type: 'singleSelect',
                        valueOptions: choices.map((c) => ({
                            value: c.value,
                            label: c.label
                        }))
                    });
                }
            } else if (dataTypeMap[col.dataSpecFieldName] === "string") {
                dataGridColumns.push({
                    flex: 1,
                    description: col.questionText,
                    field: field,
                    headerName: col.questionLabel,
                    editable: true,
                    type: 'string'
                });
            } else if (dataTypeMap[col.dataSpecFieldName] === "date") {
                dataGridColumns.push({
                    flex: 1,
                    description: col.questionText,
                    field: field,
                    headerName: col.questionLabel,
                    editable: true,
                    type: 'date',
                    valueGetter: (params: GridValueGetterParams<any, any>) => {
                        return !!params.value ? dayjs(params.value).toDate() : null;
                    },
                });
            } else if (dataTypeMap[col.dataSpecFieldName] === "double" || dataTypeMap[col.dataSpecFieldName] === "integer") {
                dataGridColumns.push({
                    flex: 1,
                    description: col.questionText,
                    field: field,
                    headerName: col.questionLabel,
                    editable: true,
                    type: 'number'
                });
            }
        }
    }

    if (!readOnly) {
        dataGridColumns.push({
            field: 'actions',
            type: 'actions',
            resizable: true,
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <IconButton
                            sx={{
                                color: 'primary.main',
                            }}
                            size="small"
                            disabled={isSaving}
                            onClick={handleSaveClick(id)}
                        >
                            <SaveIcon />
                        </IconButton>,
                        <IconButton
                            size="small"
                            disabled={isSaving}
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        >
                            <CancelIcon />
                        </IconButton>,
                    ];
                }
                return [
                    <IconButton
                        size="small"
                        className="textPrimary"
                        disabled={isSaving}
                        onClick={handleEditClick(id)}
                        color="inherit"
                    >
                        <EditIcon />
                    </IconButton>,
                    <IconButton
                        size="small"
                        disabled={isSaving}
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    >
                        <DeleteIcon />
                    </IconButton>,
                ];
            },
        });
    }

    const addRowButton = (
        <Button
            sx={{
                marginRight: 1,
                float: "right"
            }}
            disabled={readOnly || isSaving}
            size="small"
            color="inherit"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
                setRows((oldRows) => {
                    const newRowI = oldRows.length;
                    const newRow: GridRowModel = { id: randomId(), isNew: true };
                    for (const col of columns) {
                        // Add this read-only value in
                        if (col.readValueFromCompletionContexts) {
                            if (injectedTableRows && injectedTableRows[newRowI]) {
                                newRow[col.dataSpecFieldName] = injectedTableRows[newRowI][col.dataSpecFieldName];
                            } else {
                                newRow[col.dataSpecFieldName] = "unknown";
                            }
                        }
                    }
                    return [...oldRows, newRow];
                });
            }}
        >
            Row
        </Button>
    );

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <Stack>
                {(!!validationWarnings && validationWarnings.length > 0) && (
                    <Alert severity="warning" sx={{ marginBottom: "10px" }}>
                        {validationWarnings.map(w => <div>* {w}</div>)}
                    </Alert>
                )}
            </Stack>
            <DataGrid
                rows={rows}
                columns={dataGridColumns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) => {
                    //console.log(error);
                }}
                hideFooter
                autoHeight
                disableColumnMenu
                isCellEditable={() => !readOnly}
                slots={{
                    toolbar: () => (
                        <GridToolbarContainer>
                            {addRowButton}
                        </GridToolbarContainer>
                    )
                }}
            />
        </div>
    );
}

export default TableComponent;
