import { blue } from '@mui/material/colors';
import React, { memo } from 'react';
import { EdgeLabelRenderer, SmoothStepEdgeProps, getSmoothStepPath } from 'reactflow';

const CustomEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    label,
    data
}: SmoothStepEdgeProps) => {
    const isActive = data.isActive;
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition
    });

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            {label ? (
                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            background: (isActive ? blue[400] : "white"),
                            padding: 5,
                            borderRadius: 5,
                            border: (isActive ? "none" : "1px solid #ddd"),
                            fontSize: 12,
                            fontWeight: 500,
                            color: (isActive ? "white" : "black"),
                        }}
                        className="nodrag nopan"
                    >
                        {label}
                    </div>
                </EdgeLabelRenderer>
            ) : null}
        </>
    );
};

export default memo(CustomEdge);
