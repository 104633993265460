import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import AddAnnotationPlugin from '../../../components/lexical/plugins/AddAnnotationPlugin';
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import React, { useEffect, useRef } from 'react';
import { LexicalEditor, TextNode, $getRoot } from "lexical";
import FloatingAnnotationToolbarPlugin from "../../../components/lexical/plugins/FloatingAnnotationToolbarPlugin";
import { ExtendedMarkNode } from "../../../components/lexical/nodes/ExtendedMarkNode";
import EditAnnotationPlugin from "../../../components/lexical/plugins/EditAnnotationPlugin";
import { DocumentAnnotation } from "../../../types/taker/documentkeyterms.generated";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import EditorThemeClasses from "../../../components/lexical/wrappers/RichTextEditor/theme";
import { RawHtmlNode } from "../../../components/lexical/nodes/RawHtmlNode";

const ForceUpdatePlugin = ({
    lexicalDocumentIdentifier,
    lexicalPage
}: {
    lexicalDocumentIdentifier: string;
    lexicalPage?: Object,
}) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (lexicalPage) {
            const editorState = editor.parseEditorState(JSON.stringify(lexicalPage));
            if (!editorState.isEmpty()) {
                editor.setEditorState(editorState);
            } else {
                editor.update(() => {
                    const root = $getRoot();
                    root.clear();
                });
                console.warn(`editorState was empty for page with id=${lexicalDocumentIdentifier}, page=${lexicalPage}`);
            }
        }
    }, [lexicalDocumentIdentifier])
    return null;
};

interface PageEditorProps {
    readOnly: boolean;
    lexicalDocumentIdentifier: string;
    pageIndex: number;
    lexicalPage?: Object,
    annotations: DocumentAnnotation[];
    pageScaleFactor: number;
    isV2Render: boolean;
}

const PageEditor = ({
    readOnly,
    lexicalDocumentIdentifier,
    pageIndex,
    lexicalPage,
    annotations,
    pageScaleFactor,
    isV2Render
}: PageEditorProps) => {
    const { documentKeyTermsService } = useKeyTermGroupState();
    const localLexicalPage = useRef<string>();
    
    const initialConfig = {
        editorState: (editor: LexicalEditor) => {
            // TODO: needs to be re initialized when the identifier changes
            if (lexicalPage) {
                const editorState = editor.parseEditorState(JSON.stringify(lexicalPage));
                if (!editorState.isEmpty()) {
                    editor.setEditorState(editorState);
                } else {
                    console.warn(`editorState was empty for page with index=${pageIndex}`);
                }
            }
        },
        editable: false,
        namespace: "main",
        onError(error: any) {
            throw error;
        },
        nodes: [RawHtmlNode]
    } as InitialConfigType;

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <ForceUpdatePlugin 
                lexicalDocumentIdentifier={lexicalDocumentIdentifier}
                lexicalPage={lexicalPage}
            />
            <PlainTextPlugin
                contentEditable={
                    <ContentEditable />
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin
                onChange={(editorState, editor) => {
                    editorState.read(() => {
                        const jsonString = JSON.stringify(editorState);
                        localLexicalPage.current = jsonString;
                    });
                }}
            />
            <AddAnnotationPlugin
                annotations={annotations}
                onAddAnnotation={(termNames, annotation) => {
                    // 100 ms delay so the OnChangePlugin plugin is guarenteed to update
                    // localLexicalPage before the callback.
                    setTimeout(() => {
                        if (localLexicalPage.current) {
                            documentKeyTermsService.updateLexicalDocumentPage(
                                lexicalDocumentIdentifier,
                                pageIndex,
                                JSON.parse(localLexicalPage.current)
                            );
                            documentKeyTermsService.addDocumentAnnotation(termNames, annotation);
                        }
                    }, 100);
                }}
                pageIndex={pageIndex}
                pageScaleFactor={pageScaleFactor}
            />
            <EditAnnotationPlugin
                readOnly={readOnly}
                onUpdateAnnotation={documentKeyTermsService.bulkUpdateDocumentAnnotations}
            />
            <FloatingAnnotationToolbarPlugin
                readOnly={readOnly}
                annotations={annotations}
                isV2Render={isV2Render}
            />
        </LexicalComposer>
    );
}

export default PageEditor;