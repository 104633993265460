import React, { useEffect, useMemo, useState } from "react";
import KeyTermGroup from "./KeyTermGroup";
import { Box } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import WidgetWrapper from "../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../containers/WidgetWrapper/states";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainToolbar from "./MainToolbar";

const buildInitialContentFilteringState = (savedCfState: string | null) => {
    const cfState: KeyTermsState = (!!savedCfState && JSON.parse(savedCfState)) || {
        groupManagerOpen: false,
        targetTakerDocumentUploadId: null,
        showAiSummaries: false,
        showGenerationDialog: false,
        panelViewMode: 0,
        activeAnnotationIds: []
    };
    return cfState;
};

const TakerKeyTerms = () => {
    const { takerPermissionState, taker, takerDocumentId } = useTakerState();
    const navigate = useNavigate();

    const [widgetState, setWidgetState] = useState<KeyTermsState>(
        buildInitialContentFilteringState(localStorage.getItem(`KeyTermsState-${takerDocumentId}`))
    );

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    /**
     * Sync widgetState with localStorage.
    */
    useEffect(() => {
        if (!!widgetState) {
            localStorage.setItem(`KeyTermsState-${takerDocumentId}`, JSON.stringify(widgetState));
        }
    }, [widgetState]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                height: '100%',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    width: '100%'
                }}
            >
                <TakerToolbar
                    data-testid="taker-toolbar"
                    onClickForward={() => {
                        if (taker) {
                            navigate(`/mainTaker/${taker.id}/analysis`);
                        }
                    }}
                    workflowDisplay={"Key Terms"}
                    workflowDescription={`Please upload a document to begin annotating.`}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    overflow: 'hidden'
                }}
                flexGrow={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <WidgetWrapper
                        widgetState={widgetState}
                        setPartialWidgetState={(s) => {
                            setWidgetState(Object.assign({}, widgetState, s)); 
                        }}
                    >
                        <Box 
                            paddingTop={1}
                            paddingLeft={1}
                            paddingRight={1}
                        >
                            <MainToolbar data-testid="main-toolbar" readOnly={isReadOnly}/>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                overflow: 'hidden'
                            }}
                            flexGrow={1}
                        >
                            <KeyTermGroup data-testid="key-term-group" readOnly={isReadOnly} />
                        </Box>
                    </WidgetWrapper>
                </Box>
            </Box>
        </Box>
    );
}

export default TakerKeyTerms;
