import { Box, TextField } from "@mui/material";

interface EditableSummaryV2Props {
    triggerEdit: boolean;
    defaultValue: string;
    expandSummary: boolean;
    onChangeValue: (s: string) => void;
    readOnly: boolean;
}

const EditableSummaryV2 = (
{
    triggerEdit,
    defaultValue,
    expandSummary,
    onChangeValue,
    readOnly
}: EditableSummaryV2Props) => {
    return (
        <Box
            sx={{
                width: "100%",
                display: "inline-flex",
                alignItems: "center"
            }}
        >
            {triggerEdit ? (
                <TextField
                    data-testid="editable-summary-text"
                    id="editable-summary-text"
                    size="medium"
                    value={defaultValue}
                    onChange={(e: any) => {
                        if (!readOnly) {
                            onChangeValue(e.target.value);
                        }
                    }}
                    variant="outlined"
                    multiline
                    fullWidth
                    InputProps={{
                        readOnly,
                        autoComplete: "off"
                    }}
                />
            ) : (
                <Box
                    padding={1}
                    paddingLeft={2}
                >
                    <em>
                        {expandSummary ?
                            (defaultValue || "none") :
                            `${(defaultValue || "none").substring(0, 40)}...`
                        }
                    </em>
                </Box>
            )}
        </Box>
    );
}

export default EditableSummaryV2;