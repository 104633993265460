import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
    Grid,
    List,
    ToggleButton,
    Tooltip,
} from "@mui/material/";
import { Add, CloseFullscreen, Delete, OpenInFull } from "@mui/icons-material";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import KeyTermListItem from "./KeyTermListItem";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import DeleteWithConfirmationButton from "../../../components/buttons/DeleteWithConfirmationButton";

interface KeyTermsProps {
    isReadOnly: boolean;
};

const KeyTerms = ({
    isReadOnly
}: KeyTermsProps) => {
    const { 
        documentKeyTermsService, 
        takerDocumentUpload,
        isKeyTermGroupStateDirty 
    } = useKeyTermGroupState();
    const { mutateState, getState } = useWidgetState();

    const [keyTermSearchName, setKeyTermSearchName] = useState<string>("");

    const showExpandedSummaries = getState<KeyTermsState>().showExpandedSummaries;

    const uniqueNewKeyTerm = () => {
        const baseNewKeyTerm: string = "key term"
        let numNew: number = 1;
        while(documentKeyTermsService.containsKeyTerm(baseNewKeyTerm + " " + numNew)) {
            numNew += 1;
        }
        return baseNewKeyTerm + " " + numNew;
    }
    
    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                height: '100%',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    width: '100%'
                }}
                paddingBottom={1}
            >
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid 
                        item
                        xs={6}
                        alignItems="center"
                    >
                        <TextField
                            id="key-term-search"
                            size="small"
                            value={keyTermSearchName}
                            placeholder="Search Key Terms"
                            onChange={(e: any) => {
                                setKeyTermSearchName(e.target.value);
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid 
                        item
                        container
                        xs={6}
                        columnSpacing={1}
                        justifyContent="end"
                        paddingRight={1}
                    >
                        <Grid item alignItems="center">
                            <Tooltip title={"Expand Summaries"}>
                                <ToggleButton
                                    value="check"
                                    size="small"
                                    aria-label="Expand Summaries"
                                    selected={showExpandedSummaries}
                                    onChange={() =>
                                        mutateState<KeyTermsState>({
                                            showExpandedSummaries: !showExpandedSummaries
                                        })
                                    }
                                    sx={{
                                        alignItems: "center"
                                    }}
                                >
                                    {showExpandedSummaries ? 
                                        <CloseFullscreen fontSize="small" /> :
                                        <OpenInFull fontSize="small" /> 
                                    }
                                </ToggleButton>
                            </Tooltip>
                        </Grid>
                        <Grid item alignItems="center">
                            <Button
                                data-testid="create-key-term-button"
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={() =>
                                    documentKeyTermsService.createNewKeyTerm(uniqueNewKeyTerm())
                                }
                            >
                                key term
                            </Button>
                        </Grid>
                        <Grid item alignItems="center">
                            <DeleteWithConfirmationButton
                                disabled={isKeyTermGroupStateDirty}
                                onDelete={() => documentKeyTermsService.removeAllKeyTerms()}
                                confirmationText="Are you sure you want to delete all key terms in this group? This will also remove all of your highlights."
                                useDefaultButtonStyle={true}
                                defaultButtonText="Delete All Key Terms"
                                outlined={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    overflow: 'auto'
                }}
                flexGrow={1}
            >
                <List disablePadding>
                    {(documentKeyTermsService.keyTerms) && (
                        documentKeyTermsService.keyTerms.map((keyTerm, index) => (
                            keyTerm.termName.toLowerCase().indexOf(keyTermSearchName.toLowerCase()) !== -1 && (
                                <KeyTermListItem
                                    data-testid={`key-term-list-item-${index}`}
                                    takerDocumentUploadId={takerDocumentUpload?.id}
                                    keyTerm={keyTerm}
                                    myIndex={index}
                                    totalLength={documentKeyTermsService.keyTerms?.length}
                                    readOnly={isReadOnly}
                                />
                            )
                        ))
                    )}
                </List>
            </Box>
        </Box>
    );
};

export default KeyTerms;