import {
    Box,
    Grid,
    Typography,
    Button
} from "@mui/material";
import React, { useMemo } from "react";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { GraphFulfillment } from "../../../types/builderv2.generated";
import { useReadOnlyBuilderData } from "../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData";

interface HasManyModuleStartProps {
    modulePrefixes: string[];
    praxiModuleId: string;
    fulfillment: GraphFulfillment;
};

const HasManyModuleStart = ({
    modulePrefixes,
    praxiModuleId,
    fulfillment
}: HasManyModuleStartProps) => {
    const {
        activeTargetTakerState,
        questionnareDataService,
        takerOutput,
        getVariableValue,
        isTakerStateDirty
    } = useTakerState();
    const { builderHolder } = useReadOnlyBuilderData();

    let moduleIds = useMemo(() => {
        let mids = [];
        mids.push(...modulePrefixes);
        mids.push(praxiModuleId);
        return mids;
    }, [praxiModuleId, modulePrefixes]);

    const actualItems: number | undefined = useMemo(() =>
        activeTargetTakerState && activeTargetTakerState.count(moduleIds),
        [activeTargetTakerState, moduleIds]
    );

    const expectedItems: number | undefined = useMemo(() => {
        if (fulfillment.completionContexts && activeTargetTakerState) {
            for (const cc of fulfillment.completionContexts) {
                if (cc.completionContextType === "number-iterations") {
                    let numIterationsValue = getVariableValue(modulePrefixes, cc.variableReference);
                    if (typeof numIterationsValue === "number") {
                        return numIterationsValue;
                    } else {
                        return numIterationsValue ? parseInt(numIterationsValue) : 0;
                    }
                } else if (cc.completionContextType === "variable-injected-loop-context") {
                    let table = getVariableValue(modulePrefixes, cc.variableReference);
                    if (!!table) {
                        return table.data.length;
                    }
                } 
            }
        }
    }, [activeTargetTakerState, module, modulePrefixes, takerOutput])

    const actionElement = useMemo(() => {
        if (expectedItems !== undefined && actualItems !== undefined && expectedItems !== actualItems) {
            if (expectedItems > actualItems) {
                return (
                    <Button
                        data-testid={`add-iteration-${praxiModuleId}`}
                        size="small"
                        color="inherit"
                        variant="outlined"
                        disabled={isTakerStateDirty}
                        onClick={() => {
                            let toAddNum = expectedItems - actualItems;
                            questionnareDataService.addIterations(moduleIds, toAddNum);
                        }}
                    >
                        please click here to add new iterations
                    </Button>
                );
            } else {
                let toDeleteNum = actualItems - expectedItems;
                return (
                    <Typography variant="subtitle1" color="red">
                        {`please delete ${toDeleteNum} of the iterations`}
                    </Typography>
                );
            }
        } else if (expectedItems === undefined) {
            return (
                <Button
                    data-testid="add-iteration"
                    size="small"
                    color="inherit"
                    variant="outlined"
                    disabled={isTakerStateDirty}
                    onClick={() => {
                        questionnareDataService.addIterations(moduleIds, 1);
                    }}
                >
                    add iteration
                </Button>
            );
        }
    }, [actualItems, expectedItems, isTakerStateDirty]);

    const requiresAttention = useMemo(() => 
        (expectedItems !== undefined && actualItems !== undefined && expectedItems !== actualItems), 
        [actualItems, expectedItems]
    );
    
    const praxiModuleDisplayName = useMemo(() => {
        const praxiModule = builderHolder.getModule(moduleIds);
        if (!praxiModule) {
            return "unknown";
        }
        return praxiModule.displayName;
    }, [builderHolder, moduleIds]);
    
    return (
        <Box
            sx={{
                marginBottom: "1%",
                padding: "1%",
                backgroundColor: requiresAttention ?  "rgb(237, 108, 2, 0.25)" : "rgb(25, 118, 210, 0.25)",
                borderRadius: 1
            }}
        >
            <Grid container alignItems="center" justifyContent="space-between" columnSpacing={2}>
                <Grid item>
                    <Typography variant="h6">
                        {`Start ${praxiModuleDisplayName}`}
                    </Typography>
                </Grid>
                <Grid item>
                    {actionElement}
                </Grid>
            </Grid>
        </Box>
    );
};

export default HasManyModuleStart;