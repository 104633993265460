import { WidgetsSharp } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#e4e4e4",
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

export default theme;