import { 
    Box, 
    Chip, 
    Grid, 
    Typography 
} from "@mui/material";
import { useMemo } from "react";
import { useReadOnlyBuilderData } from "../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData";
import { GraphFulfillmentState } from "../../../types/taker/fulfillmentstate.generated";

interface EndingNodeProps {
    graphFulfillmentState: GraphFulfillmentState;
    nodeId: string;
    moduleIds: string[];
    iteration: number | null;
    statement?: string;
    label?: string;
}

const EndingNode = ({ 
    graphFulfillmentState,
    nodeId,
    moduleIds,
    iteration,
    statement,
    label
}: EndingNodeProps) => {
    const { builderHolder } = useReadOnlyBuilderData();

    const finalStatement = useMemo(() => {
        for (const entry of graphFulfillmentState.renderedText) {
            if (entry.nodeId === nodeId && entry.rendered) {
                return entry.qText;
            }
        }
        let trimmed = statement?.trim();
        return (!trimmed || trimmed === "") ? null : trimmed;
    }, [graphFulfillmentState, nodeId, statement]);

    const endingText = useMemo(() => {
        if (label) {
            return label;
        }

        const praxiModule = builderHolder.getModule(moduleIds);
        if (!praxiModule) {
            return "unknown";
        }

        if (iteration !== null) {
            return `End iteration ${iteration + 1} of ${praxiModule.displayName}`;
        }
        return `End ${praxiModule.displayName}`;
    }, [builderHolder, moduleIds, iteration]);

    let backgroundColor = (iteration === null) ? "rgb(25, 118, 210, 0.25)" : "rgb(25, 118, 210, 0.05)";

    // Only show an end node when either:
    // 1) There is a specific statement programmed or
    // 2) this is an iteration

    if (!finalStatement && iteration === null) {
        return null;
    } 

    return (
        <Box
            sx={{
                marginBottom: "1%",
                padding: "1%",
                backgroundColor: backgroundColor,
                borderRadius: 1
            }}
        >
            <Grid 
                container 
                justifyContent="flex-start"
                alignItems="center" 
                columnSpacing={2}
                rowSpacing={2}
            >
                <Grid item alignItems="center">
                    <Chip label={endingText}/>
                </Grid>
                {finalStatement && (
                    <Grid 
                        item 
                        alignItems="center"
                    >
                        <Typography 
                            variant="subtitle1"
                        >
                            {finalStatement}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>           
    );
};

export default EndingNode;