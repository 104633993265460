import React from "react";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot, LexicalEditor, TextNode } from "lexical";

interface CommentProps {
    lexical?: Object,
    onUpdate?: (l: Object, t: string) => void;
}

const Comment = ({
    lexical,
    onUpdate,
}: CommentProps) => {
    let initialConfig = {
        editorState: (editor: LexicalEditor) => {
            if (lexical) {
                console.log("initializing from lexical");
                const editorState = editor.parseEditorState(JSON.stringify(lexical));
                if (!editorState.isEmpty()) {
                    editor.setEditorState(editorState);
                }
            }
        },
        editable: !!onUpdate,
        namespace: "main",
        onError(error: any) {
            throw error;
        },
        nodes: [TextNode]
    } as InitialConfigType;

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <PlainTextPlugin
                contentEditable={
                    <ContentEditable style={{ outline: 0, paddingTop: 0 }} />
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin
                onChange={(editorState, editor) => {
                    editorState.read(() => {
                        if (!!onUpdate) {
                            onUpdate(
                                JSON.parse(JSON.stringify(editorState)),
                                $getRoot().getTextContent()
                            );
                        }
                    });
                }}
            />
        </LexicalComposer>
    );
}

export default Comment;
