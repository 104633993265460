import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import KeyTermGroupState, { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';
import { TakerDocumentUpload } from '../../../../redux/models/dataModelTypes';
import { KeyTerm } from '../../../../types/taker/documentkeyterms.generated';
import { Add } from '@mui/icons-material';
import { KeyTermSource } from '../../../../types/taker/uidatastate.generated';

interface PartialKeyTerm {
    id: string;
    name: string;
}

const KeyTermsList = () => {
    const {
        documentKeyTermsService,
        lastSavedTimestamp
    } = useKeyTermGroupState();

    const allKeyTerms = useMemo(() =>
        documentKeyTermsService?.keyTerms || [],
        [lastSavedTimestamp, documentKeyTermsService]
    );

    return (
        <Box paddingTop={1}>
            <Stack>
                {allKeyTerms.map(kt => (
                    <>
                        <Typography variant="subtitle2">
                            {kt.termName}
                        </Typography>
                        <Typography paragraph>
                            {kt.summary}
                        </Typography>
                    </>
                ))}
            </Stack>
        </Box>
    );
};

interface ResearchKeyTermsProps {
}

const ResearchKeyTerms = ({
}: ResearchKeyTermsProps) => {
    const [keyTermGroupId, setKeyTermGroupId] = useState<string | undefined>();
    const { activeTakerDocument } = useTakerState();

    const allKeyTermGroups = useMemo(() =>
        activeTakerDocument?.takerDocumentUploads || [],
        [activeTakerDocument]
    );

    return (
        <Stack>
            <Stack direction="row" spacing={1}>
                <FormControl sx={{ minWidth: 120 }}>
                    <Select
                        size="small"
                        displayEmpty
                        data-testid="key-term-group-name-select"
                        value={keyTermGroupId}
                        onChange={(event: SelectChangeEvent<string>) => {
                            setKeyTermGroupId(event.target.value);
                        }}
                        label=""
                    >
                        <MenuItem value={undefined}>
                            Select a Group
                        </MenuItem>
                        {allKeyTermGroups.map((ktg: TakerDocumentUpload) => (
                            <MenuItem value={ktg.id}>
                                {ktg.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Box position="relative">
                {keyTermGroupId && (
                    <KeyTermGroupState takerDocumentUploadId={keyTermGroupId}>
                        <KeyTermsList />
                    </KeyTermGroupState>
                )}
            </Box>
        </Stack>
    );
}

export default memo(ResearchKeyTerms);