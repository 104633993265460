import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { ButtonGroup, DialogActions, DialogContent } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useWidgetState } from '../../../containers/WidgetWrapper/wrapper';
import { useReadOnlyBuilderData } from '../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData';
import { AnalysisState } from '../../../containers/WidgetWrapper/states';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';


export default function IterationChooserDialog() {
    const [open, setOpen] = useState<boolean>(false);
    const { builderHolder } = useReadOnlyBuilderData();
    const { getState, mutateState } = useWidgetState();
    const { fulfillmentStateHolder } = useTakerState();

    const mid = getState<AnalysisState>().targetMid;
    const selectedIteration = getState<AnalysisState>().selectedIteration;

    useEffect(() => {
        if (mid && builderHolder.moduleRequiresIterations(mid.split('_'))) {
            if (selectedIteration === null) {
                setOpen(true);
            }
        }
    }, [mid, selectedIteration, builderHolder]);

    const numIterations = useMemo(() => {
        if (mid) {
            let moduleIds = mid.split('_');
            if (fulfillmentStateHolder && moduleIds.length > 0) {
                let c = fulfillmentStateHolder.count(moduleIds);
                if (c !== undefined) {
                    return c;
                }
            }
        }
        return 0;
    }, [fulfillmentStateHolder, mid]);

    const fulfilled = useMemo(() => {
        if (mid) {
            let moduleIds = mid.split('_');
            if (fulfillmentStateHolder && moduleIds.length > 0) {
                return fulfillmentStateHolder.getSubstateFulfilled(moduleIds);
            }
        }
    }, [fulfillmentStateHolder, mid]);

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Choose Iteration</DialogTitle>
            <DialogContent>
                {Array.isArray(fulfilled) && (
                    <ButtonGroup orientation="vertical" variant="contained" aria-label="Basic button group">
                        {[...Array(numIterations).keys()].map((i) => (
                            <Button
                                data-testid={`iteration-button-${i}`}
                                variant="outlined"
                                color={fulfilled[i] ? "info" : "error"}
                                key={i}
                                onClick={() => {
                                    mutateState<AnalysisState>({
                                        selectedIteration: i,
                                        selectedMid: mid,
                                        targetMid: undefined
                                    });
                                    setOpen(false);
                                }}
                            >
                                {fulfilled[i] ? 
                                    `Iteration ${(i + 1)}` :
                                    `Iteration ${(i + 1)} (incomplete)`
                                }
                            </Button>
                        ))}
                    </ButtonGroup>
                )}
            </DialogContent>
            <DialogActions>                
                <Button onClick={() => setOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}