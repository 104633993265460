import React, { useCallback, useEffect, useRef, useState } from "react";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import PageEditor from "./PageEditor";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { LexicalDocument } from "../../../types/taker/documentkeyterms.generated";
import { Box, Button, ButtonGroup, Chip, Grid, IconButton } from "@mui/material";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { Document, Page } from 'react-pdf';
import {
    TransformWrapper,
    TransformComponent,
    useControls,
} from "react-zoom-pan-pinch";
import { elementScroll, useVirtualizer } from '@tanstack/react-virtual'
import { ZoomIn, ZoomOut, ZoomOutMap } from "@mui/icons-material";

import type { VirtualizerOptions } from '@tanstack/react-virtual'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

function easeInOutQuint(t: number) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
}

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
    withCredentials: true
};

interface ControlsProps {
    enableZoom: boolean;
}

const Controls = ({
    enableZoom
}: ControlsProps) => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
        <Grid
            sx={{
                width: "100%",
                position: 'absolute',
                zIndex: 1
            }}
            container
            alignItems="center"
            columnSpacing={1}
        >
            <Grid item>
                <ButtonGroup
                    size="small"
                    color="inherit"
                    variant="text"
                >
                    {enableZoom && (
                        <>
                            <Button
                                onClick={() => zoomIn()}
                            >
                                <ZoomIn />
                            </Button>
                            <Button
                                onClick={() => zoomOut()}
                            >
                                <ZoomOut />
                            </Button>
                        </>
                    )}
                    <Button
                        onClick={() => resetTransform()}
                    >
                        <ZoomOutMap />
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

const ASPECT_RATIO_SCALE_MULTIPLIER = .80;
const DOCUMENT_MAX_WIDTH = 1200;
const ACTIVATION_KEYS = ['Meta', 'Shift'];

interface PagesEditorInnerProps {
    readOnly: boolean;
    lexicalDocument: LexicalDocument;
    parentDiv: HTMLDivElement;
    setViewPageWarnings: (n: number) => void;
    documentWidth: number;
}

const PagesEditorInner = ({
    readOnly,
    lexicalDocument,
    parentDiv,
    setViewPageWarnings,
    documentWidth
}: PagesEditorInnerProps) => {
    const {
        documentKeyTermsService,
        isKeyTermsDataLoading,
        isKeyTermGroupStateDirty
    } = useKeyTermGroupState();
    const { getState } = useWidgetState();
    const [resizeJob, setResizeJob] = useState<any>();
    const panelViewMode = getState<KeyTermsState>().panelViewMode;
    const scrollToPage = getState<KeyTermsState>().scrollToPage;
    const scrollingRef = useRef<number>();

    const getHeight = (thisWidth: number, index: number) => {
        const meta = lexicalDocument.pageMetadata[index] as Record<string, number>;
        if (meta) {
            let ogWidth = meta['original_page_width'];
            let ogHeight = meta['original_page_height'];
            let isVersion2 = !!meta['is_version_2'];
            if (isVersion2) {
                return (ogHeight * (thisWidth / ogWidth)) + 25;
            }
            return ((ogHeight * (thisWidth / ogWidth)) / ASPECT_RATIO_SCALE_MULTIPLIER) + 25;
        }
        return 0;
    }

    const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback((offset, canSmooth, instance) => {
        const duration = 1000
        const start = parentDiv.scrollTop || 0
        const startTime = (scrollingRef.current = Date.now())
        const run = () => {
            if (scrollingRef.current !== startTime) return
            const now = Date.now()
            const elapsed = now - startTime
            const progress = easeInOutQuint(Math.min(elapsed / duration, 1))
            const interpolated = start + (offset - start) * progress
            if (elapsed < duration) {
                elementScroll(interpolated, canSmooth, instance)
                requestAnimationFrame(run)
            } else {
                elementScroll(interpolated, canSmooth, instance)
            }
        }
        requestAnimationFrame(run)
    }, []);

    const rowVirtualizer = useVirtualizer({
        count: lexicalDocument.lexicalPages.length,
        getScrollElement: () => parentDiv,
        estimateSize: (index) => getHeight(documentWidth, index),
        overscan: 5,
        scrollToFn
    });

    const resizeAllHeights = useCallback(() => {
        for (let i = 0; i < lexicalDocument.lexicalPages.length; i++) {
            rowVirtualizer.resizeItem(i, getHeight(parentDiv.offsetWidth, i));
        }
    }, [documentWidth]);

    useEffect(() => {
        if (panelViewMode === 0 || panelViewMode == 2) {
            resizeAllHeights();
        }
    }, [panelViewMode, documentWidth]);

    useEffect(() => {
        const onResize = () => {
            if (resizeJob) {
                clearTimeout(resizeJob);
            }
            setResizeJob(setTimeout(() => resizeAllHeights(), 50));
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    useEffect(() => {
        if (scrollToPage !== undefined && !isKeyTermsDataLoading && !isKeyTermGroupStateDirty) {
            rowVirtualizer.scrollToIndex(scrollToPage);
        }
    }, [scrollToPage]);

    if (!lexicalDocument || lexicalDocument.lexicalPages.length === 0) {
        return (
            <div
                style={{
                    width: "100%"
                }}
            >
                <em>document is blank or not available</em>
            </div>
        );
    }

    return (
        <TransformWrapper
            wheel={{ activationKeys: ACTIVATION_KEYS }}
            panning={{ activationKeys: ACTIVATION_KEYS }}
            doubleClick={{ disabled: true }}
        >
            <Controls enableZoom={false}/>
            <TransformComponent>
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: parentDiv.offsetWidth,
                        position: 'relative'
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const meta = lexicalDocument.pageMetadata[virtualRow.index] as Record<string, number>;
                        if (meta) {
                            let ogWidth = meta['original_page_width'];
                            let isVersion2 = !!meta['is_version_2'];
                            return (
                                <div
                                    key={virtualRow.index}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: `${virtualRow.size}px`,
                                        transform: `translateY(${virtualRow.start}px)`,
                                    }}
                                >
                                    <PageEditor
                                        readOnly={readOnly}
                                        lexicalDocumentIdentifier={lexicalDocument.identifier}
                                        pageIndex={virtualRow.index}
                                        lexicalPage={lexicalDocument.lexicalPages[virtualRow.index]}
                                        annotations={
                                            documentKeyTermsService.getAnnotations(
                                                lexicalDocument.identifier,
                                                virtualRow.index
                                            ) || []
                                        }
                                        pageScaleFactor={(parentDiv.offsetWidth / ogWidth)}
                                        isV2Render={isVersion2}
                                    />
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            width: "100%",
                                            zIndex: 1000,
                                            textAlign: "center",
                                            bottom: 0,
                                            left: 0,
                                            height: '25px'
                                        }}
                                    >
                                        <Chip
                                            label={`page ${(virtualRow.index + 1)}`}
                                            size="small"
                                            onClick={() => setViewPageWarnings(virtualRow.index)}
                                        />
                                    </Box>
                                </div>
                            );
                        }
                    })}
                </div>
            </TransformComponent>
        </TransformWrapper>
    );
}

interface PagesEditorProps {
    readOnly: boolean;
    documentWidth: number;
    lexicalDocument: LexicalDocument;
}

const PagesEditor = ({
    readOnly,
    documentWidth,
    lexicalDocument
}: PagesEditorProps) => {
    const { takerDocumentUpload } = useKeyTermGroupState();
    const { getState } = useWidgetState();
    const [viewPageWarnings, setViewPageWarnings] = useState<number | null>(null);
    const targetFileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;
    const [parentDiv, setParentDiv] = useState<HTMLDivElement | null>(null);

    const realDocumentWidth = (documentWidth >= DOCUMENT_MAX_WIDTH) ? DOCUMENT_MAX_WIDTH : documentWidth;

    return (
        <>
            <div
                ref={(r) => setParentDiv(r)}
                style={{
                    width: `${realDocumentWidth}px`,
                    margin: "0 auto",
                    height: "100%",
                    overflowX: "hidden",
                }}
            >
                {parentDiv && (
                    <PagesEditorInner
                        readOnly={readOnly}
                        lexicalDocument={lexicalDocument}
                        parentDiv={parentDiv}
                        setViewPageWarnings={setViewPageWarnings}
                        documentWidth={realDocumentWidth}
                    />
                )}
            </div>
            <SimpleModalWrapper
                headerText=""
                open={viewPageWarnings !== null}
                handleClose={() => setViewPageWarnings(null)}
                sx={{ width: "70vw", padding: "16px 24px 24px 24px" }}
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '3%',
                        alignContent: "center"
                    }}
                >
                    {viewPageWarnings !== null && (
                        <Document
                            file={`${window.__RUNTIME_CONFIG__.API_ENDPOINT}/v1/file_uploads/${takerDocumentUpload?.fileUpload.id}/items/${targetFileUploadItemId}/content`}
                            options={options}
                        >
                            <Page
                                key={`page_${viewPageWarnings + 1}`}
                                pageNumber={viewPageWarnings + 1}
                            />
                        </Document>
                    )}
                </Box>
            </SimpleModalWrapper>
        </>
    );
}

export default PagesEditor;
