import { Box, CircularProgress } from "@mui/material";

type Props = {};

export default function DefaultLoadingScreen({}: Props) {
  return (
    <Box 
      data-testid="default-loading-screen"
      sx={{ 
        height: "100vh",
        display: "grid",
        placeItems: "center"
      }}>
      <CircularProgress size={60}/>
    </Box>
  );
}
