import React from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Box, Button } from "@mui/material/";
import * as yup from "yup";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { MultiSelectChipsAutoCompRhf } from "../../components/form/reactHookForm/multiSelectChipAutoCompRhf";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useUpdateUserMutation } from "../../redux/services/user";

interface formInputs {
  firstName: string;
  lastName: string;
  roles: Array<any>;
}

interface Props {
  user: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}  

const userRoles = [
  { "id": "BUILDER", "display": "Builder" },
  { "id": "TAKER", "display": "Taker" },
  { "id": "TECHNICAL", "display": "Technical" },
  { "id": "ADMIN", "display": "Admin" },
  { "id": "SUPERADMIN", "display": "Superadmin" },
  { "id": "INDEXER", "display": "Indexer" },
  { "id": "DEBUGGER", "display": "Debugger" }   
];

export const UpdateUserModal = ({ user, open, setOpen }: Props) => {
  const [updateUser, updateUserResult] = useUpdateUserMutation();

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    roles: yup.array().required(),
  });

  const getDisplayForUserRole = (id: string) => {
    for (const ur of userRoles) {
      if (ur.id === id.trim()) {
        return ur.display;
      }
    }
  };

  const defaultValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    roles: user.roles.map((r: any) => ({
      id: r,
      display: getDisplayForUserRole(r)
    })),
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitCustom: SubmitHandler<any> = (d) => {
    updateUser({
      id: user.id,
      roles: d.roles.map((r: any) => r.id),
      firstName: d.firstName,
      lastName: d.lastName
    });
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<formInputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });


  return (
    <SimpleModalWrapper
      headerText="Update User"
      open={open}
      handleClose={handleClose}
      sx={{ width: "30vw", height: "auto", padding: "16px 24px 16px 24px" }}
    >
      <Box>
        <form
          onSubmit={handleSubmit(submitCustom)}
          style={{
            padding: "16px 0px 16px 0px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <TextFieldRhf
            label="First Name"
            fieldName="firstName"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <TextFieldRhf
            label="Last Name"
            fieldName="lastName"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <MultiSelectChipsAutoCompRhf
            options={userRoles}
            label="User Roles"
            placeholder="role"
            fieldName="roles"
            control={control}
            errors={errors}
            sx={{ minHeight: "71px" }}
          />
          <Box>
            <Button variant="contained" type="submit" sx={{ width: "30%" }}>
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ width: "30%", marginLeft: "30px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </SimpleModalWrapper>
  );
};
