import QuestionFooter, { AnalysisFieldState, ReferencesState } from "./QuestionFooter";
import {
    Box,
    Divider,
    Typography,
    Grid
} from "@mui/material";
import React, { useMemo } from "react";
import { Commentary, StaticChoices, DisplayedVariableReference, DisplayedVariableReferences } from "../../../types/builderv2.generated";
import { GraphFulfillmentState } from "../../../types/taker/fulfillmentstate.generated";
import { AnalysisState } from "../../../containers/WidgetWrapper/states";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";

import { blue } from "@mui/material/colors";
import QuestionDetailsModal from "./DetailsModals/QuestionDetailsModal";
import QuestionAnswer, { PrimitiveType } from "./QuestionAnswer";


interface QuestionWrapperProps {
    graphFulfillmentState: GraphFulfillmentState;
    nodeId: string;
    dataType: PrimitiveType;
    questionId: string;
    questionLabel: string;
    questionText: string;
    questionType: "radio" | "multiselect" | "input";
    choices?: StaticChoices | DisplayedVariableReference | DisplayedVariableReferences;
    questionCommentary?: Commentary;
    analysisState?: AnalysisFieldState;
    referencesState?: ReferencesState;
    moduleIds: string[];
    iteration: number | null;
    defaultValue: any;
    onChangeValue: (a: any) => void;
    readOnly?: boolean;
}

const QuestionWrapper = ({
    graphFulfillmentState,
    nodeId,
    dataType,
    questionId,
    questionLabel,
    questionText,
    questionType,
    choices,
    questionCommentary,
    analysisState,
    referencesState,
    moduleIds,
    iteration,
    defaultValue,
    onChangeValue,
    readOnly = false
}: QuestionWrapperProps) => {
    const { getState } = useWidgetState();

    const selectedQuestionnaireElement = getState<AnalysisState>().selectedElement;

    const { questionnareDataService } = useTakerState();

    const uniqueId = useMemo(() =>
        `${nodeId}_${questionId}_${moduleIds.join('_')}`,
        [nodeId, questionId, moduleIds]
    );

    const isSelected = useMemo(() =>
        moduleIds &&
        selectedQuestionnaireElement &&
        selectedQuestionnaireElement.moduleIds &&
        nodeId === selectedQuestionnaireElement.nodeId &&
        moduleIds.length === selectedQuestionnaireElement.moduleIds.length &&
        moduleIds.every((val, i) => val === selectedQuestionnaireElement.moduleIds[i]),
        [
            nodeId,
            selectedQuestionnaireElement
        ]
    );

    const finalText = useMemo(() => {
        for (const entry of graphFulfillmentState.renderedText) {
            if (entry.nodeId === nodeId && entry.rendered) {
                return entry.qText;
            }
        }
        let trimmed = questionText.trim();
        return trimmed === "" ? null : trimmed;
    }, [graphFulfillmentState, questionId, questionText]);

    const border = isSelected ? `2px solid ${blue[400]}` : "1px solid #ddd";

    // TODO: need to add a property to a question for this instead of checking ID
    const noAIAnswering = questionId === "AUDITOR_QIS" || questionId === "STANDARD_QIS" || questionId === "ACK";

    const isBoltButtonMenuDisabled = (questionType === "input" && dataType === "string");

    return (
        <Box
            sx={{
                marginTop: 1,
                marginBottom: 1,
                padding: 1,
                backgroundColor: "white",
                borderRadius: 1,
                border: border
            }}
            data-testid={questionId.toString()}
        >
            <Grid container>
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            variant="subtitle1"
                            sx={{ marginLeft: 1 }}
                            data-testid={`${questionId.toString()}-label`}
                        >
                            <strong>{questionLabel}</strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <QuestionDetailsModal
                            uniqueId={uniqueId}
                            moduleIds={moduleIds}
                            questionId={questionId}
                            iteration={iteration}
                            questionCommentary={questionCommentary}
                            analysisState={analysisState}
                            referencesState={referencesState}
                            graphFulfillmentState={graphFulfillmentState}
                            nodeId={nodeId}
                            dataType={dataType}
                            questionLabel={questionLabel}
                            questionText={finalText}
                            questionType={questionType}
                            choices={choices}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} paddingTop={1}>
                    <Divider />
                </Grid>
                {finalText && (
                    <>
                        <Grid item xs={12} paddingTop={2}>
                            <div
                                style={{
                                    alignItems: 'left',
                                    width: "100%",
                                    display: 'flex'
                                }}
                                data-testid={`${questionId.toString()}-text`}
                            >
                                <Typography
                                    data-testid="question-text"
                                    paragraph
                                    sx={{
                                        "white-space": "pre-wrap"
                                    }}
                                >
                                    {finalText}
                                </Typography>
                            </div>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} paddingTop={2}>
                    <QuestionAnswer
                        data-testid={`${questionId.toString()}-questionAnswer`}
                        uniqueId={uniqueId}
                        graphFulfillmentState={graphFulfillmentState}
                        nodeId={nodeId}
                        dataType={dataType}
                        questionType={questionType}
                        choices={choices}
                        defaultValue={defaultValue}
                        onChangeValue={onChangeValue}
                        readOnly={readOnly}
                    />
                </Grid>
                {!(analysisState === "DISABLED" && referencesState === "DISABLED") && (
                    <Grid item xs={12} paddingTop={2}>
                        <Divider />
                    </Grid>
                )}
                <Grid item xs={12} paddingTop={2}>
                    <QuestionFooter
                        uniqueId={uniqueId}
                        moduleIds={moduleIds}
                        questionId={questionId}
                        iteration={iteration}
                        analysisState={analysisState}
                        referencesState={referencesState}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default QuestionWrapper;