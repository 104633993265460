import React from "react";
import { Box, Button, IconButton, Tooltip  } from "@mui/material/";
import { DeleteIcon, EditIcon, PlusIcon, ErrorIcon, PreviewIcon } from "../../assets/icons";

export type ButtonType = "edit" | "delete" | "create" | "view";

export interface buttonItem {
  type?: ButtonType;
  icon?: JSX.Element;
  disabled?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;  
}

export const crudButtonOptions = {
  EDIT: "edit" as ButtonType,
  DELETE: "delete" as ButtonType,
  CREATE: "create" as ButtonType,
  VIEW: "view" as ButtonType,
};

type Props = {
  buttons: buttonItem[];
};

export function CrudButtonGroup({ buttons }: Props) {
  return (
    <Box sx={{ display: "flex" }}>
      {buttons.map((button: buttonItem, index: number) => (
        <Tooltip title={button.tooltip}>
          <IconButton
            key={index}
            size="small"
            color="default"
            onClick={button.handleClick ? button.handleClick : () => console.log("clicked")}
            disabled={Boolean(button.disabled)}
            sx={{
              marginLeft: "5px",
              border: "1px solid #ddd"
            }}
            data-testid={`icon-button-${index}`}
          >        
            {button.icon ? (
              <>
                {button.icon}
              </>
            ) : (
              <>
                {button.type === "edit" ? (
                  <EditIcon />
                ) : button.type === "delete" ? (
                  <DeleteIcon />
                ) : button.type === "create" ? (
                  <PlusIcon />
                ) : button.type === "view" ? (
                  <PreviewIcon />
                ) : (
                  <ErrorIcon />
              )}
              </>
            )}
          </IconButton>
        </Tooltip> 
      ))}
    </Box>
  );
}
