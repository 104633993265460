import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../../components/lexical/themes/theme";
import { useMemo, useState } from 'react';
import { Alert, AlertTitle, Box } from '@mui/material';
import { TakerDocumentAnalysis } from '../../../redux/models/dataModelTypes';
import { useGetTakerDocumentAnalysisPayloadQuery } from '../../../redux/services/taker';
import { ReportSectionNode } from '../../../components/lexical/nodes/ReportSectionNode';
import { ReportRevision } from '../../../types/taker/reportstate.generated';
import { LoadingButton } from '@mui/lab';
import InjectReportEditsPlugin from '../../../components/lexical/plugins/InjectReportEditsPlugin';
import SyncReportModal from './SyncReportModal';
import OnChangeAnalysisPlugin from './OnChangeAnalysisPlugin';

interface ReportEditorProps {
    readOnly: boolean;
    syncedMemoGenAnalysis: TakerDocumentAnalysis;
    latestMemoGenAnalysis: TakerDocumentAnalysis;
    reportRevision: ReportRevision;
}

export default function ReportEditor({
    readOnly,
    syncedMemoGenAnalysis,
    latestMemoGenAnalysis,
    reportRevision
}: ReportEditorProps) {
    const [openSyncReport, setOpenSyncReport] = useState<boolean>(false);
    const {
        data: syncedMemoGenAnalysisData,
        isSuccess: syncedMemoGenAnalysisSuccess
    } = useGetTakerDocumentAnalysisPayloadQuery(syncedMemoGenAnalysis.id);
    
    const hasNewUpdates = useMemo(() =>
        syncedMemoGenAnalysis.id !== latestMemoGenAnalysis.id,
        [syncedMemoGenAnalysis, latestMemoGenAnalysis]
    );

    const pendingReview = useMemo(() =>
        latestMemoGenAnalysis.state === "PENDING_GENERATION" && (new Date().getTime() - latestMemoGenAnalysis.updatedAt) < (10000 * 600),
        [latestMemoGenAnalysis]
    );

    return (
        <Box
            sx={{
                p: 1
            }}
        >
            <LexicalComposer
                initialConfig={{
                    editable: false,
                    namespace: "main",
                    theme: EditorThemeClasses,
                    onError(error: any) {
                        console.error(error);
                    },
                    nodes: [
                        HeadingNode,
                        ListNode,
                        ListItemNode,
                        QuoteNode,
                        TableNode,
                        TableCellNode,
                        TableRowNode,
                        ReportSectionNode
                    ]
                }}
            >
                <>
                    {hasNewUpdates && (
                        <Box sx={{ paddingBottom: 1 }}>
                            <Alert
                                severity="info"
                                action={
                                    <LoadingButton
                                        data-testid="sync-report-alert-btn"
                                        loading={pendingReview}
                                        color="inherit"
                                        size="small"
                                        onClick={() => setOpenSyncReport(true)}
                                    >
                                        sync
                                    </LoadingButton>
                                }
                            >
                                <AlertTitle>
                                    There were updates to the report
                                </AlertTitle>
                            </Alert>
                            <SyncReportModal
                                open={openSyncReport}
                                onClose={() => setOpenSyncReport(false)}
                                latestMemoGenAnalysis={latestMemoGenAnalysis}
                                reportRevision={reportRevision}
                            />
                        </Box>
                    )}
                </>
                <div
                    style={{
                        border: "1px solid #ddd",
                        borderRadius: 2,
                        height: "100%",
                        padding: "2%",
                        overflow: "auto",
                        maxWidth: "1200px"
                    }}
                >
                    <OnChangeAnalysisPlugin
                        memoGenData={syncedMemoGenAnalysisData}
                        isSuccess={syncedMemoGenAnalysisSuccess}
                    />
                    <div
                        style={{
                            padding: "5%"
                        }}
                    >
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable
                                    className="editor-input"
                                />
                            }
                            placeholder={<div className="editor-placeholder"></div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <AutoFocusPlugin />
                        <ListPlugin />
                        <TablePlugin />
                        <InjectReportEditsPlugin reportRevision={reportRevision}/>
                    </div>
                </div>
            </LexicalComposer>
        </Box>
    );
}
