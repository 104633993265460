import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import { Box, Card, CardContent, Divider, Stack, TextField, Typography } from "@mui/material";
import { BoltButton } from "../../../components/buttons/boltButton";
import { useState } from "react";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { OptionsWithExtraProps, useSnackbar } from "notistack";
import { useUserScopedAppData } from "../../../containers/UserScopedAppData/UserScopedAppData";

const TOP_CENTER_OPTION = {
    variant: 'info',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
} as OptionsWithExtraProps<'info'>;

interface ProposedInputWidgetProps {
    keyTerm: KeyTerm;
}
export const ProposedInputWidget = ({ keyTerm }: ProposedInputWidgetProps) => {
    const [aiGeneratedValue, setAIGeneratedValue] = useState("Press the bolt on the right to generate a suggested summary!");
    const [disableInput, setDisableInput] = useState(false);
    const { documentKeyTermsService } = useKeyTermGroupState();
    const { flagProvider } = useUserScopedAppData();
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {
        const summary = documentKeyTermsService.getMockKeyTermSummary(keyTerm.termName);
        if (!summary) {
            enqueueSnackbar("AI Generated Summary is not available", TOP_CENTER_OPTION);
            return;
        }

        setDisableInput(true);

        const words = summary.split(' ');

        const setInnerReportSectionContent = (wordI: number) => {
            // Replace first child, remove the addition children
            const contatedTokens = words.slice(0, wordI + 1).join(' ');
            setAIGeneratedValue(contatedTokens);
        }

        for (let i = 0; i < words.length; i++) {
            setTimeout(() => {
                setInnerReportSectionContent(i);
            }, (300 * (i + 1)));
        }
        setTimeout(() => {
            setDisableInput(false);
        }, (300 * words.length));
    };

    let flags = flagProvider.populateFlagValues([
        "DEMO_MODE__enable_demo_mode",
        "AI_FEATURES_KEY_TERMS__enable_ai_for_key_terms"
    ]);
    let tooltipText = "Generate AI Summary";
    let disableButton = false;
    if(flags.DEMO_MODE__enable_demo_mode === "TRUE" && flags.AI_FEATURES_KEY_TERMS__enable_ai_for_key_terms === "FALSE") {
        tooltipText = "Generate AI Summary";
    } else if(flags.DEMO_MODE__enable_demo_mode === "FALSE" && flags.AI_FEATURES_KEY_TERMS__enable_ai_for_key_terms === "FALSE") {
        tooltipText = "AI Key Term Summary Generation coming soon!";
        disableButton = true;
    }
    return (
        <Card variant="outlined" sx={{ height: "100%", overflowY: "auto" }}>
            <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">
                        Proposed Summary
                    </Typography>
                    <BoltButton
                        boltId="generate-summary"
                        isKeyTerm
                        disableAll={disableButton}
                        tooltipText={tooltipText}
                        onClick={() => {
                            handleClick()
                        }}
                        onClickDelay={(Math.random() * 3000) + 3000}
                        size="small"
                    />
                </Stack>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                <Box
                    sx={{
                        width: "100%",
                        display: "inline-flex",
                        alignItems: "center"
                    }}
                >
                    <TextField
                        data-testid="editable-summary-text"
                        id="editable-summary-text"
                        size="medium"
                        value={aiGeneratedValue}
                        variant="outlined"
                        multiline
                        fullWidth
                        disabled={disableInput}
                        InputProps={{
                            readOnly: true,
                            autoComplete: "off"
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}