import {
    Box,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { useMemo, useState } from "react";
import { ExpandLessOutlined, ExpandMoreOutlined, MoreHoriz } from "@mui/icons-material";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { AnalysisState } from "../../../containers/WidgetWrapper/states";
import ConfirmationDialog from "../../../components/dialog/GenericConfirmation";
import { useReadOnlyBuilderData } from "../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData";

interface OptionsProps {
    onDeleteIteration: () => void;
}

const Options = ({
    onDeleteIteration
}: OptionsProps) => {
    const { isTakerStateDirty } = useTakerState();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [needConfirm, setNeedConfirm] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton
                id="other-options-btn"
                aria-controls={open ? 'other-options-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disabled={isTakerStateDirty}
                onClick={handleClick}
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                id="other-options-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'other-options-btn',
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setNeedConfirm(true);
                    }}
                >
                    Delete Iteration
                </MenuItem>
            </Menu>
            {needConfirm && (
                <ConfirmationDialog
                    title="Confirmation"
                    confirmationText="Are you sure you want to delete this iteration?"
                    onConfirmed={() => {
                        onDeleteIteration();
                        setNeedConfirm(false);
                    }}
                    onCancelled={() => {
                        setNeedConfirm(false);
                    }}
                />
            )}
        </Box>
    );
};

interface StartIterationProps {
    moduleIds: string[];
    iteration: number;
}

const StartIteration = ({
    moduleIds,
    iteration
}: StartIterationProps) => {
    const {
        questionnareDataService,
        fulfillmentStateHolder
    } = useTakerState();
    const { getState, mutateState } = useWidgetState();
    const { builderHolder } = useReadOnlyBuilderData();

    let mid = moduleIds.join("_");

    const collapsedState = getState<AnalysisState>().collapsedState || {};

    const collapseIteration = () => {
        let newCollapsedState = { ...collapsedState };
        if (!newCollapsedState[mid]) {
            newCollapsedState[mid] = {};
        }
        newCollapsedState[mid][iteration - 1] = true;
        mutateState<AnalysisState>({
            collapsedState: newCollapsedState
        });
    };

    const expandIteration = () => {
        let newCollapsedState = { ...collapsedState };
        if (!newCollapsedState[mid]) {
            newCollapsedState[mid] = {};
        }

        for (let i in newCollapsedState[mid]) {
            newCollapsedState[mid][i] = true;
        }
        newCollapsedState[mid][iteration - 1] = false;

        mutateState<AnalysisState>({
            collapsedState: newCollapsedState
        });
    };

    let isCollapsedUnset = (collapsedState[mid] === undefined) || (collapsedState[mid] && collapsedState[mid][iteration - 1] === undefined);
    let isCollapsedSetToTrue = collapsedState[mid] && collapsedState[mid][iteration - 1];
    let isCollapsed = isCollapsedUnset || isCollapsedSetToTrue;

    const fulfilled = useMemo(() => {
        if (moduleIds) {
            if (fulfillmentStateHolder && moduleIds.length > 0) {
                let f = fulfillmentStateHolder.getSubstateFulfilled(moduleIds);
                if (Array.isArray(f)) {
                    return f[iteration];
                }
            }
        }
    }, [fulfillmentStateHolder, moduleIds, iteration]);

    const praxiModuleDisplayName = useMemo(() => {
        const praxiModule = builderHolder.getModule(moduleIds);
        if (!praxiModule) {
            return "unknown";
        }
        return praxiModule.displayName;
    }, [builderHolder, moduleIds]);

    return (
        <Box
            sx={{
                marginBottom: "1%",
                paddingLeft: "1%",
                backgroundColor: !!fulfilled ? "rgb(25, 118, 210, 0.05)" : "rgb(255, 0, 0, 0.05)",
                borderRadius: 1
            }}
        >
            <Grid container alignContent="center">
                <Grid item xs={8} alignContent="center">
                    <Typography variant="subtitle2">
                        {`Iteration ${iteration + 1} of ${praxiModuleDisplayName}`}
                    </Typography>
                </Grid>
                <Grid item container xs={4} justifyContent="end">
                    <Grid item>
                        <Options
                            onDeleteIteration={() => {
                                questionnareDataService.removeIteration(moduleIds, iteration);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton
                            id={`ExpandCollapseBtn-${mid}-${iteration}`}
                            onClick={() => {
                                if (isCollapsed) {
                                    expandIteration();
                                } else {
                                    collapseIteration();
                                }
                            }}
                        >
                            {isCollapsed ? 
                                <ExpandLessOutlined data-testid={`ExpandLessBtn-${mid}-${iteration}`}/> : 
                                <ExpandMoreOutlined data-testid={`ExpandMoreBtn-${mid}-${iteration}`}/>
                            }
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default StartIteration;