import { BuilderDocument } from "../../redux/models/dataModelTypes";
import { Box, Button, Chip, Divider, Grid, List, ListItem } from "@mui/material/";
import {
    useAddFulfillmentSimulationJobMutation,
    useGetBuilderDocumentJobPayloadQuery
} from "../../redux/services/builder";
import { useState } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";

interface Props {
    builderDocument: BuilderDocument;
}

interface SimulationResultProps {
    builderDocumentJobId: string;
}

interface SimulationResultDetailsProps {
    builderDocumentJobId: string;
    runNumbers: number[];
    i: number;
}

const SimulationResultDetails = ({ 
    builderDocumentJobId,
    runNumbers,
    i
}: SimulationResultDetailsProps) => {
    const { 
        data: jobPayload,
        isSuccess
    } = useGetBuilderDocumentJobPayloadQuery({ builderDocumentJobId, runNumbers });

    if (isSuccess) {
        return (
            <Grid container spacing={1}>
                <Grid xs={12} item spacing={1}>
                    <pre>
                        {jobPayload['failures'][i] && JSON.stringify(jobPayload['failures'][i], null, 4)}
                    </pre>
                </Grid>
                <Grid xs={12} item spacing={1}>
                    <pre>
                        {jobPayload['states'][0] && JSON.stringify(jobPayload['states'][0], null, 4)}
                    </pre>
                </Grid>
            </Grid>
        );
    }
    return null;
};

const SimulationResult = ({ builderDocumentJobId }: SimulationResultProps) => {
    const [openRun, setOpenRun] = useState<number>();
    const { 
        data: jobPayload,
        isSuccess
    } = useGetBuilderDocumentJobPayloadQuery({builderDocumentJobId, runNumbers: []});

    if (isSuccess) {
        return (
            <Grid container spacing={1}>
                {jobPayload['failures'].map((f: any, i: number) => (
                    <Grid item>
                        {f ? (
                            <Chip 
                                label={f['termination_message']}
                                variant="outlined"
                                color="error"
                                size="small"
                                clickable
                                onClick={() => {
                                    setOpenRun(f['run_number']);
                                }}
                            />
                        ) : (
                            <Chip 
                                label="passed"
                                variant="outlined"
                                color="success"
                                size="small"
                            />
                        )}
                        {(openRun !== undefined && f && openRun === f['run_number']) && (
                             <SimpleModalWrapper
                                headerText=""
                                open={openRun === f['run_number']}
                                handleClose={() => setOpenRun(undefined)}
                                sx={{ width: "80vw", height: "80vh", padding: "16px 24px 16px 24px" }}
                            >
                                <SimulationResultDetails
                                    builderDocumentJobId={builderDocumentJobId}
                                    runNumbers={[openRun]}
                                    i={i}
                                />
                            </SimpleModalWrapper>
                        )}
                    </Grid>
                ))}
            </Grid>
        );
    }
    return null;
};

export const Simulation = ({ builderDocument }: Props) => {
    const [addFulfillmentSimulationJob, addBuilderDocumentGuidanceRes] = useAddFulfillmentSimulationJobMutation();
    const [showMore, setShowMore] = useState<string>();

    const jobs = [...builderDocument.fulfillmentSimulationJobs];
    jobs.sort((a, b) => b.createdAt - a.createdAt);

    return (
        <Grid container>
            <Grid
                xs={12}
                item
                paddingTop={1}
                paddingBottom={2}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        addFulfillmentSimulationJob({
                            builderDocumentId: builderDocument.id,
                        });
                    }}
                >
                    Add
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                paddingBottom={2}
            >
                <List disablePadding>
                    {jobs.map(fsj => (
                        <ListItem
                            data-testid={`list-item-${fsj.id}`}
                            key={fsj.id}
                            sx={{
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            <Grid
                                container
                                padding={1}
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: 1,
                                    border: "1px solid rgb(195, 195, 195)",
                                }}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                    xs={12}
                                >
                                    <span>{new Date(fsj.createdAt).toLocaleString()}</span>
                                    <span>{fsj.state} {new Date(fsj.updatedAt).toLocaleString()}</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Divider />
                                    </Box>
                                    <Box paddingTop={1}>
                                        {showMore === fsj.id ? (
                                            <>
                                                <SimulationResult builderDocumentJobId={fsj.id}/>
                                                <Button
                                                    onClick={() => {
                                                        setShowMore(undefined)
                                                    }}
                                                    variant="text"
                                                    size="small"
                                                >
                                                    less
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    setShowMore(fsj.id)
                                                }}
                                                variant="text"
                                                size="small"
                                            >
                                                more
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

export default Simulation;
