import React, { memo, useMemo } from "react";
import { Box, Button, Container, Grid } from "@mui/material";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import ReportEditor from "./ReportEditor";
import { useReportState } from "../../containers/TakerDocumentState/ReportState";
import { useAddTakerDocumentDataMutation } from "../../redux/services/takerData";
import { ReportRevision, ReportStateData } from "../../types/taker/reportstate.generated";
import { LoadingButton } from "@mui/lab";

const DisplayReport = () => {
    const {
        latestMemoGenAnalyses,
        latestReportDataId,
        latestReportRevision,
        isReportUninitialized
    } = useReportState();
    const {
        taker,
        takerDocumentId,
        isTakerDocumentLoading,
        takerPermissionState
    } = useTakerState();
    const [addTakerDocumentData, addTakerDocumentDataRes] = useAddTakerDocumentDataMutation();

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    const latestMemoGenAnalysis = useMemo(() => {
        if (latestMemoGenAnalyses) {
            return latestMemoGenAnalyses[0];
        }
    }, [latestMemoGenAnalyses]);

    const syncedMemoGenAnalysis = useMemo(() => {
        if (latestMemoGenAnalyses && latestReportRevision) {
            for (const memoGenAnalysis of latestMemoGenAnalyses) {
                if (memoGenAnalysis.id === latestReportRevision.syncedWithGenerationId) {
                    return memoGenAnalysis;
                }
            }
        }
    }, [latestMemoGenAnalyses, latestReportRevision]);

    const isLatestComplete = useMemo(() => {
        if (latestMemoGenAnalysis) {
            return latestMemoGenAnalysis.state === "PENDING_REVIEW" 
                || latestMemoGenAnalysis.state === "APPROVED";
        }
        return false;
    }, [latestMemoGenAnalysis]);

    // The document is loading
    if (isTakerDocumentLoading) {
        return (
            <Box padding={1}>
                <em>Loading</em>
            </Box>
        );
    } else if (!latestMemoGenAnalysis) {
        return (
            <Box padding={1}>
                <em>A Report Must Be Available Before Starting</em>
            </Box>
        );
    }

    return (
        <Grid
            container
            sx={{
                overflowY: "auto",
                height: "100%",
                padding: 1
            }}
            justifyContent="center"
        >
            {(!latestReportDataId || !latestReportRevision || !syncedMemoGenAnalysis) ? (
                <Box
                    sx={{ backgroundColor: "white" }}
                    border="1px solid rgb(195, 195, 195)"
                    borderRadius={1}
                    padding="5%"
                    height="calc(100% - 5px)"
                    width="100%"
                    display="block"
                    alignItems="center"
                    alignContent="center"
                    textAlign="center"
                >
                    <LoadingButton
                        disabled={isReadOnly}
                        loading={isReportUninitialized || !isLatestComplete}
                        onClick={() => {
                            if (!isReadOnly) {
                                if (taker && latestMemoGenAnalysis) {
                                    const reportRevision: ReportRevision = {
                                        revisionId: window.crypto.randomUUID(),
                                        syncedWithGenerationId: latestMemoGenAnalysis.id,
                                        updatedAt: new Date().getTime(),
                                        reportEdits: []
                                    };
                                    addTakerDocumentData({
                                        takerId: taker.id,
                                        takerDocumentId: takerDocumentId,
                                        contentType: "REPORT",
                                        content: {
                                            reportRevisions: [reportRevision]
                                        } as ReportStateData
                                    });
                                }
                            }
                        }}
                    >
                        Start Report
                    </LoadingButton>
                </Box>
            ) : (
                <div id="report-container">
                    <ReportEditor
                        readOnly={isReadOnly || isReportUninitialized}
                        syncedMemoGenAnalysis={syncedMemoGenAnalysis}
                        latestMemoGenAnalysis={latestMemoGenAnalysis}
                        reportRevision={latestReportRevision}
                    />
                </div>
            )}
        </Grid>
    )
};

export default DisplayReport;