import React, { useState } from 'react';
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import AnalysisField from './AnalysisField';
import GuidanceList from './GuidanceList';
import SourcesList from './SourcesList';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../../../types/taker/uidatastate.generated';
import QuestionAnswer, { PrimitiveType } from '../QuestionAnswer';
import { GraphFulfillmentState } from '../../../../types/taker/fulfillmentstate.generated';
import { DisplayedVariableReference, DisplayedVariableReferences, StaticChoices } from '../../../../types/builderv2.generated';
import { BoltButton } from '../../../../components/buttons/boltButton';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';
import { useUserScopedAppData } from '../../../../containers/UserScopedAppData/UserScopedAppData';

const TOP_CENTER_OPTION = {
    variant: 'info',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
} as OptionsWithExtraProps<'info'>;

interface AiGeneratedCardProps {
    uniqueId: string;
    graphFulfillmentState: GraphFulfillmentState;
    nodeId: string;
    dataType: PrimitiveType;
    questionType: "radio" | "multiselect" | "input";
    choices?: StaticChoices | DisplayedVariableReference | DisplayedVariableReferences;
    moduleIds: string[];
    iteration: number | null;
    questionId: string;
    questionText: string | null;
    enableCommentary: boolean;
    enableAnalysis: boolean;
}

const AiGeneratedCard = ({
    uniqueId,
    graphFulfillmentState,
    nodeId,
    dataType,
    questionType,
    choices,
    moduleIds,
    iteration,
    questionId,
    questionText,
    enableCommentary,
    enableAnalysis
}: AiGeneratedCardProps) => {
    const { questionnareDataService } = useTakerState();
    const { enqueueSnackbar } = useSnackbar();
    const { flagProvider } = useUserScopedAppData();
    const [answer, setAnswer] = useState<string>("");
    const [analysis, setAnalysis] = useState<string>("");
    const [reference, setReference] = useState<CommentaryOrGuidanceReference[]>([]);
    const [source, setSource] = useState<KeyTermSource[]>([]);

    const handleClick = () => {
        let a = questionnareDataService.getMockAnswer(moduleIds, questionId, iteration);
        if (a !== undefined) {
            setAnswer(a);
            setAnalysis(questionnareDataService.getMockAnalysis(moduleIds, questionId, iteration) || "");
            setReference(questionnareDataService.getMockReference(moduleIds, questionId, iteration) || []);
            setSource(questionnareDataService.getMockSource(moduleIds, questionId, iteration) || []);
        } else {
            enqueueSnackbar("AI Generated Answer is not available", TOP_CENTER_OPTION);
        }
    };

    let flags = flagProvider.populateFlagValues([
        "DEMO_MODE__enable_demo_mode",
        "AI_FEATURES_QUESTIONNAIRE__enable_ai_for_questionnaire"
    ]);
    let tooltipText = "Generate AI Answer";
    let disableButton = false;
    if(flags.DEMO_MODE__enable_demo_mode === "TRUE" && flags.AI_FEATURES_QUESTIONNAIRE__enable_ai_for_questionnaire === "FALSE") {
        tooltipText = "Generate AI Answer";
    } else if(flags.DEMO_MODE__enable_demo_mode === "FALSE" && flags.AI_FEATURES_QUESTIONNAIRE__enable_ai_for_questionnaire === "FALSE") {
        tooltipText = "AI Answer Generation coming soon!";
        disableButton = true;
    }
    return (
        <Card variant="outlined" sx={{ height: "100%", overflowY: "auto" }}>
            <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        Proposed
                    </Typography>
                    <React.Fragment>
                        <BoltButton
                            boltId={uniqueId}
                            disableMenu={true}
                            disableAll={disableButton}
                            tooltipText={tooltipText}
                            onClick={() => handleClick()}
                            onClickDelay={(Math.random() * 3000) + 3000}
                            size="small"
                        />
                    </React.Fragment>
                </Stack>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                {questionText && (
                    <Box
                        alignItems="left"
                        width="100%"
                        display="flex"
                        marginBottom={1}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                "white-space": "pre-wrap"
                            }}
                        >
                            {questionText}
                        </Typography>
                    </Box>
                )}
                <QuestionAnswer
                    uniqueId={uniqueId}
                    graphFulfillmentState={graphFulfillmentState}
                    nodeId={nodeId}
                    dataType={dataType}
                    questionType={questionType}
                    choices={choices}
                    defaultValue={answer}
                    onChangeValue={() => { }}
                />
                {enableAnalysis && (
                    <Box paddingTop="15px">
                        <Typography variant="subtitle1" color="text.secondary">
                            Analysis
                        </Typography>
                        <AnalysisField
                            readOnly={true}
                            defaultValue={analysis}
                            onUpdate={() => { }}
                        />
                    </Box>
                )}
                {(enableCommentary) && (
                    <Box paddingTop="15px">
                        <Typography variant="subtitle1" color="text.secondary">
                            Guidance
                        </Typography>
                        <GuidanceList
                            readOnly={true}
                            defaultValue={reference}
                            onUpdate={() => { }}
                        />
                    </Box>
                )}
                <Box paddingTop="15px">
                    <Typography variant="subtitle1" color="text.secondary">
                        Sources
                    </Typography>
                    <SourcesList
                        readOnly={true}
                        defaultValue={source}
                        onUpdate={() => { }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default AiGeneratedCard;
