import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';

export type AnalysisFieldState = "DISABLED" | "OPTIONAL" | "REQUIRED";
export type ReferencesState = "DISABLED" | "OPTIONAL";

interface QuestionFooterProps {
    uniqueId: string;
    moduleIds: string[];
    iteration: number | null;
    questionId: string;
    analysisState?: AnalysisFieldState;
    referencesState?: ReferencesState;
}

const QuestionFooter = ({
    uniqueId,
    moduleIds,
    iteration,
    questionId,
    analysisState = "OPTIONAL",
    referencesState = "OPTIONAL",
}: QuestionFooterProps) => {
    const { questionnareDataService } = useTakerState();

    const analysis = questionnareDataService.getAnalysis(moduleIds, questionId, iteration) || "";
    const reference = questionnareDataService.getReference(moduleIds, questionId, iteration) || [];
    
    const enableAnalysis = useMemo(
        () => analysisState === "OPTIONAL" || analysisState === "REQUIRED",
        [analysisState]
    );

    const enableCommentary = useMemo(
        () => referencesState === "OPTIONAL",
        [referencesState]
    );

    if (!enableAnalysis && !enableCommentary) {
        return null;
    }

    let isAnalysisEmpty = !analysis || analysis === "";
    let isReferencesEmpty = !reference || reference.length === 0;

    const errorCaptions: string[] = [];
    const captions: string[] = [];

    if (isAnalysisEmpty && analysisState === "REQUIRED") {
        errorCaptions.push("Analysis is required, but empty.");
    }
    if (isAnalysisEmpty && analysisState === "OPTIONAL") {
        captions.push("Analysis is empty.");
    }
    if (isReferencesEmpty && referencesState === "OPTIONAL") {
        captions.push("There are no references.");
    }

    return (
        <Stack>
            {errorCaptions.map((s, i) => (
                <Typography 
                    id={`footerError_${i}_${uniqueId}`}
                    variant="caption"
                    color={red[700]}
                >
                    * {s}
                </Typography>
            ))}
            {captions.map((s, i) => (
                <Typography 
                    id={`footerMessage_${i}_${uniqueId}`}
                    variant="caption"
                >
                    * {s}
                </Typography>
            ))}
        </Stack>
    );
}

export default QuestionFooter;