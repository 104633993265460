import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../containers/WidgetWrapper/wrapper";
import { MemoGenerationState } from "../../containers/WidgetWrapper/states";
import Toolbar from "./ReportToolbar";
import DisplayReport from "./DisplayReport";

const buildInitialReportState = (savedState: string | null) => {
    const state: MemoGenerationState = (!!savedState && JSON.parse(savedState)) || {
        generatedReportsModalOpen: false
    };
    return state;
};

const TakerReport = () => {
    const navigate = useNavigate();
    const {
        taker,
        takerDocumentId
    } = useTakerState();
    const [widgetState, setWidgetState] = useState<MemoGenerationState>(
        buildInitialReportState(
            localStorage.getItem(`MemoGenerationState-${takerDocumentId}`),
        )
    );

    /**
     * Sync widgetState with localStorage.
    */
    useEffect(() => {
        if (!!widgetState) {
            const widgetStateCopy = { ...widgetState };
            delete widgetStateCopy.currentEditor;
            localStorage.setItem(`MemoGenerationState-${takerDocumentId}`, JSON.stringify(widgetStateCopy));
        }
    }, [widgetState]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                height: '100%',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    width: '100%'
                }}
            >
                <TakerToolbar
                    onClickBack={() => {
                        if (taker) {
                            navigate(`/mainTaker/${taker.id}/analysis`);
                        }
                    }}
                    workflowDisplay={"Report"}
                    workflowDescription={`Please review a generated report.`}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    overflow: 'hidden'
                }}
                flexGrow={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <WidgetWrapper
                        widgetState={widgetState}
                        setPartialWidgetState={(s) => {
                            setWidgetState(Object.assign({}, widgetState, s));
                        }}
                    >
                        <Box
                            paddingTop={1}
                            paddingLeft={1}
                            paddingRight={1}
                        >
                            <Toolbar />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                overflow: 'hidden'
                            }}
                            flexGrow={1}
                        >
                            <DisplayReport />
                        </Box>
                    </WidgetWrapper>
                </Box>
            </Box>
        </Box>
    );
}

export default TakerReport;
