import React, { useState } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material/";
import { useAddApplicationMutation } from "../../redux/services/application";
import { ApplicationTagType } from "../../redux/models/dataModelTypes";


interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const tagTypes: ApplicationTagType[] = [
  "ORGANIZATION",
  "PRIMARY"
];

export function CreateApplicationModal({ open, setOpen }: Props) {
  const [tagData, setTagData] = useState<string>();
  const [tagType, setTagType] = useState<ApplicationTagType>();
  const [addApplication, addApplicationRes] = useAddApplicationMutation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SimpleModalWrapper
      headerText="Create Application"
      open={open}
      handleClose={handleClose}
      sx={{ width: "30vw", height: "auto", padding: "16px 24px 16px 24px" }}
    >
      <Box>
        <FormControl
          fullWidth
          sx={{ marginTop: "10px" }}
          variant="outlined"
        >
          <InputLabel id="tag-type-label">Tag Type</InputLabel>
          <Select
            label="Tag Type"
            labelId="tag-type-label"
            value={tagType}
            onChange={(e) => setTagType(e.target.value as ApplicationTagType)}>
            {tagTypes.map((item) =>
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "10px"
          }}
          variant="outlined"
        >
          <TextField
            label="Tag Data"
            onChange={(e) => setTagData(e.target.value)}
          />
        </FormControl>
      </Box>
      <Box>
        <Button
          variant="contained"
          type="submit"
          sx={{ width: "30%" }}
          onClick={() => {
            addApplication({
              tag: {
                type: tagType,
                data: tagData
              }
            });
            handleClose();
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          sx={{ width: "30%", marginLeft: "30px" }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </SimpleModalWrapper>
  );
}
