import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "../../components/navigation/SideNav";
import { Box, Typography, Fab, Grid, Toolbar, CircularProgress } from "@mui/material/";
import MyRecentBuilders from "./myRecentBuilders";
import MyRecentTakers from "./myRecentTakers";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Builder } from "../../redux/models/dataModelTypes";
import { useAddTakerMutation } from "../../redux/services/taker";
import { useAddBuilderMutation } from "../../redux/services/builder";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";
import SharedTakers from "./sharedTakers";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { FeatureFlags } from "../../types/featureflags.generated";

interface ViewBuilderModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  builder: Builder | null;
}

export function ViewBuilderModal({
  open,
  setOpen,
  builder
}: ViewBuilderModalProps) {
  const navigate = useNavigate();

  const [addTaker, addTakerResult] = useAddTakerMutation();

  useEffect(() => {
    if (addTakerResult.isSuccess) {
      navigate(`/mainTaker/${addTakerResult.data.id}/keyTerms`);
    }
  }, [addTakerResult]);

  const createNewTaker = () => {
    if (!builder) {
      return;
    }

    addTaker({
      name: "new taker",
      description: "",
      builderId: builder.id,
      initialBuilderDocumentId: builder.builderDocuments[0].id
    });
  };

  if (!builder) {
    return null;
  }
  return (
    <SimpleModalWrapper
      headerText="Automation Details"
      open={open}
      handleClose={() => setOpen(false)}
      sx={{ height: "auto", padding: "16px 24px 16px 24px" }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Toolbar
            variant="dense"
            sx={{
              paddingTop: "5px",
              paddingBottom: "5px",
              width: "100%",
              justifyContent: "end"
            }}
          >
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              sx={{
                float: "right"
              }}
              component="button"
              onClick={(e: any) => createNewTaker()}
            >
              Create New
            </Fab>
          </Toolbar>
        </Grid>
        <Grid item xs={12}>
          <MyRecentTakers builderId={builder.id} />
        </Grid>
      </Grid>
    </SimpleModalWrapper>
  );
}

const HOME_DEFAULT_FLAGS: FeatureFlags = {
  FEATURE_SHARE_TAKERS__enable_home_entrypoint: "FALSE"
};

const Home = () => {
  const [targetBuilder, setTargetBuilder] = useState<Builder | null>(null);
  const [takerModalOpen, setTakerModalOpen] = useState<boolean>(false);
  const { selectedOrgId, flagProvider } = useUserScopedAppData();
  const navigate = useNavigate();
  const [addBuilder, addBuilderResult] = useAddBuilderMutation();
  const { 
    authenticated,
    user
  } = useSelector((state: RootReducerType) => state.auth);
  
  const enableHomeEntrypoint = useMemo(
    () => flagProvider.populateFlagValues([
      "FEATURE_SHARE_TAKERS__enable_home_entrypoint"
    ]).FEATURE_SHARE_TAKERS__enable_home_entrypoint === "TRUE", 
    [flagProvider]
  ); 

  useEffect(() => {
    if (addBuilderResult.isSuccess) {
      navigate(`/mainBuilder/${addBuilderResult.data.id}`);
    }
  }, [addBuilderResult]);

  const createNewBuilder = () => {
    addBuilder({
      name: "new builder",
      description: "",
      organizationId: selectedOrgId
    });
  };

  return (
    <SideNav>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto'
        }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                width: "100%"
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Automations
                  </Typography>
                </Box>
                {user?.roles.includes('BUILDER') && (
                  <Fab
                    variant="extended"
                    color="primary"
                    size="medium"
                    sx={{ fontWeight: "bold" }}
                    component="button"
                    onClick={(e: any) => createNewBuilder()}
                  >
                    Create Automation
                  </Fab>
                )}
              </Box>
            </Box> 
        
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MyRecentBuilders
                onOpenBuilder={(bm: Builder) => {
                  setTargetBuilder(bm);
                  setTakerModalOpen(true);
                }}
              />
            </Box>
          </Grid>
          {enableHomeEntrypoint && (
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  width: "100%"
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Shared With You
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
          {enableHomeEntrypoint && (
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {authenticated ? (
                  <SharedTakers />
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        <ViewBuilderModal
          open={takerModalOpen}
          setOpen={setTakerModalOpen}
          builder={targetBuilder}
        />
      </Box>
    </SideNav >
  );
}

export default Home;