import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from "@mui/x-date-pickers";

interface DateQuestionProps {
    uniqueId: string;
    defaultValue:  Dayjs;
    onChangeValue: (a: any) => void;
    readOnly: boolean;
}

const DateQuestion = ({
    uniqueId,
    defaultValue,
    onChangeValue,
    readOnly
}: DateQuestionProps) => {
    return (
        <DatePicker
            data-testid={uniqueId}
            value={!!defaultValue ? dayjs(defaultValue) : null}
            onChange={(value: Dayjs | null) => {
                onChangeValue(value?.format("YYYY-MM-DD HH:mm:ss"));
            }}
            disabled={readOnly}
        />
    );
}

export default DateQuestion;

