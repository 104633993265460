import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  LexicalCommand,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { StatusBox } from './StatusBox';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';
import { DocumentAnnotation } from '../../../../types/taker/documentkeyterms.generated';
import { createRectsFromDOMRange } from '@lexical/selection';
import { getDOMRangeRect } from '../common';

import './index.css';

export interface EditHighlightCommandPayload {
  documentAnnotations: DocumentAnnotation[];
};

export const OPEN_STATUS_COMMAND: LexicalCommand<EditHighlightCommandPayload> = createCommand(
  'OPEN_STATUS_COMMAND',
);

interface EditAnnotationPluginProps {
  readOnly: boolean;
  onUpdateAnnotation: (
    kts: string[][],
    dkts: string[][],
    documentAnnotation: DocumentAnnotation[],
  ) => void;
}

export default function EditAnnotationPlugin({
  readOnly,
  onUpdateAnnotation
}: EditAnnotationPluginProps): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [showStatus, setShowStatus] = useState(false);
  const [currentPayload, setCurrentPayload] = useState<EditHighlightCommandPayload>();
  const [originalRangeRect, setOriginalRangeRect] = useState<DOMRect>();
  const [originalSelectionRects, setOriginalSelectionRects] = useState<ClientRect[]>();  
  const { documentKeyTermsService } = useKeyTermGroupState();

  const hideStatus = useCallback(() => {
    setShowStatus(false);
  }, [editor]);

  const defaultKeyTermNameLists = useMemo(() => {
    if (currentPayload && documentKeyTermsService.keyTerms) {
      const allDefaults = [];
      for (const tda of currentPayload.documentAnnotations) {
        allDefaults.push(documentKeyTermsService.keyTerms
          .filter(kt => kt.documentAnnotations?.some(da => tda.annotationId === da.annotationId))
          .map(kt => kt.termName));
      }
      return allDefaults;
    }
    return [];
  }, [documentKeyTermsService.keyTerms, currentPayload]);

  const submitUpdateAnnotation = useCallback(
    (
      keyTermNameLists: string[][],
    ) => {
      if (currentPayload) {
        onUpdateAnnotation(
          keyTermNameLists,
          defaultKeyTermNameLists,
          currentPayload.documentAnnotations
        );
      }
      setShowStatus(false);
    },
    [editor, defaultKeyTermNameLists, currentPayload],
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        OPEN_STATUS_COMMAND,
        (payload) => {
          const domSelection = window.getSelection();
          const rootElement = editor.getRootElement();          
          if (domSelection !== null && rootElement !== null) {
            const rangeRect = getDOMRangeRect(domSelection, rootElement);
            const selectionRects = createRectsFromDOMRange(editor, domSelection.getRangeAt(0));
            setOriginalRangeRect(rangeRect);
            setOriginalSelectionRects(selectionRects);
          }
          setShowStatus(true);
          setCurrentPayload(payload);

          if (domSelection !== null) {
            domSelection.removeAllRanges();
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    );
  }, [
    editor
  ]);

  return (
    <>
      {showStatus &&
        createPortal(
          <StatusBox
            readOnly={readOnly}
            editor={editor}
            hideStatus={hideStatus}
            defaultKeyTermNameLists={defaultKeyTermNameLists}
            submitUpdateAnnotation={submitUpdateAnnotation}
            originalRangeRect={originalRangeRect}
            originalSelectionRects={originalSelectionRects} 
          />,
          document.body,
        )}
    </>
  );
}