import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material/";
import { useEffect, useMemo } from "react";

export interface ChoiceType {
    label: string;
    value: any;
};

interface RadioQuestionProps {
    uniqueId: string;
    choices: ChoiceType[];
    defaultValue: any;
    onChangeValue: (a: any) => void;
    onResetField: () => void;
    readOnly: boolean;
}

const RadioQuestion = ({ 
    uniqueId,
    choices,
    defaultValue,
    onChangeValue,
    onResetField,
    readOnly
}: RadioQuestionProps) => {
    const error = useMemo(
        () => !defaultValue || defaultValue === "",
        [defaultValue]
    );

    const helperText = useMemo(
        () => error ? "Input is required" : null,
        [error]
    );

    useEffect(() => {
        // If value is populated and choices don't include the value, then reset 
        let fieldIsUnset = (defaultValue === undefined || defaultValue === null)
        if (!fieldIsUnset && !choices.some(c => c.value === defaultValue)) {
            onResetField();
            //console.log(`do reset for value=${defaultValue} and available choices`);
        }
    }, [choices.length]);
    
    return (
        <FormControl
            error={error}
            data-testid={`${uniqueId}-radioQuestion`}
        >
            <RadioGroup   
                id={uniqueId}    
                value={defaultValue}
                onChange={(event) => {
                    onChangeValue(event.target.value);
                }}
            >
                {choices.map((c) => (
                    <FormControlLabel 
                        color="red"
                        value={c.value} 
                        control={
                            <Radio 
                                disabled={readOnly}
                            />
                        } 
                        label={c.label} 
                    />
                ))}
            </RadioGroup>
            {helperText && (
                <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
}

export default RadioQuestion;
