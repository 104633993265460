import React, { useEffect } from "react";
import {
    TextField,
    Box,
    IconButton
} from "@mui/material/";
import { Cancel, Save } from "@mui/icons-material";

interface EditableSummaryProps {
    expandSummary: boolean;
    autoSave?: boolean;
    defaultValue: string;
    importedText?: string;
    importedTextChanges?: boolean;
    onChangeValue: (s: string) => void;
    readOnly: boolean;
}

const EditableSummary = ({
    expandSummary,
    autoSave,
    defaultValue,
    importedText,
    importedTextChanges,
    onChangeValue,
    readOnly,
}: EditableSummaryProps) => {
    const [editing, setEditing] = React.useState(false);
    const [editedState, setEditedState] = React.useState(defaultValue);

    useEffect(() => {
        setEditedState(defaultValue);
    }, [defaultValue])

    useEffect(() => {
        if (importedText && editedState !== importedText) {
            setEditing(true);
        }
    }, [importedTextChanges])

    return (
        <Box
            sx={{
                width: "100%",
                display: "inline-flex",
                alignItems: "center"
            }}
        >
            {editing ? (
                <TextField
                    data-testid="editable-summary-text"
                    id="editable-summary-text"
                    size="medium"
                    value={editedState}
                    onChange={(e: any) => {
                        if (!readOnly && !autoSave) {
                            setEditedState(e.target.value);
                        } else if (!readOnly && autoSave) {
                            setEditedState(e.target.value);
                            onChangeValue(e.target.value);
                        }
                    }}
                    variant="outlined"
                    multiline
                    fullWidth
                    InputProps={{
                        readOnly,
                        autoComplete: "off"
                    }}
                />
            ) : (
                <Box
                    padding={1}
                    paddingLeft={2}
                    onClick={() => {
                        if (!readOnly) {
                            setEditing(true);
                        }
                    }}
                >
                    <em>
                        {expandSummary ?
                            (editedState || "none") :
                            `${(editedState || "none").substring(0, 40)}...`
                        }
                    </em>
                </Box>
            )}
            {editing && !autoSave && (
                <IconButton
                    data-testid="save-summary-button"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                        onChangeValue(editedState);
                        setEditing(false);
                    }}
                    color="info"
                >
                    <Save />
                </IconButton>
            )}
            {editing && !autoSave && (
                <IconButton
                    data-testid="cancel-summary-button"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                        setEditedState(defaultValue);
                        setEditing(false);
                    }}
                    color="error"
                >
                    <Cancel />
                </IconButton>
            )}
        </Box>
    );
};

export default EditableSummary;