import { 
  AccountCircle, 
  AdminPanelSettings, 
  DataObject, 
  Home, 
  Logout, 
  Psychology, 
  QuestionMark,
  DataObjectOutlined
} from "@mui/icons-material";

export interface PageItem {
  route: string;
  icon?: any;
  name?: string;
}

export const pageOption: Record<string, PageItem> = {
  SIGN_IN: {
    route: "/",
    icon: Logout,
    name: "Sign Out"
  },
  HOME: {
    route: "/home",
    icon: Home,
    name: "Home"
  },
  MAIN_BUILDER: {
    route: "/mainBuilder/:id",
  },
  MAIN_TAKER: {
    route: "/mainTaker/:id",
  },
  TAKER_KEY_TERMS: {
    route: "/mainTaker/:id/keyTerms",
  },
  TAKER_ANALYSIS: {
    route: "/mainTaker/:id/analysis",
  },
  TAKER_REPORT: {
    route: "/mainTaker/:id/report",
  },
  ERROR: {
    route: "/error",
    icon: QuestionMark,
    name: "Error"
  },
  MANAGE_USERS: {
    route: "/manageUsers",
    icon: AdminPanelSettings,
    name: "Manage Users"
  },
  MANAGE_ORGANIZATIONS: {
    route: "/manageOrganizations",
    icon: AdminPanelSettings,
    name: "Manage Organizations"
  },
  USER_SETTINGS: {
    route: "/userSettings",
    icon: AccountCircle,
    name: "Account Settings",
  },
  MANAGE_APP_CONFIGS: {
    route: "/manageAppConfigs",
    icon: DataObject,
    name: "Application Configs"
  },
  MANAGE_MODELS: {
    route: "/manageModels",
    icon: Psychology,
    name: "Language Models"
  },
  LANGUAGE_MODEL: {
    route: "/languageModel/:id",
  },
  INDEXED_GUIDANCE_LIST: {
    route: "/indexedGuidanceList",
    icon: DataObjectOutlined,
    name: "Indexed Guidance"
  },
  INDEXED_GUIDANCE: {
    route: "/indexedGuidance/:id"
  },
  ASSISTANT: {
    route: "/assistant/:id",
  }
};

