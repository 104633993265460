import {
    TextField,
} from "@mui/material/";
import { useMemo } from "react";

interface IntegerQuestionProps {
    uniqueId: string;
    defaultValue: any;
    onChangeValue: (a: any) => void;
    readOnly: boolean;
}

const IntegerQuestion = ({
    uniqueId,
    defaultValue,
    onChangeValue,
    readOnly
}: IntegerQuestionProps) => {
    const error = useMemo(
        () => !defaultValue || defaultValue === "",
        [defaultValue]
    );

    const helperText = useMemo(
        () => error ? "Input is required" : null,
        [error]
    );

    return (
        <TextField
            id={uniqueId}
            value={defaultValue}
            error={error}
            helperText={helperText}
            onChange={(event) => {
                onChangeValue(event.target.value);
            }}
            type="number"
            inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                readOnly
            }}
            FormHelperTextProps={{
                sx: { marginLeft: 0 }
            }}
            variant="outlined"
        />
    );
}

export default IntegerQuestion;


