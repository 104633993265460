import React from 'react';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import CommentaryDisplay from './CommentaryDisplay';
import { useReadOnlyBuilderData } from '../../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData';
import { collectHeadersAndContentForTarget, collectHeadersForTarget, updateCommentaryWithHeadersAndContent } from './common';
import { CommentaryOrGuidanceReference } from '../../../../types/taker/uidatastate.generated';
import GuidanceSearch from './GuidanceSearch';
import { Commentary } from '../../../../types/builderv2.generated';

interface SearchGuidanceProps {
    commentary?: Commentary;
}

const ResearchGuidance = ({
    commentary
}: SearchGuidanceProps) => {
    const {
        getContentFromGuidance,
        getRefFromGuidance,
        packageByGuidanceId,
        guidanceIdLookup,
        aliasLookup
    } = useReadOnlyBuilderData();

    const [allReferences, setAllReferences] = useState<CommentaryOrGuidanceReference[]>([]);

    const [allCommentary, setAllCommentary] = useState<Commentary>([]);

    useEffect(() => {
        if (commentary) {
            const initialRefs: CommentaryOrGuidanceReference[] = [];
            for (const c of commentary) {
                if (c.type === "custom-guidance-container" && c.value) {
                    if (c.nested) {
                        for (const n of c.nested) {
                            if (n.value) {
                                if (aliasLookup[n.value]) {
                                    initialRefs.push({
                                        id: aliasLookup[n.value],
                                        type: "CUSTOM_GUIDANCE"
                                    });
                                } else  {
                                    initialRefs.push({
                                        id: n.value,
                                        type: "CUSTOM_GUIDANCE"
                                    });
                                }
                            }
                        }
                    }
                } else if (c.type === "citation" || c.type === "insight") {
                    if (c.nested) {
                        for (const n of c.nested) {
                            initialRefs.push({
                                id: n.id,
                                type: "COMMENTARY"
                            });
                        }
                    }
                }
            }
            setAllReferences(initialRefs);
        }
    }, [
        commentary
    ]);
    

    useEffect(() => {
        (async () => {
            let tfCommentary: Commentary = [];
            for (const v of allReferences) {
                if (v.type === "CUSTOM_GUIDANCE") {
                    const guidanceContentId = v.id;
                    const guidanceInfo = guidanceIdLookup[guidanceContentId];
                    if (guidanceInfo) {
                        const guidancePackage = packageByGuidanceId[guidanceInfo.id];
                        const refs = await getRefFromGuidance(guidancePackage);
                        if (guidanceContentId && refs) {
                            const content = await getContentFromGuidance(guidancePackage, guidanceContentId);
                            collectHeadersForTarget(refs.labeledRefs, [], guidanceContentId, (headers) => {
                                tfCommentary = updateCommentaryWithHeadersAndContent(
                                    JSON.parse(JSON.stringify(tfCommentary)),
                                    headers,
                                    content['lexical_content'],
                                    guidanceContentId
                                );
                            });
                        }
                    }
                } else if (v.type === "COMMENTARY") {
                    const contentId = v.id;
                    if (contentId) {
                        collectHeadersAndContentForTarget(commentary, [], contentId, (content, headers) => {
                            if (content) {
                                tfCommentary = updateCommentaryWithHeadersAndContent(
                                    JSON.parse(JSON.stringify(tfCommentary)),
                                    headers,
                                    content,
                                    contentId
                                );
                            }
                        });
                    }
                }
            }
            setAllCommentary(tfCommentary);
        })();
    }, [allReferences, packageByGuidanceId, guidanceIdLookup]);

    return (
        <Stack>
            <GuidanceSearch
                commentary={commentary}
                onAdd={(commentaryOrGuidanceReference) => {
                    let newReferences = [...allReferences];
                    newReferences.push(commentaryOrGuidanceReference);
                    setAllReferences(newReferences);
                }}
            />
            <Box position="relative">
                {allCommentary && allCommentary.length > 0 && (
                    <Box height="100%" overflow="auto">
                        <CommentaryDisplay
                            commentary={allCommentary}
                            onRemove={(id) => {
                                setAllReferences(allReferences.filter(r => r.id !== id));
                            }}
                            displayLexical
                        />
                    </Box>
                )}
            </Box>
        </Stack>
    );
};

export default ResearchGuidance;

