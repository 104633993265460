import {
    Box,
    Button
} from "@mui/material/";
import { useInitializeIndexedGuidanceFromPackageMutation, useUpdateIndexedGuidanceMutation } from "../../redux/services/indexedGuidance";
import * as yup from "yup";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { IndexedGuidance } from "../../redux/models/dataModelTypes";
import { useState } from "react";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

interface formInputs {
    guidanceId: string;
    description: string;
    canonicalLabel: string;
}

interface UpdateIndexedGuidanceDetailsProps {
    indexedGuidance: IndexedGuidance;
}

const UpdateIndexedGuidanceDetails = ({
    indexedGuidance
}: UpdateIndexedGuidanceDetailsProps) => {
    const [updateIndexedGuidance, updateIndexedGuidanceRes] = useUpdateIndexedGuidanceMutation();
    const [initializeIndexedGuidanceFromPackage, initializeIndexedGuidanceFromPackageRes] = useInitializeIndexedGuidanceFromPackageMutation();
    const [updateDetailsOpen, setUpdateDetailsOpen] = useState<boolean>(false);

    const schema = yup.object().shape({
        guidanceId: yup.string().required(),
        description: yup.string().required(),
        canonicalLabel: yup.string().nullable()
    });

    const defaultValues = {
        guidanceId: indexedGuidance.guidanceId,
        description: indexedGuidance.description,
        canonicalLabel: indexedGuidance.canonicalLabel
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<formInputs>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const handleSubmitCust = (d: formInputs) => {
        updateIndexedGuidance({
            id: indexedGuidance.id,
            guidanceId: d.guidanceId,
            name: indexedGuidance.name,
            description: d.description,
            canonicalLabel: d.canonicalLabel,
            referenceMap: indexedGuidance.referenceMap
        });
        setUpdateDetailsOpen(false);
    };

    return (
        <>
            <Button
                size="small"
                variant="contained"
                sx={{ marginLeft: "15px" }}
                disabled={updateIndexedGuidanceRes.isLoading}
                onClick={() => {
                    if (indexedGuidance) {
                        setUpdateDetailsOpen(true);
                    }
                }}
            >
                Update Details
            </Button>
            <SimpleModalWrapper
                headerText="Update Guidance Details"
                open={updateDetailsOpen}
                handleClose={() => setUpdateDetailsOpen(false)}
                sx={{ width: "50vw", height: "auto", padding: "16px 24px 16px 24px" }}
            >
                <Box>
                    <form
                        onSubmit={handleSubmit(handleSubmitCust)}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <TextFieldRhf
                            label="Guidance ID"
                            fieldName="guidanceId"
                            sx={{ minHeight: "71px" }}
                            control={control}
                            errors={errors}

                        />
                        <TextFieldRhf
                            label="Description"
                            fieldName="description"
                            sx={{ minHeight: "71px" }}
                            control={control}
                            errors={errors}
                        />
                        <TextFieldRhf
                            label="Canonical Label"
                            fieldName="canonicalLabel"
                            sx={{ minHeight: "71px" }}
                            control={control}
                            errors={errors}
                        />
                        <Box sx={{ paddingTop: "20px" }}>
                            <Button
                                disabled={initializeIndexedGuidanceFromPackageRes.isLoading}
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    initializeIndexedGuidanceFromPackage(indexedGuidance.id);
                                }}
                            >
                                {initializeIndexedGuidanceFromPackageRes.isLoading ? 
                                    "Resetting" :
                                    "Reset From Package File"
                                }
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "20px",
                                textAlign: "right"
                            }}
                        >
                            <Button
                                variant="contained"
                                type="submit">
                                Update
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ marginLeft: "10px" }}
                                onClick={() => setUpdateDetailsOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </form>
                </Box>
            </SimpleModalWrapper >
        </>

    );
}

export default UpdateIndexedGuidanceDetails;
