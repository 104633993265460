import { useCallback, useEffect, useMemo, useState } from 'react';
import { SimpleModalWrapper } from '../../../../components/dialog/wrappers/simpleModalWrapper';
import { Box, Button, Grid, MenuItem, IconButton, Checkbox, ListItemText, Menu, Typography } from '@mui/material';
import { MoreHorizOutlined, ViewComfyRounded } from '@mui/icons-material';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { Commentary, TableWithSingleAnalysisDataSpec } from '../../../../types/builderv2.generated';
import CurrentTableAnswerCard from './CurrentTableAnswerCard';
import { Choices1 } from '../../../../types/taker/fulfillmentstate.generated';
import { ColumnType, PrimitiveType } from '../TableComponent';
import ResearchCard from './ResearchCard';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../../../types/taker/uidatastate.generated';
import { TableDetailModalState } from '../../../../containers/WidgetWrapper/states';
import TableAiGeneratedCard from './TableAiGeneratedCard';

interface TableDetailsModalProps {
    moduleIds: string[];
    commentary?: Commentary;
    dataTypeMap: Record<string, PrimitiveType>;
    tableLabel?: string;
    tabelText?: {
        text: string;
        rendered: boolean;
    };
    renderedQuestionChoices?: {
        dataSpecFieldName: string;
        choices: Choices1[];
        rendered: boolean;
    }[];
    validationWarnings?: string[];
    injectedTableRows?: Record<string, string>[];
    columns: ColumnType[],
    dataSpec: TableWithSingleAnalysisDataSpec;
    defaultRows: any[][];
    readOnly: boolean;
}

const buildInitialTableDetailsModalState = (takerDocumentId: string) => {
    const savedState = localStorage.getItem(`TableDetailModalState-${takerDocumentId}`);
    const tableDetailModalState = (!!savedState && JSON.parse(savedState)) || {
        showCurrentInput: true,
        showResearch: true,
    };
    return tableDetailModalState;
}

const TableDetailsModal = ({
    moduleIds,
    commentary,
    dataTypeMap,
    tableLabel,
    tabelText,
    renderedQuestionChoices,
    validationWarnings,
    injectedTableRows,
    columns,
    dataSpec,
    defaultRows,
    readOnly
}: TableDetailsModalProps) => {
    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { 
        takerDocumentId, 
        questionnareDataService, 
        isTakerStateDirty 
    } = useTakerState();

    const [localCols, setLocalCols] = useState<Record<string, any[]>>({});

    const uniqueId = useMemo(() => moduleIds.join('_'), [moduleIds]);

    const [analysis, setAnalysis] = useState<string>(
        dataSpec.otherDataSpecFieldName &&
        questionnareDataService.getAnalysis(moduleIds, dataSpec.otherDataSpecFieldName, null) || ""
    );

    const [reference, setReference] = useState<CommentaryOrGuidanceReference[]>(
        dataSpec.otherDataSpecFieldName &&
        questionnareDataService.getReference(moduleIds, dataSpec.otherDataSpecFieldName, null) || []
    );

    const [source, setSource] = useState<KeyTermSource[]>(
        dataSpec.otherDataSpecFieldName &&
        questionnareDataService.getSource(moduleIds, dataSpec.otherDataSpecFieldName, null) || []
    );

    const [widgetState, setWidgetState] = useState<TableDetailModalState>(buildInitialTableDetailsModalState(takerDocumentId));

    /* Sync with localStorage */
    useEffect(() => {
        if (!!widgetState) {
            localStorage.setItem(`TableDetailModalState-${takerDocumentId}`, JSON.stringify(widgetState));
        }
    }, [widgetState]);

    useEffect(() =>
        setWidgetState(buildInitialTableDetailsModalState(takerDocumentId)),
        [open]
    );

    const updateAll = useCallback(() => {
        const nameValuePairs = [];
        for (const [name, d] of Object.entries(localCols)) {
            nameValuePairs.push({ name, value: d });
        }
        questionnareDataService.updateAnswers(moduleIds, nameValuePairs, null);
        if (dataSpec.otherDataSpecFieldName) {
            questionnareDataService.updateAnalysis(moduleIds, dataSpec.otherDataSpecFieldName, analysis, null);
            questionnareDataService.updateReference(moduleIds, dataSpec.otherDataSpecFieldName, reference, null);
            questionnareDataService.updateSource(moduleIds, dataSpec.otherDataSpecFieldName, source, null);
        }
        setOpen(false);
    }, [
        dataSpec,
        localCols, 
        questionnareDataService,
        analysis,
        reference,
        source
    ]);

    const resetAndClose = () => {
        setOpen(false);
        setLocalCols({});
        if (dataSpec.otherDataSpecFieldName) {
            setAnalysis(questionnareDataService.getAnalysis(moduleIds, dataSpec.otherDataSpecFieldName, null) || "");
            setReference(questionnareDataService.getReference(moduleIds, dataSpec.otherDataSpecFieldName, null) || []);
            setSource(questionnareDataService.getSource(moduleIds, dataSpec.otherDataSpecFieldName, null) || []);
        }
    };

    const panels = [];
    if (widgetState.showCurrentInput) {
        panels.push(
            <CurrentTableAnswerCard
                moduleIds={moduleIds}
                commentary={commentary}
                dataTypeMap={dataTypeMap}
                tableLabel={tableLabel}
                tabelText={tabelText}
                renderedQuestionChoices={renderedQuestionChoices}
                validationWarnings={validationWarnings}
                injectedTableRows={injectedTableRows}
                columns={columns}
                defaultRows={defaultRows}
                onChangeColumns={setLocalCols}
                analysis={analysis}
                setAnalysis={setAnalysis}
                reference={reference}
                setReference={setReference}
                source={source}
                setSource={setSource}
                readOnly={readOnly}
            />
        );
    }
    if (widgetState?.showAIGeneratedInput) {
        panels.push(
            <TableAiGeneratedCard
                moduleIds={moduleIds}
                commentary={commentary}
                dataTypeMap={dataTypeMap}
                tableLabel={tableLabel}
                tabelText={tabelText}
                renderedQuestionChoices={renderedQuestionChoices}
                validationWarnings={validationWarnings}
                injectedTableRows={injectedTableRows}
                columns={columns}
                dataSpec={dataSpec}
            />
        );
    }
    if (widgetState.showResearch) {
        panels.push(
            <ResearchCard
                commentary={commentary}
                enableGuidance={true}
            />
        );
    }

    return (
        <>
            <IconButton
                id={`tableDetails_${uniqueId}`}
                color="inherit"
                onClick={() => setOpen(true)}
                disabled={isTakerStateDirty}
            >
                <MoreHorizOutlined />
            </IconButton>
            <SimpleModalWrapper
                headerText={"Table Details"}
                open={open}
                handleClose={() => resetAndClose()}
                sx={{
                    width: "90vw",
                    height: "90vh",
                    padding: "16px 24px 16px 24px"
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    height='calc(100% - 16px)'
                    width='100%'
                    paddingTop={1}
                >
                    <Box
                        sx={{
                            padding: 1,
                            width: "100%",
                            alignItems: "center",
                            display: "flex",
                            border: "1px solid #ddd",
                            borderRadius: 1,
                            backgroundColor: "rgba(221,221,221,0.25)"
                        }}
                    >
                        <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                        >
                            <Grid item xs={4} justifyContent="start" padding={1}>
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                                        setAnchorEl(event.currentTarget);
                                    }}
                                    sx={{
                                        padding: "0px",
                                    }}
                                >
                                    <ViewComfyRounded />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem key="Current" >
                                        <Checkbox
                                            checked={widgetState.showCurrentInput}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setWidgetState({
                                                    ...widgetState,
                                                    showCurrentInput: event.target.checked
                                                });
                                            }}
                                        />
                                        <ListItemText primary="Current" />
                                    </MenuItem>
                                    <MenuItem key="AI Generated" >
                                        <Checkbox
                                            checked={widgetState.showAIGeneratedInput}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setWidgetState({
                                                    ...widgetState,
                                                    showAIGeneratedInput: event.target.checked
                                                });
                                            }}
                                        />
                                        <ListItemText primary="AI Generated" />
                                    </MenuItem>
                                    <MenuItem key="Research" >
                                        <Checkbox
                                            checked={widgetState.showResearch}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setWidgetState({
                                                    ...widgetState,
                                                    showResearch: event.target.checked
                                                });
                                            }}
                                        />
                                        <ListItemText primary="Research" />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                            <Grid item xs={4} textAlign="center">
                                <Typography variant="body1">
                                    {tableLabel}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        width='100%'
                        overflow='hidden'
                        flexGrow={1}
                        paddingTop={1}
                    >
                        <Grid
                            container
                            height='calc(100% - 16px)'
                            spacing={1}
                        >
                            {panels.length === 1 && (
                                <Grid item xs={12} height="100%">
                                    {panels[0]}
                                </Grid>
                            )}
                            {panels.length === 2 && (
                                <>
                                    <Grid item xs={6} height="100%">
                                        {panels[0]}
                                    </Grid>
                                    <Grid item xs={6} height="100%">
                                        {panels[1]}
                                    </Grid>
                                </>
                            )}
                            {panels.length === 3 && (
                                <>
                                    <Grid item xs={6} height="50%">
                                        {panels[0]}
                                    </Grid>
                                    <Grid item xs={6} height="50%">
                                        {panels[1]}
                                    </Grid>
                                    <Grid item xs={12} height="50%">
                                        {panels[2]}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    <Box
                        width='100%'
                        paddingTop={1}
                        paddingBottom='16px'
                        overflow='hidden'
                    >
                        {!readOnly && (
                            <Button
                                sx={{ float: "right" }}
                                variant="contained"
                                onClick={() => updateAll()}
                            >
                                Save
                            </Button>
                        )}
                        <Button
                            sx={{
                                marginRight: "4px",
                                float: "right"
                            }}
                            variant="outlined"
                            onClick={() => resetAndClose()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </SimpleModalWrapper>
        </>
    );
}

export default TableDetailsModal;