import React, { CSSProperties } from "react";
import { 
  Dialog, 
  Box, 
  IconButton, 
  Typography,
  Stack
} from "@mui/material/";
import { DeleteIcon } from "./../../../assets/icons";

export interface SimpleModalWrapperProps {
  open: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  disableBackdropClick?: boolean;
  headerText: string;
  children: JSX.Element | JSX.Element[];
  sx?: CSSProperties;
  extraHeader?: JSX.Element;
}

export const SimpleModalWrapper = (props: SimpleModalWrapperProps) => {
  const { 
    handleClose, 
    open, 
    children,
    headerText, 
    sx,
    extraHeader,
    disableBackdropClick=false
  } = props;
  let onClick = disableBackdropClick ? () => {} : handleClose;
  return (
    <Dialog onClose={onClick} open={open} maxWidth={false}>
      <Box sx={sx ?? {}}>
        <Box>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: "bold" }}>{headerText}</Typography>
            {extraHeader && extraHeader}
          </Stack>
          <IconButton
            data-testid="close-modal"
            size="small"
            sx={{
              position: "absolute",
              right: 2,
              top: 2,
            }}
            onClick={handleClose}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};
