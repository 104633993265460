import { Autocomplete, IconButton, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useReadOnlyBuilderData } from '../../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData';
import ListboxComponent from './ListboxComponent';
import StyledPopper from './StyledPopper';
import { Add } from '@mui/icons-material';
import { CommentaryOrGuidanceReference } from '../../../../types/taker/uidatastate.generated';
import { Commentary } from '../../../../types/builderv2.generated';
import { DisplayGuidance, collectAllHeaders, collectCommentary, collectHeaders } from './common';

interface GuidanceSearchProps {
    commentary?: Commentary;
    onAdd: (s: CommentaryOrGuidanceReference) => void;
}

interface SearchOption {
    id: string;
    guidanceId?: string;
    useCommentary: boolean;
    display: string;
}

const GuidanceSearch = ({
    commentary,
    onAdd
}: GuidanceSearchProps) => {
    const {
        builderDocumentGuidances,
        guidanceIdLookup
    } = useReadOnlyBuilderData();
    const [inputValue, setInputValue] = useState<string>('');
    const [value, setValue] = useState<DisplayGuidance | null>();

    const searchOptions = useMemo(() => {
        const defaultOptions: SearchOption[] = [];
        if (commentary && commentary.length > 0) {
            defaultOptions.push({
                id: "commentary",
                useCommentary: true,
                display: "Commentary"
            });
        }
        for (const bdg of builderDocumentGuidances) {
            if (bdg.indexedGuidance) {
                defaultOptions.push({
                    id: bdg.id,
                    guidanceId: bdg.indexedGuidance.guidanceId,
                    useCommentary: true,
                    display: bdg.indexedGuidance.name
                });
                defaultOptions.push({
                    id: `${bdg.id}-all`,
                    guidanceId: bdg.indexedGuidance.guidanceId,
                    useCommentary: false,
                    display: `${bdg.indexedGuidance.name} (all)`
                });
            }
        }
        return defaultOptions;
    }, [commentary, builderDocumentGuidances]);
    
    const [selectedSearchId, setSelectedSearchId] = useState<string | undefined>(() => {
        if (searchOptions.length > 0) {
            return searchOptions[0].id;
        }
    });

    const searchSelection: SearchOption | undefined = useMemo(() => {        
        for (const so of searchOptions) {
            if (so.id === selectedSearchId) {
                return so;
            }
        }
    }, [selectedSearchId, searchOptions]);

    const collectValues = (commentary: Commentary, allValues: Set<string>) => {
        for (const c of commentary) {
            if (c.nested && c.nested.length > 0) {
                collectValues(c.nested, allValues);
            } else if (c.value) {
                allValues.add(c.value);
            }
        }
    };

    const autocompleteOptions: DisplayGuidance[] = useMemo(() => {
        if (!searchSelection) {
            return [];
        }

        // Commentary option
        if (searchSelection.id === "commentary") {
            const plainCommentary = commentary && 
                commentary.filter(c => c.type !== "custom-guidance-container");
            
            const allHeaders: DisplayGuidance[] = [];
            collectCommentary(plainCommentary, [], allHeaders);
            return allHeaders;
        }

        // A guidance ID should be available in this case
        if (!searchSelection.guidanceId) {
            return [];
        }

        for (const bdg of builderDocumentGuidances) {
            if (!bdg.indexedGuidance) {
                continue;
            }

            const guidanceId = bdg.indexedGuidance.guidanceId;
            if (guidanceId === searchSelection.guidanceId) {
                const refs = bdg.indexedGuidance.referenceMap;
                const allHeaders: DisplayGuidance[] = [];
                if (commentary && searchSelection.useCommentary) {
                    const allValues = new Set<string>();
                    collectValues(
                        commentary.filter(
                            c => c.type === "custom-guidance-container" && 
                            c.value === guidanceId
                        ),
                        allValues
                    );
                    collectHeaders(allValues, refs.labeledRefs, [], allHeaders);
                } else if (!searchSelection.useCommentary) {
                    collectAllHeaders(refs.labeledRefs, [], allHeaders);
                }
                return allHeaders;
            }
        }
        return [];
    }, [searchSelection]);

    return (
        <Stack direction="row" spacing={1}>
            <IconButton
                data-testid="add-guidance-button"
                disabled={!value}
                color="primary"
                onClick={() => {
                    if (value) {
                        const guidanceInfo = guidanceIdLookup[value.id];
                        if (guidanceInfo) {
                            onAdd({
                                id: value.id,
                                data: {
                                    parentId: guidanceInfo.id,
                                    packageTag: guidanceInfo.packageTag,
                                    label: guidanceInfo.label,
                                    alias: value.display
                                },
                                type: "CUSTOM_GUIDANCE"
                            });
                        } else {
                            onAdd({
                                id: value.id,
                                data: {
                                    alias: value.display
                                },
                                type: "COMMENTARY"
                            });
                        }
                    }
                }}
            >
                <Add />
            </IconButton>
            <Select
                size="small"
                value={selectedSearchId}
                label=""
                placeholder="Search Additional Guidance"
                data-testid="guidance-select"
                onChange={(e) => {
                    setSelectedSearchId(e.target.value);
                    setInputValue('');
                    setValue(null);
                }}
            >
                {searchOptions.map(o => (
                    <MenuItem value={o.id}>
                        {o.display}
                    </MenuItem>
                ))}
            </Select>
            <Autocomplete
                size="small"
                sx={{ minWidth: "330px" }}
                disableListWrap
                ListboxComponent={ListboxComponent}
                PopperComponent={StyledPopper}
                getOptionLabel={(option) => option.display}
                filterOptions={(x) =>
                    x.filter(
                        h => h.display.includes(inputValue) || h.group.includes(inputValue)
                    )
                }
                options={autocompleteOptions}
                filterSelectedOptions
                noOptionsText="None"
                inputValue={inputValue}
                value={value}
                onChange={(event: any, newValue: DisplayGuidance | null) => {
                    setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        label={!!commentary ? "Guidance or Commentary" : "Guidance"}
                        fullWidth 
                    />
                }
                renderOption={(props, option, state) =>
                    [props, option, state.index] as React.ReactNode
                }
                groupBy={(option) => option.group}
                renderGroup={(params) => params as any}
            />
        </Stack>
    );
};

export default GuidanceSearch;