import React from 'react';
import { TextField } from '@mui/material';

interface AnalysisFieldProps {
    readOnly: boolean;
    defaultValue: string
    onUpdate: (s: string) => void;
}

const AnalysisField = ({
    readOnly,
    defaultValue,
    onUpdate
}: AnalysisFieldProps) => {
    return (
        <TextField
            data-testid="analysis-field"
            placeholder="Enter Analysis"
            value={defaultValue}
            onChange={(e) => {
                e.preventDefault();
                onUpdate(e.target.value)
            }}
            multiline={true}
            fullWidth
            variant="outlined"
            InputProps={{ readOnly }}
        />
    );
}

export default AnalysisField;