import { memo, useEffect, useMemo, useState } from 'react';
import { SimpleModalWrapper } from '../../../../components/dialog/wrappers/simpleModalWrapper';
import { Box, Button, Grid, MenuItem, IconButton, Menu, Checkbox, ListItemText, Typography } from '@mui/material';
import ViewComfyRoundedIcon from '@mui/icons-material/ViewComfy';
import { MoreHorizOutlined } from '@mui/icons-material';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { Commentary, DisplayedVariableReference, DisplayedVariableReferences, StaticChoices } from '../../../../types/builderv2.generated';
import AiGeneratedCard from './AiGeneratedCard';
import CurrentAnswerCard from './CurrentQuestionAnswerCard';
import { GraphFulfillmentState } from '../../../../types/taker/fulfillmentstate.generated';
import { PrimitiveType } from '../QuestionAnswer';
import ResearchCard from './ResearchCard';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../../../types/taker/uidatastate.generated';
import { QuestionDetailModalState } from '../../../../containers/WidgetWrapper/states';

export type AnalysisFieldState = "DISABLED" | "OPTIONAL" | "REQUIRED";
export type ReferencesState = "DISABLED" | "OPTIONAL";

interface QuestionDetailsModalProps {
    uniqueId: string;
    moduleIds: string[];
    iteration: number | null;
    questionId: string;
    questionCommentary?: Commentary;
    analysisState?: AnalysisFieldState;
    referencesState?: ReferencesState;
    graphFulfillmentState: GraphFulfillmentState;
    nodeId: string;
    dataType: PrimitiveType;
    questionLabel: string;
    questionText: string | null;
    questionType: "radio" | "multiselect" | "input";
    choices?: StaticChoices | DisplayedVariableReference | DisplayedVariableReferences;
    readOnly: boolean;
}

const buildInitialQuestionDetailsModalState = (takerDocumentId: string) => {
    const savedState = localStorage.getItem(`QuestionDetailModalState-${takerDocumentId}`);
    const questionDetailModalState = (!!savedState && JSON.parse(savedState)) || {
        showCurrentInput: true,
        showAIGeneratedInput: true,
        showResearch: true,
    };
    return questionDetailModalState;
}

const QuestionDetailsModal = ({
    uniqueId,
    moduleIds,
    iteration,
    questionId,
    questionCommentary,
    analysisState = "OPTIONAL",
    referencesState = "OPTIONAL",
    graphFulfillmentState,
    nodeId,
    dataType,
    questionType,
    questionLabel,
    questionText,
    choices,
    readOnly
}: QuestionDetailsModalProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false);

    const { 
        questionnareDataService, 
        takerDocumentId,
        isTakerStateDirty
    } = useTakerState();

    const [answer, setAnswer] = useState(
        questionnareDataService.getAnswer(moduleIds, questionId, iteration) || ""
    );
    const [analysis, setAnalysis] = useState<string>(
        questionnareDataService.getAnalysis(moduleIds, questionId, iteration) || ""
    );

    let currentAnswer = questionnareDataService.getAnswer(moduleIds, questionId, iteration);

    const [reference, setReference] = useState<CommentaryOrGuidanceReference[]>(
        questionnareDataService.getReference(moduleIds, questionId, iteration) || []
    );

    const [source, setSource] = useState<KeyTermSource[]>(
        questionnareDataService.getSource(moduleIds, questionId, iteration) || []
    );

    const enableAnalysis = useMemo(
        () => analysisState === "OPTIONAL" || analysisState === "REQUIRED",
        [analysisState]
    );

    const enableGuidance = useMemo(
        () => referencesState === "OPTIONAL",
        [referencesState]
    );

    const [widgetState, setWidgetState] = useState<QuestionDetailModalState>(buildInitialQuestionDetailsModalState(takerDocumentId));

    /* Sync with localStorage */
    useEffect(() => {
        if (!!widgetState) {
            localStorage.setItem(`QuestionDetailModalState-${takerDocumentId}`, JSON.stringify(widgetState));
        }
    }, [widgetState]);

    useEffect(() =>
        setWidgetState(buildInitialQuestionDetailsModalState(takerDocumentId)),
        [open]
    );

    useEffect(() => {
        if (!!currentAnswer) {
            setAnswer(currentAnswer)
        }
    }, [currentAnswer])


    const updateAll = () => {
        questionnareDataService.updateAnswer(moduleIds, questionId, answer, iteration);
        questionnareDataService.updateAnalysis(moduleIds, questionId, analysis, iteration);
        questionnareDataService.updateReference(moduleIds, questionId, reference, iteration);
        questionnareDataService.updateSource(moduleIds, questionId, source, iteration);
        setOpen(false);
    };

    const resetAndClose = () => {
        setOpen(false);
        setAnswer(questionnareDataService.getAnswer(moduleIds, questionId, iteration));
        setAnalysis(questionnareDataService.getAnalysis(moduleIds, questionId, iteration) || "");
        setReference(questionnareDataService.getReference(moduleIds, questionId, iteration) || []);
        setSource(questionnareDataService.getSource(moduleIds, questionId, iteration) || []);
    };

    const panels = [];
    if (widgetState.showCurrentInput) {
        panels.push(
            <CurrentAnswerCard
                uniqueId={uniqueId}
                graphFulfillmentState={graphFulfillmentState}
                nodeId={nodeId}
                dataType={dataType}
                questionText={questionText}
                questionType={questionType}
                choices={choices}
                answer={answer}
                setAnswer={setAnswer}
                analysis={analysis}
                setAnalysis={setAnalysis}
                reference={reference}
                setReference={setReference}
                source={source}
                setSource={setSource}
                enableCommentary={enableGuidance}
                enableAnalysis={enableAnalysis}
                commentary={questionCommentary}
                readOnly={readOnly}
            />
        );
    }
    if (widgetState.showAIGeneratedInput) {
        panels.push(
            <AiGeneratedCard
                uniqueId={uniqueId}
                graphFulfillmentState={graphFulfillmentState}
                nodeId={nodeId}
                dataType={dataType}
                questionType={questionType}
                choices={choices}
                moduleIds={moduleIds}
                iteration={iteration}
                questionId={questionId}
                questionText={questionText}
                enableCommentary={enableGuidance}
                enableAnalysis={enableAnalysis}
            />
        );
    }
    if (widgetState.showResearch) {
        panels.push(
            <ResearchCard
                commentary={questionCommentary}
                enableGuidance={enableGuidance}
            />
        );
    }

    return (
        <>
            <IconButton
                id={`questionDetails_${uniqueId}`}
                color="inherit"
                onClick={() => setOpen(true)}
                disabled={isTakerStateDirty}
            >
                <MoreHorizOutlined />
            </IconButton>
            <SimpleModalWrapper
                headerText={"Question Details"}
                open={open}
                handleClose={() => resetAndClose()}
                sx={{
                    width: "90vw",
                    height: "90vh",
                    padding: "16px 24px 16px 24px",
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    height='calc(100% - 16px)'
                    width='100%'
                    paddingTop={1}
                >
                    <Box
                        sx={{
                            padding: 1,
                            width: "100%",
                            alignItems: "center",
                            display: "flex",
                            border: "1px solid #ddd",
                            borderRadius: 1,
                            backgroundColor: "rgba(221,221,221,0.25)"
                        }}
                    >
                        <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                        >
                            <Grid item xs={4} justifyContent="start" padding={1}>
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                                        setAnchorEl(event.currentTarget);
                                    }}
                                    sx={{
                                        padding: "0px",
                                    }}
                                >
                                    <ViewComfyRoundedIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem key="Current" >
                                        <Checkbox
                                            checked={widgetState.showCurrentInput}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setWidgetState({
                                                    ...widgetState,
                                                    showCurrentInput: event.target.checked
                                                });
                                            }}
                                        />
                                        <ListItemText primary="Current" />
                                    </MenuItem>
                                    <MenuItem key="Proposed" >
                                        <Checkbox
                                            checked={widgetState.showAIGeneratedInput}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setWidgetState({
                                                    ...widgetState,
                                                    showAIGeneratedInput: event.target.checked
                                                });
                                            }}
                                        />
                                        <ListItemText primary="Proposed" />
                                    </MenuItem>
                                    <MenuItem key="Research" >
                                        <Checkbox
                                            checked={widgetState.showResearch}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setWidgetState({
                                                    ...widgetState,
                                                    showResearch: event.target.checked
                                                });
                                            }}
                                        />
                                        <ListItemText primary="Research" />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                            <Grid item xs={4} textAlign="center">
                                <Typography variant="body1">
                                    {questionLabel}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        width='100%'
                        overflow='hidden'
                        flexGrow={1}
                        paddingTop={1}
                    >
                        <Grid
                            container
                            height="calc(100% - 16px)"
                            spacing={1}
                        >
                            {panels.length === 1 && (
                                <Grid item xs={12} height="100%">
                                    {panels[0]}
                                </Grid>
                            )}
                            {panels.length === 2 && (
                                <>
                                    <Grid item xs={6} height="100%">
                                        {panels[0]}
                                    </Grid>
                                    <Grid item xs={6} height="100%">
                                        {panels[1]}
                                    </Grid>
                                </>
                            )}
                            {panels.length === 3 && (
                                <>
                                    <Grid item xs={6} height="50%">
                                        {panels[0]}
                                    </Grid>
                                    <Grid item xs={6} height="50%">
                                        {panels[1]}
                                    </Grid>
                                    <Grid item xs={12} height="50%">
                                        {panels[2]}
                                    </Grid>
                                </>
                            )}
                        </Grid>

                    </Box>
                    <Box
                        width='100%'
                        paddingTop={1}
                        paddingBottom='16px'
                        overflow='hidden'
                    >
                        {!readOnly && (
                            <Button
                                sx={{ float: "right" }}
                                variant="contained"
                                onClick={() => updateAll()}
                            >
                                Save
                            </Button>
                        )}
                        <Button
                            sx={{
                                marginRight: "4px",
                                float: "right"
                            }}
                            variant="outlined"
                            onClick={() => resetAndClose()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </SimpleModalWrapper>
        </>
    );
}

export default memo(QuestionDetailsModal);