import React, { useEffect, useMemo, useRef } from 'react';
import {
    SerializedLexicalNode,
    Spread,
} from 'lexical';
import { DocumentHighlight } from '../../../../types/taker/documentkeyterms.generated';
import styled from '@emotion/styled';

export interface RawHtmlPayload {
    rawHtml: string;
    page: number;
    pageWidth: number;
}

export type SerializedRawHtmlNode = Spread<
    {
        rawHtml: string;
        page: number;
        pageWidth: number;
    },
    SerializedLexicalNode
>;

const SELECTOR_QUERY = 'div.textbox, div.textline, span.word > span'

interface RawHtmlComponentProps {
    innerHtml: string;
    documentHighlights: DocumentHighlight[];
    pageScaleFactor: number;
}

interface RGBAColor {
    red: number, 
    green: number, 
    blue: number, 
    alpha:number
}

const HIGHLIGHT_COLORS: { [elementType: string] : RGBAColor[]} = {
    "WORD": [
        {red: 227, green:255, blue:0, alpha: 0.65}, 
        {red: 151, green:161, blue:0, alpha: 0.65}
    ],
    "LINE": [
        {red: 86, green: 255, blue: 0, alpha: 0.65}, 
        {red: 42, green: 128, blue: 0, alpha: 0.65}
    ],
    "TEXTBOX": [
        {red: 0, green: 249, blue: 255, alpha: 0.65},
        {red: 0, green: 123, blue: 155, alpha: 0.65}
    ]
}

const MAX_HIGHLIGHT_COUNT = 5.0
const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

const RawHtmlComponentV2 = ({
    innerHtml,
    documentHighlights,
    pageScaleFactor
}: RawHtmlComponentProps) => {
    const pageRef = useRef<HTMLDivElement>();

    const docHighlightsByElementId = useMemo(() => {
        const map: Record<string, DocumentHighlight[]> = {};
        for (const highlight of documentHighlights) {
            if (!map[highlight.elementId]) {
                map[highlight.elementId] = []
            }
            map[highlight.elementId].push(highlight);
        }
        return map;
    }, [documentHighlights]);
    
    function getBackgroundForHighlights(highlightsForElement: DocumentHighlight[]): string {
        const numHighlights = highlightsForElement.length
        const elementType = highlightsForElement[0].elementType
        const lerpVal = numHighlights / MAX_HIGHLIGHT_COUNT
        
        let minColor: RGBAColor;
        let maxColor: RGBAColor;
        if( elementType in HIGHLIGHT_COLORS) {
            minColor = HIGHLIGHT_COLORS[elementType][0]
            maxColor = HIGHLIGHT_COLORS[elementType][1]
        } else {
            return ""
        }

        const red = lerp(minColor.red, maxColor.red, lerpVal)
        const blue = lerp(minColor.blue, maxColor.blue, lerpVal)
        const green = lerp(minColor.green, maxColor.green, lerpVal)
        const alpha = lerp(minColor.alpha, maxColor.alpha, lerpVal)

        return `rgba(${red},${green},${blue},${alpha})`
    }

    useEffect(() => {
        if (pageRef.current) {
            const elems = pageRef.current.querySelectorAll(SELECTOR_QUERY) as NodeListOf<HTMLElement>;
            for (const elem of elems) {
                if (docHighlightsByElementId[elem.id]) {
                    const highlights = docHighlightsByElementId[elem.id];
                    elem.style.background = getBackgroundForHighlights(highlights);
                } else {
                    elem.style.background = "";
                }
            }

            const wordContainers = pageRef.current.querySelectorAll("span.word") as NodeListOf<HTMLElement>;
            for (const wordContainer of wordContainers) {
                const innerSpans = wordContainer.querySelectorAll('span');

                // Sum up the widths of the inner spans
                let currentWidth = 0;
                innerSpans.forEach(span => {
                    currentWidth += span.offsetWidth;
                });

                // Desired width
                const desiredWidth = wordContainer.offsetWidth;

                // Calculate the total space to add
                const totalSpaceToAdd = desiredWidth - currentWidth;

                // Count the number of gaps (spaces between words)
                const numberOfGaps = innerSpans.length - 1;

                // Calculate word-spacing value
                const wordSpacing = totalSpaceToAdd / numberOfGaps;

                if (wordSpacing >= 0) {
                    // Apply word-spacing to each span
                    innerSpans.forEach(span => {
                        span.style.wordSpacing = `${wordSpacing}px`;
                    });
                } 
                if (totalSpaceToAdd < 0) {
                    let scaleFactor = desiredWidth / currentWidth;
                    scaleFactor = scaleFactor/numberOfGaps
                    innerSpans.forEach(span => {
                        span.style.transform = `scaleX(calc(var(--scale-factor) * ${scaleFactor}))`;
                    });
                }
            }
        }
    }, [pageRef, documentHighlights]);

    const PageDiv = styled.div`
        .page {
            --scale-factor: ${pageScaleFactor};
            position: relative;
            font-family: serif;
            line-height: 1;
        }
        .textbox {
            position: absolute;
            white-space: pre;
        }
        .textline {
            position: absolute;
        }
        .line {
            position: absolute;
        }
        .rect {
            position: absolute;
        }
        .figure {
            position: absolute;
        }
        .word {
            position: absolute;
        }
    `;

    return (
        <PageDiv
            ref={(r) => {
                if (r) {
                    pageRef.current = r;
                }
            }}
            dangerouslySetInnerHTML={{
                __html: innerHtml
            }}
        />
    );
};

export default RawHtmlComponentV2;