import React, { useContext, PropsWithChildren, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { pageOption } from '../../data/pages';
import { useGetCurrentApplicationQuery } from '../../redux/services/application';
import { useGetFeatureFlagFileQuery } from '../../redux/services/staticFile';
import { FlagProvider } from './FlagProvider';
import { useSelector } from '../../redux/reduxUtils/functions';
import { RootReducerType } from '../../redux/models/reduxTypes';
import { useSnackbar } from 'notistack';
import { OrganizationMembership } from '../../redux/models/dataModelTypes';
import { FeatureFlags } from '../../types/featureflags.generated';

interface UserScopedAppDataHookData {
    selectedOrgId: string;
    setSelectedOrgId: (oid: string) => void;
    flagProvider: FlagProvider;
    isDebugger: boolean;
}

type UserScopedAppDataHook = () => UserScopedAppDataHookData;

const Context = React.createContext({});

const LAST_ORG_ID_KEY = "last-org-id-v0";

const getLastOrgId = (firstOrgId: string): string => {
    if (localStorage?.getItem(LAST_ORG_ID_KEY)) {
        return localStorage.getItem(LAST_ORG_ID_KEY) as string;
    } else {
        return firstOrgId;
    }
}
const setLastOrgId = (id: string) =>
    localStorage.setItem(LAST_ORG_ID_KEY, id);

const snackbarMessage = 'You are not a member of any organization. Please contact your administrator to be added to an organization.';

export const useUserScopedAppData: UserScopedAppDataHook = () => {
    return useContext(Context) as UserScopedAppDataHookData;
}

const getEarliestOrgIndex = (orgs: OrganizationMembership[]): number => {
    const earliestOrg = orgs.reduce((earliest, org, index) => {
        if (org.createdAt < earliest.org.createdAt) {
            return { org, index };
        }
        return earliest;
    }, { org: orgs[0], index: 0 });
    return earliestOrg.index;
}

interface UserScopedAppDataContainerProps {
    featureFlagDefaultOverrides?: FeatureFlags;
    children: React.ReactNode;
}

export const UserScopedAppDataContainer: React.FC<PropsWithChildren<UserScopedAppDataContainerProps>> = ({
    featureFlagDefaultOverrides = {},
    children
}: UserScopedAppDataContainerProps) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector((state: RootReducerType) => state.auth);
    const isDebugger = useMemo(() => !!user?.roles.includes('DEBUGGER'), [user]);
    if (!isDebugger && user?.organizationMemberships.length === 0) {
        enqueueSnackbar(snackbarMessage, { variant: 'error' });
    }
    let firstOrgId = '';
    const lastOrgId = getLastOrgId(firstOrgId);

    const { data: applicationList } = useGetCurrentApplicationQuery();
    const { data: featureFlagSchema } = useGetFeatureFlagFileQuery();

    let defaultOrgId = '';

    if (lastOrgId) {
        defaultOrgId = lastOrgId;
    } else {
        if (user) {
            if (user?.organizationMemberships?.length > 0) {
                defaultOrgId = user.organizationMemberships[getEarliestOrgIndex(user.organizationMemberships)].organization.id;
            }
        }
    }

    const [orgId, setOrgId] = useState<string>(defaultOrgId);

    const flagProvider = useMemo(() => {
        const provider = new FlagProvider(orgId)

        // Set Global Defaults
        if (featureFlagSchema) {
            provider.setDefaultFlagValues(featureFlagSchema);
        }

        // Set the flag values specified by application configs
        if (applicationList) {
            provider.setConfigurationEntriesForApplicationTags(applicationList);
        }

        // Set any overrides we may pass in directly (for testing)
        if (featureFlagDefaultOverrides) {
            provider.setPartialDefaultFlagValues(featureFlagDefaultOverrides);
        }

        return provider;
    }, [
        applicationList, 
        orgId, 
        featureFlagSchema, 
        featureFlagDefaultOverrides
    ]);

    // Keep local storage up to date.
    useEffect(() => {
        setLastOrgId(orgId);
    }, [orgId]);

    return (
        <Context.Provider
            value={{
                selectedOrgId: orgId,
                setSelectedOrgId: (oid: string) => {
                    setOrgId(oid);
                    navigate(pageOption.HOME.route);
                },
                flagProvider,
                isDebugger
            }}>
            {children}
        </Context.Provider>
    );
};
